import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, List, Switch } from 'antd';
import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

//componentes
import PhoneInput from "../../../Widgets/Inputs/PhoneInput";

//variables
const axios = require('axios').default;
const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;


/**
 *
 *
 * @class FormDireccion
 * @extends {React.Component}
 * @description Formulario de usuarios
 */
class FormDireccion extends Component {


    formModalDireccion = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingImage: false,
            form: {},

            id: null,
            image: null,
            usuario: null,

            admin: false,
            activo: false,

        }
    }


    /**:4
     * @methodOf FormDireccion
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.direccion_id) {
            this.getData()
        }
    }


    /**
    * @memberof FormDireccion
    *
    * @method onFinish
    * @description  Se ejecuta al dar finalizar al formulario
    *
    **/
    onFinish = values => {

        console.log(values);

        if (this.props.direccion_id !== undefined) {
            this.updateDireccion(values)
        } else {
            this.addDireccion(values);
        }

    }


    /**
   * @memberof FormDireccion
   *
   * @method getData
   * @description  trae la informacion de la direccion
   *
   **/
    getData = (values) => {
        this.setState({ loading: true })
        axios.get('/usuarios/direcciones', {
            params: { 
                direccion_id: this.props.direccion_id,
                usuario_id: this.props.usuario_id, 
            }
        }).then(response => {
            console.log("response", response.data.data);

            let direccion = response.data.data.length > 0 ? response.data.data[0] : {}

            this.formModalDireccion.current.setFieldsValue({
                nombre: direccion.nombre,
                calle: direccion.calle,
                ciudad: direccion.ciudad,
                estado: direccion.estado,
                notas: direccion.notas,
                codigo_postal: direccion.codigo_postal,
                numero: direccion.numero,
                telefono: direccion.telefono,
            });

        }).catch(error => {
            message.error('Error al obtener la informacion')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @memberof FormDireccion
    *
    * @method updateDireccion
    * @description  Actualiza la informacion de una direccion
    *
    **/
    updateDireccion = (values) => {
        
        axios.post('/usuarios/direccion/update', {
            usuario_id: this.props.usuario_id,
            direccion_id: this.props.direccion_id,
            ...values
        })
        .then((response) => {
            message.success('Dirección actualizada')
            this.props.onCancel()  
        })
        .catch((error) => {
            console.log(error)
           message.error('Error al actualizar la dirección')
        })
    }

    /**
    * @memberof FormDireccion
    *
    * @method addDireccion
    * @description  Añade un usuario
    **/
    addDireccion = (values) => {
        axios.post('/usuarios/direccion/add', {
            ...values,
            usuario_id: this.props.usuario_id
        })
            .then((response) => {
                message.success('Direccion añadida')
                this.props.onCancel()
            })
            .catch((error) => {
                console.log(error);
                message.error("Error al crear el usuario");
            })
    }


    render() {

        const { loading, usuario, activo, admin } = this.state;



        return (

            <Spin spinning={loading}>
                <Title level={3} className="text-center">{this.props.direccion_id ? 'Editar' : 'Añadir'} Dirección</Title>
                <Form
                    layout="vertical"
                    ref={this.formModalDireccion}
                    onFinish={this.onFinish}
                >

                    <Row justify="center" gutter={[16,16]}>
                        <Col xs={20}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{ required: true, message: "Por favor, ingrese nombre" }]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>


                        <Col xs={20}>
                            <Form.Item
                                label="Calle"
                                name="calle"
                                rules={[{ required: true, message: "Por favor, ingrese la calle" }]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col xs={10}>
                            <Form.Item
                                label="Número EXT"
                                name="numero"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                         <Col xs={10}>
                            <Form.Item
                                label="Código Postal"
                                name="codigo_postal"
                                rules={[{ required: true, message: "Por favor, ingrese el código postal" }]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col xs={20}>
                            <Form.Item
                                label="Ciudad"
                                name="ciudad"
                                rules={[{ required: true, message: "Por favor, ingrese la ciudad" }]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col xs={20}>
                            <Form.Item
                                label="Estado"
                                name="estado"
                                rules={[{ required: true, message: "Por favor, ingrese la estado" }]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col xs={20}>
                            <Form.Item
                                label="Telefono"
                                name="telefono"
                            >
                                <PhoneInput/>
                            </Form.Item>
                        </Col>

                        <Col xs={20}>
                            <Form.Item
                                label="Notas"
                                name="notas"
                            >
                                <Input.TextArea/>
                            </Form.Item>
                        </Col>


                    </Row>


                    <Row justify="center" align="middle" >
                        <Col span={20} className="flex-column mt-1">
                            <Form.Item>
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {

    let { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormDireccion {...props} />
        </Modal>
    )
}