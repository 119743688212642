import React, { Component, useRef, useImperativeHandle, forwardRef } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, message, Upload, Checkbox, Spin, Radio, Space, Switch, Divider } from 'antd';
import { PlusOutlined,  DeleteOutlined, LoadingOutlined } from '@ant-design/icons';


const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');
const Decimal = require('decimal.js')

class CotizacionDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            orden: {
                subtotal_venta: 0,
                iva_venta: 0,
                total_venta: 0
            },
            productos: [],
            to_delete: [],

        }
    }

    refModalVariante = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if(this.props.orden_id){
            this.getOrden()
        }

    }

    /**
    * @memberof Cotizaciones
    *
    * @method getOrden
    * @description Obtiene la orden y sus productos
    **/
    getOrden = () => {
        this.setState({loading: true})
        axios.get('/ordenes/detalle',{
            params:{
                orden_id: this.props.orden_id
            }
        }).then(response => {
            this.setState({
                orden: response.data.orden,
                productos: response.data.orden_detalles
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la información')
        }).finally(()=>this.setState({loading: false}))
    }


    /**
    * @memberof Cotizaciones
    *
    * @method updateProducto
    * @description Actualiza la información de una orden detalle, y vuelve a obtener todos los detalles con los calculos
    **/
    updateProducto = (values, index) => {
        
        let orden = this.state.orden
        let productos = this.state.productos
        let orden_detalle = this.state.productos[index]

        let { precio, cantidad } = values

        //actualiazamos los campos del productos, con lo que ingresa el usaurio
        orden_detalle.precio = precio === null ? 1 : precio
        orden_detalle.cantidad = cantidad

        //realizamos los calculos del producto

        let utilidad_unitaria = ((orden_detalle.precio * 100) - (orden_detalle.costo * 100))/100

        if(orden.iva_agregado_venta){
            let iva_unitario = Decimal.sub(orden_detalle.precio, Decimal.div(orden_detalle.precio, 1.16))
            orden_detalle.iva = (iva_unitario  *  orden_detalle.cantidad)

            orden_detalle.precio_unitario = Decimal.sub(orden_detalle.precio,iva_unitario)

            orden_detalle.margen_utilidad = ((utilidad_unitaria / orden_detalle.precio) * 100)
        }else{
            orden_detalle.iva = 0

            orden_detalle.precio_unitario = Decimal.div(orden_detalle.precio, 1.16)

            orden_detalle.margen_utilidad = (((orden_detalle.precio_unitario / orden_detalle.costo) - 1) * 100)
        }

        orden_detalle.total_venta = (((orden_detalle.cantidad * 100) * (orden_detalle.precio_unitario  * 100 )) / 10000)


        productos[index] = orden_detalle

        let sub_total_venta = 0
        let utilidad = 0
        for(const detalle of productos){
            sub_total_venta = ((sub_total_venta * 100) + (detalle.total_venta * 100))/100
            utilidad += detalle.margen_utilidad 
        }

        orden.subtotal_venta = sub_total_venta
        orden.total_venta = sub_total_venta

        if(orden.iva_agregado_venta){
            orden.iva_venta = ((sub_total_venta * 100) * (0.16 * 100)) / 10000
            orden.total_venta =  ((orden.total_venta * 100) + ( orden.iva_venta * 100)) / 100
        }else{
            orden.iva_venta = 0
        }

        orden.utilidad = (utilidad / productos.length)
        orden.subtotal_venta = orden.subtotal_venta
        orden.total_venta = orden.total_venta
        orden.iva_venta = orden.iva_venta

        
            this.setState({productos: productos, orden: orden})
        
    
    }

    /**
    * @memberof Cotizaciones
    *
    * @method updateOrden
    * @description Actualiza el total dependidneo del iva
    **/
    updateOrden = (values = { iva_agregado : this.state.orden.iva_agregado_venta}) => {

        let { iva_agregado } = values

        let orden = this.state.orden

        orden.iva_agregado_venta = iva_agregado

        this.setState({orden: orden},async ()=>{

            await this.upadateProductosMontos()
            
            let productos = this.state.productos

            let utilidad_orden = 0
            let iva_orden = 0
            let sub_total_venta = 0

            //iteamos cada producto
            for(let producto of productos){
                //sumamos la utilidad de cada producto
                utilidad_orden += producto.margen_utilidad
                //sumamos el iva de cada producto
                iva_orden = parseFloat(Decimal.sum(iva_orden, producto.iva ).toDecimalPlaces(2))
                //sumamos el total de cada producto, para obetener el subtotal de la orden
                sub_total_venta = parseFloat(Decimal.sum(sub_total_venta, producto.total_venta).toDecimalPlaces(2))
            }

            orden.subtotal_venta = sub_total_venta
            orden.total_venta = sub_total_venta

            if(orden.iva_agregado_venta){
                orden.iva_venta = iva_orden
                orden.total_venta =  ((sub_total_venta * 100) + ( iva_orden * 100)) / 100
            }else
                orden.iva_venta = 0

            orden.utilidad = (utilidad_orden / productos.length)

            this.setState({orden})
        }) 

        

    }


    /**
    * @memberof Cotizaciones
    *
    * @method upadateProductosMontos
    * @description Recorre todos los productos en el state para calular los montos totales y utilidades
    **/
    upadateProductosMontos = () => {

        let orden = this.state.orden
        let productos = this.state.productos

        return new Promise(resolve => {

            //recorremos todos los productos para, hacer los calculos de cada uno 
            for(let i = 0; i < productos.length; i++){

                let utilidad_unitaria = ((productos[i].precio * 100) - (productos[i].costo * 100))/100

                if(orden.iva_agregado_venta){
                    let iva_unitario = Decimal.sub(productos[i].precio, Decimal.div(productos[i].precio, 1.16))
                    productos[i].iva = (iva_unitario  *  productos[i].cantidad)
                   

                    productos[i].margen_utilidad = ((utilidad_unitaria / productos[i].precio) * 100)
                }else{
                    productos[i].iva = 0

                    productos[i].margen_utilidad = (((productos[i].precio_unitario / productos[i].costo) - 1) * 100)
                }

                productos[i].total_venta = (((productos[i].cantidad * 100) * (productos[i].precio_unitario  * 100 )) / 10000)

            }

            this.setState({productos},()=>{
                resolve(true)
            })
        })

    }

    /**
    * @memberof Cotizaciones
    *
    * @method updateData
    * @description Actualiza la informacion en la bd
    **/    
    updateData = () => {
        this.setState({loading: true})
        axios.post('/ordenes/update/all',{
            orden: this.state.orden,
            orden_detalles: this.state.productos,
            to_delete: this.state.to_delete
        }).then(response => {
            message.success('Orden Actualizada')
            this.props.onCancel()
        }).catch(error =>{
            console.log("error", error);
            if(error?.response?.status === 403){
                message.error(error?.response?.data?.message)
            }else{
                message.error('Error al actualizar')
            }
        }).finally(()=>this.setState({loading: false}))
    }


    /**
    * @memberof Cotizaciones
    *
    * @method deleteProducto
    * @description Elimina un producto de la orden
    **/
    deleteProducto = (orden_detalle_id, index) => {

        if(this.state.productos.length === 1){
            message.warning('No se puede eliminar, mínimo un producto por orden')
            this.setState({loading: false})
            return
        }

       
            
        let productos = this.state.productos
        let to_delete = this.state.to_delete

        productos.splice(index, 1)
        if(orden_detalle_id)
            to_delete.push(orden_detalle_id)

        this.setState({productos: []},()=>{
            this.setState({productos, to_delete},()=>{
                this.updateOrden()
            })
        })


    
    }

    /**
    * @memberof Cotizaciones
    *
    * @method addProducto
    * @description Metodo que permite PSEUDO-AÑADIR una orden detalle a la orden, crea el registro de la orden_detalle en la bd, para realizar los calculos y busquedas de costos y precio
    * para depues eliminarlo inmediatamente de la bd y nos regresa una copia para añadirlo
    **/
    addProducto = ({variante_id, proveedor_id}) => {
        axios.post('/ordenes/producto/add',{
            orden_id: this.props.orden_id,
            variante_id,
            proveedor_id,
            eliminar: true
        }).then(response => {
            let new_producto = {...response.data.data, new: true}

            let productos = this.state.productos

            productos.push(new_producto)

            this.setState({
                productos: productos
            },()=>this.updateProducto(new_producto, productos.length - 1))

        }).catch(error => {
            console.log("error", error);

        })
    }

    render() {

        const { orden, productos } = this.state

        return (
            <>
                
                <Spin spinning={this.state.loading}>
                    <Row className="table-titles mt-1" gutter={[16,16]}>
                        <Col className="center" span={8}>
                            <Text>Producto</Text>
                        </Col>
                        <Col className="center" span={4}>
                            <Text>Cantidad</Text>
                        </Col>
                        <Col className="center" span={4}>
                            <Text>Precio</Text>
                        </Col>
                        <Col className="center" span={5}>
                            <Text>Total</Text>
                        </Col>
                        
                    </Row>
                    <div className="list-productos mt-1">
                        {
                            productos.map((producto,index) =>{
                                return <Form
                                    onValuesChange={(values, all) => this.updateProducto(all, index)}
                                    initialValues={{...producto}}
                                >
                                    <Form.Item name="total" hidden/>
                                    <Form.Item name="_id" hidden/>
                                    <Row gutter={[16,16]} style={{marginTop: '12px'}}>
                                        <Col span={8} className="flex-left-column">
                                            <Text ellipsis strong style={{fontSize: '12px'}}>{producto?.variante_id?.nombre}</Text>
                                            <Text ellipsis style={{fontSize: '12px'}}>{producto?.producto_id?.nombre}</Text>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item name="cantidad">
                                                <InputNumber min={1} precision={0} className="width-100" size="small"/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item name="precio">
                                                <InputNumber min={1} precision={2} className="width-100" size="small"/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={5} className="flex-right">
                                            <Text strong style={{fontSize: '12px'}}>$ {producto?.total_venta?.toMoney()} mxn</Text>
                                        </Col>
                                        <Col span={2} className="center">
                                            <Button
                                                size="small"
                                                title="Eliminar"
                                                type="danger"
                                                onClick={()=>this.deleteProducto(producto._id, index)}
                                                icon={<DeleteOutlined /> }>
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            {producto.margen_utilidad.toUtilidad()}
                                        </Col>
                                    </Row>
                                </Form>
                            })
                        }
                    </div>
                    <Divider/>
                    <div className="carrito-card mt-1">
                        <Row>
                            <Col span={12}>
                                <Text className="titulo-sm">Utilidad</Text>
                            </Col>
                            <Col span={12} className="flex-right">
                                <Text className="dato-sm">{orden.utilidad ? orden.utilidad.toUtilidad() : '0%'}</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={12}>
                                <Text className="titulo">Subtotal</Text>
                            </Col>
                            <Col span={12} className="flex-right">
                                <Text className="dato">$ {orden?.subtotal_venta?.toMoney()} mxn</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={12}>
                                <Text className="titulo">IVA <Switch size="small" checked={orden.iva_agregado_venta} onChange={(check) => this.updateOrden({iva_agregado: check})}/></Text>
                            </Col>
                            <Col span={12} className="flex-right">
                                <Text className="dato">$ {orden?.iva_venta?.toMoney()} mxn</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={12}>
                                <Text className="titulo">Total</Text>
                            </Col>
                            <Col span={12} className="flex-right">
                                <Text className="dato">$ {orden?.total_venta?.toMoney()} mxn</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={24} className="mt-1">
                                <Button 
                                    className="generar" 
                                    htmlType="submit" 
                                    type="primary" 
                                    loading={this.state.loading}
                                    onClick={()=>this.updateData()}
                                >
                                    Guardar Cotización
                                </Button>
                            </Col>
                        </Row>
                    </div>

                </Spin>
            </>
        )
    }
}



export default forwardRef((props,ref) => {
    const { visible = false, onCancel = () => { } } = props

    const cotRef = useRef(null);

    useImperativeHandle(ref, () => ({

        addProducto(values) {
            cotRef.current.addProducto(values)
        },

    }))

    return <CotizacionDetalle {...props} ref={cotRef}/>
})
