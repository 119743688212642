import React from 'react'
import { Route, Switch } from "react-router-dom";


import Usuarios from '../../components/Admin/Usuarios/Usuarios';
import DetalleUsuarios from '../../components/Admin/Usuarios/DetalleUsuarios';


/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterUsuarios( props) {
    return (
        <Switch>

            <Route exact path="/admin/usuarios" render={() => <Usuarios {...props} />} />
            <Route exact path="/admin/usuarios/detalle/:usuario_id" render={() => <DetalleUsuarios {...props} />} />

         </Switch>
    )
}

export default RouterUsuarios;
