import React from 'react'
import { Route, Switch } from "react-router-dom";


import TipoCliente from '../../components/Admin/TipoCliente/TipoCliente';


/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterTipoCliente( props) {
    return (
        <Switch>

            <Route exact path="/admin/tipos" render={() => <TipoCliente {...props} />} />

         </Switch>
    )
}

export default RouterTipoCliente;
