import React, { Component } from "react";
import { Layout, Col, Row, message, PageHeader, Typography, Card, Select, Tabs, Button, Dropdown, Menu, Form } from 'antd';
import { FlagFilled, EllipsisOutlined } from '@ant-design/icons';

//COMPONENTES
import PagosTab from "./PagosTab";
import CotizacionesTab from "./CotizacionesTab";
import VentasTab from "./VentasTab";
import { IconArrowBack } from "../../Widgets/Iconos";

//MODALES
import ModalUsuarios from '../Usuarios/ModalUsuario'
import ModalDireccion from './Modales/ModalDireccion'

//VRIABLES
const { TabPane } = Tabs;
const { Option } = Select
const { Content } = Layout;
const moment = require('moment');
const { Title, Text } = Typography
const axios = require("axios").default;


/**
 * @class CRMDetalle
 * @extends {Component} Muestra a Detalle la informacion del cliente 
 */
export default class CRMDetalle extends Component {

	volver = () => {
        this.props.history.goBack();
    }

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			usuario_id: this.props.match.params.usuario_id,
			cliente: {
				direcciones: [],
				saldo: 0,
				credito: 0,
				dias_credito: 0,
			},
			direccion: null,
			ventas:{
				ventas_mes: 0,
				ventas_total: 0,
				
			},
			ordenes:{
				ventas_mes: 0,
				ventas_total: 0,
				
			},
			pagado: {
				total: 0
			},
		}
	}

	direccionRef = React.createRef()

	/**
	* @memberof CRMDetalle
	* @method componentDidMount
	* @description  Carga los datos de la vista
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		
		this.getCliente()
	}

	/**
	* @memberof CRMDetalle
	* @method getCliente
	* @description  Carga los datos de la vista
	**/
	getCliente = () => {
		this.setState({loading: true})
		axios.get('/cliente/get',{
			params:{
				cliente_id: this.props.match.params.usuario_id
			}
		}).then(response => {
			console.log("response", response.data);

			let index = 0

			if(response.data.data.direcciones.length > 0 && this.state.direccion != null){
				let index_b = response.data.data.direcciones.findIndex(dir => dir._id.toString() === this.state.direccion._id.toString())

				if(index_b !== -1){
					index = index_b
				}

			}


			this.setState({
				cliente: response.data.data,
				direccion: response.data.data.direcciones.length > 0 ? response.data.data.direcciones[index] : null,
				ventas: response.data.ventas,
				pagado: response.data.pagado,
				ordenes: response.data.ordenes,
			})

			this.direccionRef.current.setFieldsValue({
				direccion_id: response.data.data.direcciones.length > 0 ? response.data.data.direcciones[index]._id : null, 
			})

		}).catch(error => {
			console.log("error", error);

		})
	}

	render() {

		const { cliente, ventas_mes, ventas_total, direccion, ventas, pagado, ordenes } = this.state

		return (
			<>
				<PageHeader
					className="site-page-header"
					title={
						<Row className="width-100" justify="space-between" align="middle">
							<Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
								<Button ghost onClick={this.volver} style={{ border: "none" }}>
                                    <IconArrowBack />
                                </Button>
								<span className="ant-page-header-heading-title">
									CRM Detalle Cliente
								</span>
							</Col>
						</Row>
					}
				/>
				<Content className="admin-content content-bg pd-1 CRMdetalle">
					<Row gutter={20}>
						<Col xs={24} sm={24} md={24} lg={8} className="grid">
							<Card 
								className="detalle"
							>
								<Row>
									<Col span={22}>
										<Text className="cliente-title">{cliente.nombre} {cliente.apellido}</Text>
									</Col>
									<Col span={2} className="flex-right">
										<Dropdown 
											overlay={<Menu onClick={(e)=>this.setState({modal_usuario: true})} items={[{ label: 'Editar', key: '0' }]}/>} 
											trigger={['click']}
										>
									    	<EllipsisOutlined/>
										</Dropdown>
									</Col>
								</Row>
								<Row align="middle" className="mt-2 mb-1"><Text className="cliente-desde"><FlagFilled className="mr-1"/>Cliente desde {moment(cliente.createdAt).fromNow()}</Text></Row>
								<Row><Text className="clente-info">{cliente.nombre} {cliente.apellido}</Text></Row>
								<Row><Text className="clente-info">{ cliente.rfc ? cliente.rfc : 'Sin RFC' }</Text></Row>
								<Row><Text className="clente-info">{cliente.telefono} / { cliente.email }</Text></Row>
							</Card>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} className="grid">
							<Card className="detalle">
								<Row>
									<Col span={20}>
										<Form
											ref={this.direccionRef}
										>
											<Form.Item name="direccion_id">
												<Select 
													className="direcciones"
													onSelect = {(direccion_id)=>{
														let index = this.state.cliente.direcciones.findIndex(dir =>dir._id.toString() === direccion_id.toString())
														if(index != -1){
															this.setState({direccion: this.state.cliente.direcciones[index]})
														}
													}}
												>
													{cliente.direcciones.map(dir => <Option value={dir._id}>{dir.nombre}</Option>)}
													{cliente.direcciones.length > 0 ? null : <Option value="1">Sin direcciones</Option>}
												</Select>
											</Form.Item>
										</Form>
									</Col>
									<Col span={4} className="flex-right">
										<Dropdown 
											overlay={<Menu onClick={()=>this.setState({modal_direccion: true})}>
												<Menu.Item key="0">
													Añadir Dirección
												</Menu.Item>
												{direccion ? <Menu.Item key="1" onClick={()=>this.setState({direccion_id: this.state.direccion._id})}>
													Editar Dirección
												</Menu.Item> : null}
											</Menu>} 
											trigger={['click']}
										>
									    	<EllipsisOutlined/>
										</Dropdown>
									</Col>
									
								</Row>
								<div className="mt-1">
									<Text className="info-direccion">{direccion ? direccion.nombre : '-'}</Text>
									<div className="info-direccion-sub"><strong>Calle: </strong> {direccion ? direccion.calle : ''}</div>
									<div className="info-direccion-sub"><strong>Ciudad: </strong>{direccion ? direccion.ciudad : ''}</div>
									<div className="info-direccion-sub"><strong>Estado: </strong>{direccion ? direccion.estado : ''} </div>

									<div className="info-direccion-sub"><strong>C.P.: </strong>{direccion ? direccion.codigo_postal : ''}</div>
									<div className="info-direccion-sub"><strong>Notas: </strong>{direccion ? direccion.notas : ''}</div>
									<div className="info-direccion-sub"><strong>Teléfono: </strong>{direccion ? direccion.telefono : ''}</div>

								</div>
							</Card>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} className="grid">
							<Card className="detalle">
								<Row><Text className="venta-title">{'Venta del mes'}</Text></Row>
								<Row><Text className="venta-dato mt-1"> $ {(ventas.ventas_mes + ordenes.ventas_mes).toMoney()} MXN</Text></Row>
								<Row className="mt-2"><Text className="venta-title">{'Venta total'}</Text></Row>
								<Row><Text className="venta-dato mt-1">$ {(ventas.ventas_total + ordenes.ventas_total).toMoney()} MXN</Text></Row>
							</Card>
						</Col>
						<Col className="mt-1" span={24}>
							<Card className="info-card">
								<Row justify="space-between">
									<Col className="mt-1 mb-1" xs={24} sm={12} md={8} lg={4}>
										<Row className="center">
											<Text className="titulo">Monto de Venta</Text>
										</Row>
										<Row className="mt-1 center">
											<Text className="dato">$ {(ventas.ventas_total + ordenes.ventas_total).toMoney()} MXN</Text>
										</Row>
									</Col>
									<Col className="mt-1 mb-1" xs={24} sm={12} md={8} lg={4}>
										<Row className="center">
											<Text className="titulo">Monto Pagado</Text>
										</Row>
										<Row className="mt-1 center">
											<Text className="dato">$ {pagado.total.toMoney()} MXN</Text>
										</Row>
									</Col>
									<Col className="mt-1 mb-1" xs={24} sm={12} md={8} lg={4}>
										<Row className="center">
											<Text className="titulo">Saldo</Text>
										</Row>
										<Row className="mt-1 center">
											<Text className="dato">$ {cliente.saldo ? cliente.saldo.toMoney() : '0.00'} MXN</Text>     
										</Row>
									</Col>
									<Col className="mt-1 mb-1" xs={24} sm={12} md={8} lg={4}>
										<Row className="center">
											<Text className="titulo">Dias de Credito</Text>
										</Row>
										<Row className="mt-1 center">
											<Text className="dato"> {cliente.dias_credito ? cliente.dias_credito : 0 }</Text>
										</Row>
									</Col>
									<Col className="mt-1 mb-1" xs={24} sm={24} md={8} lg={4}>
										<Row className="center">
											<Text className="titulo">Monto de Crédito</Text>
										</Row>
										<Row className="mt-1 center">
											<Text className="dato">$ {cliente.credito ? cliente.credito.toMoney() : '0.00'} MXN</Text>
										</Row>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Tabs className="tabs-personalized">
								<TabPane tab="Cotizaciones" key="1">
									<CotizacionesTab
										usuario_id={this.props.match.params.usuario_id}
									/>
								</TabPane>
								<TabPane tab="Pagos" key="2">
									<PagosTab
										usuario_id={this.props.match.params.usuario_id}
									/>
								</TabPane>
								<TabPane tab="Ventas" key="3">
									<VentasTab
										usuario_id={this.props.match.params.usuario_id}
									/>
								</TabPane>
							</Tabs>
						</Col>
						
					</Row>
								
				</Content>

				<ModalUsuarios 
					visible={this.state.modal_usuario}
					onCancel={()=>{
						this.setState({modal_usuario: false})
						this.getCliente()
					}}
					usuario_id={this.state.usuario_id}
					crm={true}
				/>

				<ModalDireccion 
					visible={this.state.modal_direccion}
					onCancel={()=>{
						this.setState({modal_direccion: false, direccion_id: undefined})
						this.getCliente()
					}}
					usuario_id={this.state.cliente._id}
					direccion_id={this.state.direccion_id}
				/>

			</>
		)
	}
}

