
import React from 'react'
import { Spin, Layout, Col, Row, PageHeader, Avatar, Typography, Image, Card, Statistic, Tag } from "antd";
import { VentasSemana } from '../Widgets/graficas'
import Analytics from '../Widgets/analytics'
import { User } from '../../Hooks/Logged';
import { UserOutlined, StockOutlined } from "@ant-design/icons";
import { IconSalesGreen, IconGroupPersons, IconOrdenes } from "../Widgets/Iconos"
import '../../Styles/Modules/Dashboard/dashboard.scss'
import { FaShoppingCart, FaBoxes } from "react-icons/fa";


const axios = require('axios')
const moment = require('moment')
const { Content } = Layout;
const { Title, Text } = Typography;


export default class Dashboard extends React.Component {
    static contextType = User;
    constructor(props) {
        super(props);

        this.state = {

            loading: false,
            ventas_totales: 0,
            ventas_semana: 0,
            total_productos: 0,
            total_usuarios: 0,
            ventas:{
                total: 0
            },
            compras:{
                total: 0
            },
            grafica:{},
            productos: [],
            margen_utilidad: 0,
            dataValue: {
                "lunes": 0,
                "martes": 0,
                "miercoles": 0,
                "jueves": 0,
                "viernes": 0,
                "sábado": 0,
                "domingo": 0,
            }
        }
    }




    /**
     *
     *
     * @memberof Dashboard
     * @description Carga los datos a la vista
     */

    componentDidMount() {
        this.props.setTitle('Dashboard')
        axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
        this.getDashboard()

    }


    getDashboard() {
        axios.get('/dashboard').then(response => {

            let compras_totales = response.data.compras_totales.total, ventas_totales = response.data.ventas_totales.total

            let utilidad =  ((ventas_totales * 100) - (compras_totales * 100)) / 100
            console.log("utilidad", utilidad);

            let margen_utilidad = (utilidad / ventas_totales) * 100
            console.log("margen_utilidad", margen_utilidad);

            this.setState({
                compras: response.data.compras,
                ventas: response.data.ventas,
                ventas_totales: response.data.ventas_totales,
                compras_totales: response.data.compras_totales,
                productos: response.data.productos,
                grafica: response.data.datos_semanales,
                margen_utilidad
            })
            
        });
    }



    render() {

        const { ventas, compras } = this.state 

        return (
            <Spin spinning={this.state.loading} >
                <PageHeader
                    className="site-page-header"
                    title={
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                <span className="ant-page-header-heading-title">
                                    Dashboard
                                </span>
                            </Col>
                        </Row>
                    }
                />

                
                <Content >
                    <Row className="dashboard width-100  pd-1">
                        <Col xs={24} sm={24} md={12} lg={12} xl={14} xxl={16}>
                            <Row gutter={[16, 16]} className="width-100 ">
                                <Col span={24} xs={23} lg={12} xl={12}>
                                    <Card className="card-container">
                                        <Col>
                                            <Row>
                                                <Card.Meta
                                                    title={`Ventas ${moment().format('MMMM').toUpperCase()}`}
                                                />
                                            </Row>
                                            <Row>
                                                <Statistic className="flex-left mt-1 card-count" value={`$ ${ventas.total?.toMoney()} MXN`} />
                                            </Row>
                                            <Row className='mt-1' align='middle'>
                                                <Col>
                                                    <Tag className='center icono'><StockOutlined/></Tag>
                                                </Col>
                                                <Col>
                                                    <Text>{`${ventas.porcentaje_dif_ventas?.toMoney()}`}%</Text>
                                                </Col> 
                                            </Row>
                                        </Col>
                                    </Card>
                                </Col>
                                <Col span={24} xs={23} lg={12} xl={12}>
                                    <Card className="card-container">
                                        <Col>
                                            <Row>
                                                <Card.Meta
                                                    title={`Compras ${moment().format('MMMM').toUpperCase()}`}
                                                />
                                            </Row>
                                            <Row>
                                                <Statistic className="flex-left mt-1 card-count" value={`$ ${compras.total?.toMoney()} MXN`} />
                                            </Row>
                                            <Row className='mt-1' align='middle'>
                                                <Col>
                                                    <Tag className='center icono'><StockOutlined/></Tag>
                                                </Col>
                                                <Col>
                                                    <Text>{`${compras.porcentaje_dif_compras?.toMoney()}`}%</Text>
                                                </Col> 
                                            </Row>
                                        </Col>
                                    </Card>
                                </Col>
                                <Col span={24}  >
                                    <Card className="card-container "  >
                                        <Card.Meta
                                            title="Ventas Semanales"
                                        />

                                        <VentasSemana datos={this.state.grafica} />
                                    </Card>
                                </Col>


                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={10} xxl={8}>
                            <Analytics data={this.state} margen_utilidad={this.state.margen_utilidad}/>
                        </Col>
                    </Row >
                </Content >
            </Spin >
        )
    }
}