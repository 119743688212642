import React, { Component } from "react";
import { message, List, Layout, Input, Row, Col, Modal, Empty, Avatar, Switch, Typography, PageHeader, Button, Table } from 'antd';
import { Link, Redirect } from "react-router-dom";
import { IconArrowBack } from './../../Widgets/Iconos'
import CustomAvatar from "../../Widgets/Avatar/Avatar";

//modal


import './../../../Styles/Modules/Usuarios/usuarios.css'
import { Trochoid } from "zrender";


const { Title, Text } = Typography;
const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;

const axios = require("axios").default;

class DetalleUsuarios extends Component {


    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            usuario_id: "",
            usuario: undefined,
            volver: false,
        };
    }


    /**
   * @memberof DetalleUsuarios
   * @method componentDidMount
   * @description  Inicializa defaults de axios y llama consulta getInfo
   **/
    componentDidMount() {
        this.props.setTitle('Detalle Usuario', true)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        const id = window.location.pathname.split("/")[(window.location.pathname.split("/").length) - 1];
        this.state.usuario_id = id;

        setTimeout(() => {
            this.getInfo();
        }, 200);
    }


    /**
   * @memberof DetalleUsuarios
   *
   * @method getInfo
   * @description  trae la informacion de un usuario
   *
   **/ getInfo = (values) => {

        this.setState({ loading: true })
        axios.get('/usuarios/get', {
            params: { id: this.state.usuario_id }
        }).then(response => {

            if (response.data.success) {
                var info = response.data.data;
                this.state.usuario = info;
                var admin = info.tipo === 1;
                this.props.setTitle(`Detalle Usuario ${info?.nombre}`,true )
            }
            else {
                message.error('Error al obtener la informacion')

            }
        }).catch(error => {
            message.error('Error al obtener la informacion')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    render() {
 

        const { usuario } = this.state;

        return (

           <Layout>
                <Content className="admin-content content-bg pd-1">

                    <Row className="w-100 row-card">
                        <Col span={20}>
                            <Title level={3}>
                                Nombre
                            </Title>

                            <Title level={4}>
                            <CustomAvatar
                                name={usuario?.nombre + ' ' + usuario?.apellido} image={usuario?.avatar} 
                                className="avatar-user avatar-big"/>
                                {usuario?.nombre} {usuario?.apellido}
                            </Title>
                        </Col>

                        <Col span={6}>
                            <Title level={3}>
                                Email
                            </Title>

                            <Title level={4}>
                                {usuario?.email}
                            </Title>
                        </Col>


                        <Col span={6}>
                            <Title level={3}>
                                Teléfono
                            </Title>

                            <Title level={4}>
                                {usuario?.telefono}
                            </Title>
                        </Col>


                        <Col span={6}>
                            <Title level={3}>
                                Activo
                            </Title>

                            <Title level={4}>
                                <Switch disabled checked={(usuario?.activo)} />
                            </Title>
                        </Col>


                        <Col span={6}>
                            <Title level={3}>
                                Administrador
                            </Title>

                            <Title level={4}>
                                <Switch disabled checked={(usuario?.tipo === 1)} />
                            </Title>
                        </Col>


                    </Row>
                </Content>


            </Layout>

        )
    }


}



export default DetalleUsuarios;