import React from 'react'
import { Route, Switch } from "react-router-dom";

//componentetes
import Productos from '../../components/Admin/Productos/Productos';
import Variantes from '../../components/Admin/Variantes/Variantes';



/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterProductos( props) {
    return (
        <Switch>
            <Route exact path="/admin/productos" render={() => <Productos {...props} />} />
            <Route exact path="/admin/productos/:producto_id/variantes" render={(props_p) => <Variantes {...props} {...props_p}/>} />
        </Switch>
    )
}

export default RouterProductos;
