import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, PageHeader, Button, Avatar, Switch, List, Card, Space, Typography } from 'antd';
import { Link, Redirect } from "react-router-dom";
import { ExclamationCircleOutlined, EyeOutlined, FormOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import CustomAvatar from "../../Widgets/Avatar/Avatar";

//modal
import ModalTipoCliente from './ModalTipoCliente'
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton"
import './../../../Styles/Modules/Usuarios/usuarios.css'

const { Header, Content } = Layout;
const { Text } = Typography;
const { Search } = Input;
const { confirm } = Modal;

const axios = require("axios").default;

class TipoCliente extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataTipos: [],
			itemCount: undefined,
			perPage: 10,
			pageCount: 1,
			currentPage: 1,
			slNo: 1,
			hasPrevPage: false,
			hasNextPage: false,
			prev: null,
			next: null,
			tipo_id: undefined
		}
	}
	/**
	 * @memberof TipoCliente
	 *
	 * @method componentDidMount
	 * @description Se define header de axios, se llama metódo de consulta inicial receivedData
	 *
	 **/

	componentDidMount() {
		/* this.props.setTitle('Usuarios') */
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.receivedData();
	}


	/**
	* @memberof TipoCliente
	*
	* @method   receivedData
	* @description  Metodo que realiza el paginado de los usuaros
	* @param values String  o Number con la info a buscar, por defecto cadena vacia para busquedas
	*
	**/
	receivedData = (page) => {
		this.state.dataTipos = [];


		axios.get('/tipos', {
			params: {
				page: this.state.currentPage,
				limit: 10,
				paginate: true,
				search: this.state.search,
			}
		})
			.then(res => {
				console.log(res.data.data)
				if (res.data != undefined) {
					this.setState({
						dataTipos: res.data.data.itemsList,
						currentPage: res.data.data.paginator.currentPage,
						itemCount: res.data.data.paginator.itemCount,
					});
				}
			}).catch(error => {
				console.log("error", error);
				message.error('Error al traer la informacion')
			})
	}




	/**
	  * @memberof TipoCliente
		*
		* @method   handlePageClick
		* @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
		*
		**/
	handlePageClick = page => {
		this.setState({ currentPage: page }, () => {
			this.receivedData();
		})
	};

	/**
	* @memberof TipoCliente
	  *
	  * @method   handlePageClick
	  * @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
	  *
	  **/
	handleSearch = search => {
		this.setState({ search: search }, () => {
			this.receivedData();
		})
	};

	/**
	  * @memberof TipoCliente
	  *
	  * @method   showDeleteConfirm
	  * @description  Se muestra modal de confirmación para eliminar un usuario 
	  *
	  **/
	showDeleteConfirm = (item) => {
		const tm_nombre = item.nombre;
		const tm_id = item._id;

		confirm({
			title: 'Eliminar tipo',
			icon: <ExclamationCircleOutlined />,
			content: '¿Estas seguro de eliminar el tipo ' + tm_nombre + ' ?',
			okText: 'Continuar',
			okType: 'danger',
			cancelText: 'Cancelar',
			onOk: () => {
				axios({
					method: 'delete',
					url: '/tipos/delete',
					data: { id: tm_id }
				})
					.then((response) => {
						message.success('Tipo eliminado');
						this.receivedData()
					}).catch((error) => {
						message.error('Error al eliminar');
					})
			}
		})
	}

	render() {

		return (
			<>	
				<PageHeader
                    className="site-page-header"
                    title={
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                <span className="ant-page-header-heading-title">
									Tipo de Cliente
                                </span>
                            </Col>
                        </Row>
                    }
                />
                <Content className="admin-content content-bg pd-1">
					<Row className="mb-1">
						<Col xs={24} md={12}>
							<Search
								className="search-bar"
								placeholder="Buscar..."
								enterButton="Buscar"
								onSearch={this.handleSearch}
							/>
						</Col>
					</Row>

					<List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Usuarios" }}
                        dataSource={this.state.dataTipos}
                        pagination={{
                            current: this.state.page,
                            pageSize: 50,
                            total: this.state.total,
                            showSizeChanger: false,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            className:"flex-left",
                            onChange: this.handlePageClick
                            
                        }}

						header={
							(
								<Row className="width-100 ">
	                                    <Col span={6}  className="center">
											<Text strong>Nombre</Text>
	                                    </Col>
	                                    <Col span={6}  className="center">
	                                        <Text strong>Descuento</Text>
	                                    </Col>
	                                    <Col span={6}  className="center">
											<Text strong>Color</Text>
                                        </Col>
	                                    <Col span={6} className="center">
											<Text strong>Acciones</Text>
	                                    </Col>
                                    </Row>
							)
						}
                        

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">
	                                    <Col span={6}  className="center">
											<Text strong>{item.nombre}</Text>
	                                    </Col>
	                                    <Col span={6}  className="center">
	                                        <Text strong>{item.descuento}</Text>
	                                    </Col>
	                                    <Col span={6}  className="center">
                                            <CustomAvatar color={item.color} />
                                        </Col>
	                                    <Col span={6} className="center">
	                                        <Space>
	                                           
	                                          {/*<Link to={`/admin/usuarios/detalle/${item._id}`}>
													<Button
														className="mr-1"
														title="Detalle"
														icon={<EyeOutlined />}
													/>
												</Link>*/}

												<Button
													title="Editar"
													type="secondary"
													icon={<FormOutlined   style={{  color: "currentcolor" }} />}
													onClick={() => {
														this.setState({ ModalTipoCliente: true, tipo_id: item._id })
													}}
												>
												</Button>
												<Button
													title="Eliminar"
													type="danger"
													onClick={() => this.showDeleteConfirm(item)}
													icon={<DeleteOutlined  style={{  color: "currentcolor" }} />}>
												</Button>
	                                          
	                                        </Space>
	                                    </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />
					
				</Content>
				
				<FloatingButton title={'Agregar Usuario'} onClick={() => this.setState({ ModalTipoCliente: true })}/>
				
				<ModalTipoCliente
					visible={this.state.ModalTipoCliente}
					tipo_id={this.state.tipo_id}
					onCancel={() => {
						this.setState({ ModalTipoCliente: false, usuario_id: undefined })
						this.receivedData()
					}}
				/>
			</>
		)
	}
}



export default TipoCliente;