import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, PageHeader, Tag, Button, Space, Typography, Card, Popconfirm, List } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';

//componentes
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";
import { IconBus, IconEye, IconEdit, IconDelete } from "../../Widgets/Iconos";

//modales
import ModalCuentas from './ModalCuentas'

const { Content } = Layout;
const { Title, Text } = Typography

const axios = require("axios").default;
const moment = require('moment');



const colors = ["#00000", "#87d068", "#FA9100"];
/**
 *
 *
 * @class Cuentas
 * @extends {Component}
 */
export default class Cuentas extends Component {
    constructor(props) {
        super(props);
        this.state = {

            data: [],
            page: 1,
            limit: 10,
            total: 0
        }
    }

    /**
    * @memberof Cuentas
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    *
    *
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getCuentas()
    }


    /**
    * @memberof Cuentas
    * @method getCuentas
    * @description Trae las cuentas 
    */
    getCuentas = (page = this.state.page) => {

        this.setState({loading: true})
        axios.get('/cuentas/list', {
            params: {
                page: page,
            }
        }).then(response => {
            this.setState({
                cuentas: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }





    render() {

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title={
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                <span className="ant-page-header-heading-title">
                                    Cuentas
                                </span>
                            </Col>
                        </Row>
                    }
                />
                <Content className="admin-content content-bg pd-1">

                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Cuentas" }}
                        dataSource={this.state.cuentas}
                        pagination={{
                            current: this.state.page,
                            pageSize: 10,
                            total: this.state.total,
                            showSizeChanger: false,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            className:"flex-left",
                            onChange: this.getCuentas
                            
                        }}
                        header={<Row className="width-100 pr-1 pl-1" >
                            <Col span={3}  className="center">
                                <Text strong></Text>
                            </Col>

                            <Col span={7}  className="center">
                                <Text strong>Nombre</Text>
                            </Col>
                            <Col span={5}  className="center">
                                <Text strong>Banco</Text>
                            </Col>
                            <Col span={5}  className="center">
                                <Text strong>Saldo</Text>
                            </Col>
                            
                            <Col span={4}  className="center">
                                <Text strong>Acciones</Text>
                            </Col>

                        </Row>
                        }

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">

                                    <Col span={3}  className="center">
                                        <CustomAvatar name={item.nombre} image={item.logo} color={item.color} />
                                    </Col>

                                    <Col span={7}  className="center">
                                        <Text strong>{item.nombre}</Text>
                                    </Col>
                                    <Col span={5}  className="center">
                                        <Text strong>{item.banco}</Text>
                                    </Col>
                                    <Col span={5}  className="center">
                                        <Text strong>$ {item.saldo.toMoney()} MXN</Text>
                                    </Col>
                                        <Col span={4} className="center">
                                            <Space>

                                                <Button type="primary" icon={<IconEdit/>} onClick={()=>this.setState({modal_visible: true, cuenta_id: item._id})}/>
                                               
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Deseas eliminar esta cuenta?"
                                                    onConfirm={() => axios.post('/cuentas/delete',  { id: item._id }).then((response) => {
                                                        message.success('Cuenta eliminada')
                                                        this.getCuentas()
                                                    })
                                                        .catch((error) => {
                                                            message.error(error?.response?.data?.message);
                                                            this.getCuentas();
                                                        })
                                                    }
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    <Button type="normal" danger  title="Eliminar" icon={<IconDelete/>}/>
                                                </Popconfirm>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />


                </Content>

                <FloatingButton title={'Agregar Usuario'} onClick={() => this.setState({ modal_visible: true })}/>
                
                <ModalCuentas 
                    visible={this.state.modal_visible}
                    onCancel={()=>{
                        this.setState({modal_visible: false, cuenta_id: undefined})
                        this.getCuentas()
                    }}
                    cuenta_id={this.state.cuenta_id}
                />


            </>
        )
    }
}

