import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, PageHeader, Button, Typography, List, Card, Space } from 'antd';
import { Link } from "react-router-dom"
import { ExclamationCircleOutlined, FormOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

//componentes
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";
import { IconBus, IconEye, IconEdit, IconDelete } from "../../Widgets/Iconos";

//modales
import ModalCategorias from './ModalCategorias'

const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;
const { Text } = Typography

const axios = require("axios").default;



class Categorias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombreVista: '',
      user: 'Administrador',
      dataCategorias: [],
      itemCount: undefined,
      perPage: 10,
      pageCount: 1,
      currentPage: 1,
      slNo: 1,
      hasPrevPage: false,
      hasNextPage: false,
      prev: null,
      next: null,
      categoria_id: undefined
    }
  }

    /**
    * @memberof Categorias
    *
    * @method handlePageClick
    * @description  Envia los datos del formulario al Servidor
    *
    * @param values (array)
    * Contiene los campos del formulario para registrar al usuario 
    *
    * @returns response (array)
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCategorias();
    }

    /**
    * @memberof Categorias
    *
    * @method   getCategorias
    * @description  Metodo que realiza el paginado de las categorias
    * @param values  Number con la pagina a buscar, por defecto cadena vacia para busquedas
    *
    **/
    getCategorias = (page = this.state.page) => {
        axios.get('/categorias', {
            params: {
                page: page,
                limit: 10
            }
        }).then(response => {
            this.setState({
                cuentas: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
            })
        }).catch(error => {
            console.log("error", error);

        })
    }


    /**
     * @memberof Categorias
     *
     * @method   showDeleteConfirm
     * @description  comentar codigo por favor
     *
     **/
    showDeleteConfirm = (item) => {
        const cat_nombre = item.nombre;
        const cat_id = item._id;

        confirm({
            title: 'Eliminar Categoria',
            icon: <ExclamationCircleOutlined />,
            content: '¿Estas seguro que deseas eliminar la categoria ' + cat_nombre + ' ?',
            okText: 'Continuar',
            okType: 'danger',
            cancelText: 'Cancelar',

            onOk: ()=> {

                axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');

                axios({
                    method: 'delete',
                    url: '/categorias/delete',
                    headers: { Authorization: sessionStorage.getItem('token') },
                    data: { id: cat_id }
                })
                .then((response) => {
                  this.getCategorias()
                })
                .catch((error) => {
                    console.log(error)
                    message.error('Error al eliminar')
                })
            },
        });
    }

  /**
  * @memberof Usuarios
  *
  * @method   handlePageClick
  * @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
  *
  **/
  handlePageClick = page => {
    this.getCategorias(page);
  };

    render() {


        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title={
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                <span className="ant-page-header-heading-title">
                                    Categorias
                                </span>
                            </Col>
                        </Row>
                    }
                />
                <Content className="admin-content content-bg pd-1">

                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Cuentas" }}
                        dataSource={this.state.cuentas}
                        pagination={{
                            current: this.state.page,
                            pageSize: 10,
                            total: this.state.total,
                            showSizeChanger: false,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            className:"flex-left",
                            onChange: this.getCuentas
                            
                        }}
                        header={<Row className="width-100 pr-1 pl-1" >

                            <Col span={5}  className="center">
                                <Text strong>Nombre</Text>
                            </Col>
                            <Col span={15}  className="center">
                                <Text strong>Descripción</Text>
                            </Col>
                            <Col span={4}  className="center">
                                <Text strong>Acciones</Text>
                            </Col>

                        </Row>
                        }

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">

                                    <Col span={5}  className="flex-left">
                                        <Text strong>{item.nombre}</Text>
                                    </Col>
                                    <Col span={15}  className="center">
                                        <Text strong>{item.descripcion}</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Space>

                                            <Button
                                                title="Editar" 
                                                type="secondary" 
                                                onClick={()=>this.setState({modalCategrias: true, categoria_id: item._id})}
                                                icon={<FormOutlined  style={{  color: "currentcolor" }} />}> 
                                            </Button>
                                            <Button 
                                                title="Eliminar" 
                                                type="danger" 
                                                onClick={()=>this.showDeleteConfirm(item)} 
                                                icon={<DeleteOutlined  style={{  color: "currentcolor" }} />}>
                                            </Button>
                                        </Space>
                                    </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />


                </Content>

                <FloatingButton title={'Agregar Usuario'} onClick={() => this.setState({ modalCategrias: true })}/>
                
                <ModalCategorias
                    visible={this.state.modalCategrias}
                    onCancel={()=>{
                        this.setState({modalCategrias: false, categoria_id: undefined})
                        this.getCategorias()
                    }}
                    categoria_id={this.state.categoria_id}
                />


            </>
        )


    }
}

export default Categorias;