import React from 'react'
import { Route, Switch } from "react-router-dom";
import Precios from '../../components/Admin/Precios/Precios';
import PrecioDetalle from '../../components/Admin/Precios/PrecioDetalle';
// componentes

/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function PreciosRouter( props) {
    return (
        <Switch>
            <Route exact path="/admin/precios" render={() => <Precios {...props} />} />
            <Route exact path="/admin/precios/detalle/:producto_id" render={(props_p) => <PrecioDetalle {...props} {...props_p}/>} />
        </Switch>
    )
}

export default PreciosRouter;
