import React from 'react'
import { Route, Switch } from "react-router-dom";
import Cuentas from '../../components/Admin/Cuentas/Cuentas';

// componentes

/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterVentas( props) {
    return (
        <Switch>
            <Route exact path="/admin/cuentas" render={() => <Cuentas {...props} />} />
        </Switch>
    )
}

export default RouterVentas;
