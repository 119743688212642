import React, { Component } from 'react';
import { Dropdown, Menu, Col, Row, Typography, Layout, Input, Space, Button, Form, message } from 'antd';
import { PhoneFilled, RightSquareOutlined, } from '@ant-design/icons';
import { FaEnvelope } from "react-icons/fa";
import { Link } from 'react-router-dom';

import { IconSNYoutube, IconSNLinkedIn, IconSNTwitter, IconSNFacebook, IconSNInstagram, IconContactoFt, IconConversation } from './../Widgets/Iconos'

import '../../Styles/Global/footer.scss';
import '../../Styles/Global/footer.css';
import { SetUser, User } from "./../../Hooks/Logged"
import Login from "./../Auth/Login"
import Register from "./../Auth/Register"

const { Footer } = Layout;
const axios = require('axios').default;
const { Title, Paragraph, Text} = Typography



/**
 * @const Boletin
 * @description Boletin informativo
 */

class Boletin extends Component {
    static contextType = User;
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            isAdmin: false,
            isLogged: false,
        }
    }

    componentDidMount() {
    }

/**
    * @memberof Boletin
    *
    * @method onFinish
    * @description  Registra un usuario para el NewsLetter
    *
    **/
    onFinish = (values) => {
        axios.post('/suscriptores/add', {
            email: values.email
        }).then((res) => {
            message.success('Se ha suscrito con exito');
        }).catch((error) => {
            message.error('Error al suscribirse')
        })
    }



    render() {
        return (
            <section className="footer-public">
                <div className="section-boletin">
                    <Row className="footer-section w-100">
                        <Col xs={24} xl={12} xxl={13}>
                            <Row className="w-100">
                                <Col span={6}>
                                    <svg width="106" height="92" viewBox="0 0 116 102" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ display: "inline-block" }}>
                                        <path d="M32.2222 89.25C27.0667 89.25 21.9111 84.7875 19.3333 82.875C6.44444 73.95 2.57778 70.7625 0 68.85V95.625C0 99.1452 2.8859 102 6.44444 102H58C61.5585 102 64.4444 99.1452 64.4444 95.625V68.85C61.8667 70.7625 58 73.95 45.1111 82.875C42.5333 84.7875 37.3778 89.25 32.2222 89.25ZM58 51H6.44444C2.8859 51 0 53.8548 0 57.375V60.5625C5.15556 64.3875 4.51111 64.3875 23.2 77.775C25.1333 79.05 29 82.875 32.2222 82.875C35.4444 82.875 39.3111 79.05 41.2444 78.4125C59.9333 65.025 59.2889 65.025 64.4444 61.2V57.375C64.4444 53.8548 61.5585 51 58 51ZM109.556 31.875H45.1111C41.5526 31.875 38.6667 34.7298 38.6667 38.25V44.625H58C64.6881 44.625 70.2022 49.6891 70.8305 56.1438L70.8889 56.1V82.875H109.556C113.114 82.875 116 80.0202 116 76.5V38.25C116 34.7298 113.114 31.875 109.556 31.875ZM103.111 57.375H90.2222V44.625H103.111V57.375ZM32.2222 38.25C32.2222 31.2196 38.0041 25.5 45.1111 25.5H90.2222V6.375C90.2222 2.8548 87.3363 0 83.7778 0H19.3333C15.7748 0 12.8889 2.8548 12.8889 6.375V44.625H32.2222V38.25Z" fill="white"   />
                                    </svg>
                                </Col>
                                <Col span={18}>
                                    <Title level={2} className="text-white" style={{ textAlign: 'start' }}>Suscribete a Nuestro Newsletter</Title>
                                    <Paragraph className="text-white" style={{ textAlign: 'start' }}>
                                    Obten las mejores promociones y ofertas de Materiales Manatial.
                                    </Paragraph>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} xl={12} xxl={11}>
                            <div className="section-boletin-email">
                                <Form
                                    style={{display: 'flex', justifyContent: 'space-between'}}
                                    onFinish={this.onFinish}
                                >
                                    <Form.Item
                                        name="email"
                                        style={{textAlign: 'start'}}
                                        rules={[{ type: 'email' }]}
                                    >
                                        <Input size="large" placeholder="Introduce tu correo electronico aqui" className="w100" bordered={false} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button htmlType="submit" type="ghost" className="btn-boletin" >SUBSCRIBE</Button>
                                    </Form.Item>

                                </Form>

                            </div>
                        </Col>
                    </Row>
                </div>

            </section>

        )
    }
}


/**
 * @const Footer
 * @description Footer 
 */

class _Footer extends Component {
    static contextType = User;
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            isAdmin: false,
            isLogged: false,
            modalLoginVisible: false,
            modalRegisterVisible: false,
        }
    }

    componentDidMount() {
    }

    cerrarModal = () => {
        this.setState({
            modalLoginVisible: false,
            modalRegisterVisible: false
        })
    }

    render() {
        return (
            <section className="footer">



                <div className="cnt-footer">
                    <Row className="row-cnt-footer">

                        <Col xs={24} lg={12} xl={8} xxl={7}>
                            <Row className="mb-3">
                                <Col span={24}>
                                    <Link to="/"> <img src={"/images/logo.svg"} width={170} className="mb-1" alt="ft-logo" /> </Link>

                                </Col>
                                <Col span={23}>
                                    <p className="text-grey">
                                    Inventarios robustos de producto terminado que nos permiten ofrecer el mejor nivel de servicio en pedidos a primera factura
                                    </p>

                                </Col>
                                <Col span={24}>
                                    {/*<Button className="btn-socialNet"><IconSNYoutube /></Button>
                                    <Button className="btn-socialNet"><IconSNLinkedIn /></Button>
                                    <Button className="btn-socialNet"><IconSNTwitter /></Button>*/}
                                    <a href="https://www.facebook.com/materialesmanantial" target="_blank"><Button className="btn-socialNet"><IconSNFacebook /></Button></a>
                                    <a href="https://www.instagram.com/materiales.manantial" target="_blank"><Button className="btn-socialNet"><IconSNInstagram /></Button></a>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} lg={12} xl={16} xxl={17}>
                            <Row className="mb-3">
                                <Col xs={12} xl={6} >
                                    <Title level={5} >QUICK LINKS</Title>
                                    <ol className="footer-links">
                                        <li><Link to="#">Acerca de nosotros   </Link></li>
                                        <li><Link to="#">Contactanos   </Link></li>
                                        <li><Link to={`/store`} 
                                            onClick={()=>{
                                                if(this.props.setCategoria){
                                                    this.props.setCategoria('all')
                                                    this.props.setHot(false)
                                                }
                                            }} >Productos   </Link></li>
                                        <li><Link to="#">Terminos   </Link></li>
                                        <li><Link to="#">Politica de privacidad  </Link></li>
                                    </ol>

                                </Col>
                                <Col xs={12} xl={6}>
                                    <Title level={5} >AREA DE CLIENTE</Title>
                                    <ol className="footer-links">
                                        {
                                            this.context?.nombre ? <>
                                                <li><Link to={'/user/orders'}>Mi cuenta   </Link></li>
                                                <li><Link to={'/user/orders'}>Ordenes   </Link></li>
                                                <li><Link to={'/user/orders'}>Rastreo de ordenes   </Link></li>
                                                <li><Link to={'/cart'}>Mi carrito   </Link></li>
                                            </> : <>
                                                <li><Link onClick={()=>this.setState({modalLoginVisible: true})} to="#">Iniciar Sesion   </Link></li>
                                                <li><Link onClick={()=>this.setState({modalRegisterVisible: true})} to="#">Registrar    </Link></li>
                                            </>
                                        }

                                    </ol>
                                </Col>
                                <Col xs={24} md={20} xl={12}>
                                    <Row className="mb-1">
                                        <Title level={5} >CONTACT</Title>
                                        <p>Tenemos soporte 24/7 para nuestros clientes, nos puedes llamar o chatear con nosotros!</p>
                                    </Row>

                                    <Row>
                                        <Col className="mr-1">
                                            <IconContactoFt />
                                        </Col>
                                        <Col span={10}>
                                            <p>¿Tienes alguna pregunta?</p>
                                            <a href='tel:5627227584'><Title level={5} className="txt-morado" >56 2722 7584</Title></a>
                                            
                                        </Col>

                                        <Col span={10}>
                                           <Button type="primary" ghost className="btn-chat" > CHAT EN VIVO </Button>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className="pie-footer">
                    <Row className="row-pie-footer">
                        <Col xs={24} lg={10} xl={14}>
                            <p>Materiales Manantial  -   © 2022 Todos los Derechos Reservados</p>
                        </Col>
                        <Col xs={24} lg={14} xl={10}>
                            <Row>
                                <Col xs={24} md={4} lg={5} xl={6} className="center">
                                    <Text>Pagos</Text>
                                </Col>
                                <Col xs={4} md={4} lg={4} className="center">
                                    <img src={"/images/stripe.png"}  width="80" className="w100" alt="img-pym-1" />
                                </Col>
                                <Col xs={8} md={6} lg={4} className="center">
                                    <img src={"/images/visa.png"} width="80" className="w100" alt="img-pym-2" />
                                </Col>
                                <Col xs={6} md={5} lg={4} className="center">
                                    <img src={"/images/master.png"} width="50" className="w100" alt="img-pym-4" />
                                </Col>
                                <Col xs={6} md={5} lg={4} className="center">
                                    <img src={"/images/ae.png"} width="80" className="w100" alt="img-pym-4" />
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </div>
                <Login
                    onClickRegistrarmeButton={() => this.setState({
                        modalRegisterVisible: true,
                        modalLoginVisible: false
                    })}
                    visible={this.state.modalLoginVisible}
                    onCancel={this.cerrarModal}
                />
                <Register
                    onClickLoginButton={() => this.setState({
                        modalRegisterVisible: false,
                        modalLoginVisible: true
                    })}
                    visible={this.state.modalRegisterVisible}
                    onCancel={this.cerrarModal}
                />

            </section>
        )
    }
}


export { Boletin, _Footer };