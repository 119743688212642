import React, { Component } from "react";
import { Layout, Row, Col, message, PageHeader, Typography, Card, List } from 'antd';
import { Link } from "react-router-dom";
//componentes
import CustomAvatar from "../../Widgets/Avatar/Avatar";
//modales
const { Content } = Layout;
const { Text } = Typography

const axios = require("axios").default;


/**
 * @class CRM
 * @extends {Component}
 * @desciption Clase que conntiene la vista del CRM (CLIENTES)
 */
export default class CRM extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],

            page: 1,
            limit: 20,
            total: 0
        }
    }

    /**
    * @memberof Compras
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getClientes()
    }


    /**
    * @memberof Compras
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    **/
    getClientes = (page = this.state.page) => {
        this.setState({loading: true})
        axios.get('/cliente/list',{
            params:{
                tipo: 2, //clientes
                paginate: true,
                ventas: true,
                limit: 20,
            }
        }).then(response => {
            console.log("response", response.data.data);
            this.setState({
                data: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la información')
        }).finally(()=>this.setState({loading: false}))
    }

    render() {

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title={
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                <span className="ant-page-header-heading-title">
                                    CRM
                                </span>
                            </Col>
                        </Row>
                    }
                />
                <Content className="admin-content content-bg pd-1">
                    <List
                        grid={{ gutter: 20, xs: 1, sm: 2, md: 4, lg: 4, xl: 5, xxl: 6 }}
                        dataSource={this.state.data}
                        loading={this.state.loading}
                        pagination={{
                            current: this.state.page,
                            pageSize: 20,
                            total: this.state.total,
                            showSizeChanger: false,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            className:"flex-left",
                            onChange: this.getClientes
                            
                        }}
                        renderItem={item => (
                        <List.Item>
                            <Link to={`/admin/crm/detalle/${item._id}`}>
                                <Card className="center cliente-card">
                                    <Row className="center">
                                        <CustomAvatar 
                                            name={item.nombre + ' ' + item?.apellido} 
                                            image={item.avatar}  
                                            size="large" 
                                            className="avatar-cliente mb-1"
                                            color={item.tipo_usuario_id?.color ? item?.tipo_usuario_id?.color : '#9664FF'}
                                        />
                                    </Row>
                                    <Row className="center">
                                        <Text ellipsis className="cliente-nombre center">{item.nombre} {item.apellido}</Text>
                                    </Row>
                                    <Row className="center">
                                        <Text className="cliente-credito center pt-1 pb-1">{item.dias_credito} Dias de Crédito'</Text>
                                    </Row>
                                    <Row className="center">
                                        <Text className="cliente-venta center">$ {(( item.ventas?.ventas_total ? item.ventas.ventas_total : 0) + (item.ordenes?.ventas_total ? item.ordenes.ventas_total : 0)).toMoney() } MXN</Text>
                                    </Row>
                                </Card>
                            </Link>
                        </List.Item>
                        )}
                    />
                </Content>

            </>
        )
    }
}

