import React, { Component } from 'react';
import { Col, Row, Space, Typography, Spin } from 'antd';
import { User } from "../../Hooks/Logged";
import { CheckoutForm } from '../Widgets/paypear/PaymentForm/Stripe'
import { loadStripe } from '@stripe/stripe-js';
import { Elements, } from '@stripe/react-stripe-js';

const { Paragraph } = Typography
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, { locale: 'en' });
const axios = require('axios');


export default class ResumenPago extends Component {

    static contextType = User

    stripeRefence = React.createRef()

    render() {

        let user = this.context
        return [
            <Space className="container-brands">
                <Paragraph>Powered By &nbsp;
                    <img width={80} src="/images/brands/stripe.svg" alt="Stripe" /> </Paragraph>
                <img width={80} src="/images/brands/visa.svg" alt="Visa" />
                <img width={80} src="/images/brands/mastercard.svg" alt="Mastercard" />
                <img width={80} src="/images/brands/american_express.svg" alt="American Express" />
                <img width={80} src="/images/brands/discover.svg" alt="Discover" />
            </Space>,
            <Row className="width-100">
                <Col span={24}>
                    <Spin spinning={this.props.loading}>
                        <Elements stripe={stripePromise}>
                            <CheckoutForm
                                setLoading={this.props.setLoading}
                                ref={this.stripeRefence}
                                paymentHandledSubmit={this.props.paymentHandledSubmit}
                                user={!(user == 0 || user == null || user == undefined) ? user : {
                                    ...this.props.direccion,
                                    name: this.props.direccion?.nombre
                                }}
                                total={100} />
                        </Elements>
                    </Spin>
                </Col>
            </Row>
        ]
    }
}