

import React, { Component } from "react";
import { Col, Form, message, Modal, Table, Tag, Row, Divider, Avatar, Switch, Button, Typography, List, Select } from 'antd';
import '../../../Styles/Global/modales.css';

const axios = require('axios').default;
const { Title, Text, Paragraph } = Typography;
const moment = require('moment')
const {Option} = Select

const colors = ["#00000", "#87d068", "#FA9100"];

/**
 * @class Cotizacion
 * @extends {React.Component}
 * @description Muestra la informacion si el tipo es cotizacion
 */
class Cotizacion extends Component{

    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            cotizacion: {},
        }
    }


    render() {
        return(
            <>
                    <List
                                loading={this.state.loading}
                                className="component-list pt-2"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sin Productos" }}
                                dataSource={[{},{},{}]}
                                
                                

                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        
                                            <Row className="width-100 ">

                                                <Col span={4}  className="center">
                                                    <Text strong>#</Text>
                                                </Col>
                                                <Col span={5}  className="center">
                                                    <Text strong>Producto</Text>
                                                </Col>
                                                <Col span={5}  className="center">
                                                    <Text strong>Cantidad</Text>
                                                </Col>
                                                <Col span={5}  className="center">
                                                    <Text strong>Unitario</Text>
                                                </Col>
                                                <Col span={5}  className="center">
                                                    <Text strong>Total</Text>
                                                </Col>
                                            </Row>
                                        
                                    </List.Item>
                                )}
                            />
                            <Divider/>
                            <Row className="center carrito-card">
                                <Col span={8} className="w-100">
                                        <Row>
                                            <Col span={12}>
                                                <Text className="utilidad">Utilidad</Text>
                                            </Col>
                                            <Col span={12} className="flex-right">
                                                <Text className="utilidad">{'{{#}} %'}</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <Text className="titulo">Subtotal</Text>
                                            </Col>
                                            <Col span={12} className="flex-right">
                                                <Text className="dato">{'${{Subtotal}}'}</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <Text className="titulo">IVA <Switch size="small" defaultChecked/></Text>
                                            </Col>
                                            <Col span={12} className="flex-right">
                                                <Text className="dato">{'${{IVA}}'}</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <Text className="titulo">Total</Text>
                                            </Col>
                                            <Col span={12} className="flex-right">
                                                <Text className="dato">{'${{Total}}'}</Text>
                                            </Col>
                                        </Row>
                                        <Row className="center">
                                            <Button className="mt-2 generar">Descargar Cotizacion</Button>
                                        </Row>
                                </Col>           
                            </Row>
                        
            </>
        )
    }

}

/**
 * @class Cotizacion
 * @extends {React.Component}
 * @description Muestra la informacion si el tipo es Orden de compra
 */
 class Orden extends Component{

    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            orden: {},
            loading: false,
        }
    }

    /**
    * @memberof Cotizaciones
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    *
    *
    **/
     componentDidMount() {
        /* this.props.setTitle('Ventas') */
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getDatos()

    }


    /**
	* @memberof CRMDetalle
	* @method getDatos
	* @description  Carga los datos de la vista
	**/
	getDatos = () => {
		this.setState({loading: true})
		axios.get('/orden/get',{
			params:{
				id: this.props.id
			}
		}).then(response => {
            this.setState({
                loading: false,
                orden: response.data.data.ordencompra[0],
                infoProductos: response.data.data.ordencompra[0].producto_info
            })

		}).catch(error => {
			console.log("error", error);

		})
	}


    render() {
        return(
            <>
                            <List
                                loading={this.state.loading}
                                className="component-list pt-2"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sin Productos" }}
                                dataSource={this.state.orden.productos}
                                
                                

                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        
                                            <Row className="width-100 ">

                                                <Col span={4}  className="center">
                                                    <Text strong>#</Text>
                                                </Col>
                                                <Col span={5}  className="center">
                                                    <Text strong>{this.state.infoProductos.filter(info => info._id == item.producto_id)[0].nombre}</Text>
                                                </Col>
                                                <Col span={5}  className="center">
                                                    <Text strong>{item.cantidad}</Text>
                                                </Col>
                                                <Col span={5}  className="center">
                                                    <Text strong>{`$ ${item.total.toMoney(true)} MXN`}</Text>
                                                </Col>
                                                <Col span={5}  className="center">
                                                    <Select className="estatus" defaultValue="1">
                                                        <Option value="1"> {'{{Estatus}}'} </Option>
                                                        <Option value="2"> {'{{Estatus}}'} </Option>
                                                        <Option value="3"> {'{{Estatus}}'} </Option>
                                                    </Select>
                                                </Col>
                                            </Row>
                                        
                                    </List.Item>
                                )}
                            />
                            <Divider/>
                            <Row className="center carrito-card">
                                <Col span={12} className="w-100">
                                        <Row>
                                            <Col span={12}>
                                                <Text className="utilidad">Estatus de Pago</Text>
                                            </Col>
                                            <Col span={12} className="flex-right">
                                                <Tag>{'{{Estatus}}'}</Tag>
                                            </Col>
                                        </Row>
                                        
                                        <Row>
                                            <Col span={12}>
                                                <Text className="utilidad">Utilidad</Text>
                                            </Col>
                                            <Col span={12} className="flex-right">
                                                <Text className="utilidad">{'{{#}} %'}</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <Text className="titulo">Subtotal</Text>
                                            </Col>
                                            <Col span={12} className="flex-right">
                                                <Text className="dato">{`$ ${this.state.orden.subtotal} MXN`}</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <Text className="titulo">IVA <Switch size="small" defaultChecked/></Text>
                                            </Col>
                                            <Col span={12} className="flex-right">
                                                <Text className="dato">{`$ ${this.state.orden.iva} MXN`}</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <Text className="titulo">Total</Text>
                                            </Col>
                                            <Col span={12} className="flex-right">
                                                <Text className="dato">{`$ ${this.state.orden.total} MXN`}</Text>
                                            </Col>
                                        </Row>
                                        <Row className="center">
                                            <Button className="mt-2 generar">Descargar Cotizacion</Button>
                                        </Row>
                                </Col>           
                            </Row>
                        
            </>
        )
    }

}








/**
 *
 *
 * @class ModalVenta
 * @extends {React.Component}
 * @description Formulario Detalle de Venta
 * @param tipo Se le envia el tipo de la transaccion si es (1 - Cotizacion) u (2 - Orden de compra) 
 */
class ModalVenta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            cotizacion: {},
        }
    }


    



    render() {
        const { venta } = this.state;
        return (

            <>
                <Col className="p-1">
                    <Row align="space-between bottom">
                        <Col>
                            <Text>{moment(this.props.id.createdAt).format('LLL')}</Text>
                        </Col>
                        <Col>
                            <Title>{(this.props.tipo == 1) ? "Cotizacion" : "Orden de Compra"}</Title>
                        </Col>
                        <Col>
                            <Row align="middle">
                                <Col><Avatar></Avatar></Col>
                                <Col><Text className="ml-1">{this.props.id.usuario_id[0].nombre}</Text></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {(this.props.tipo == 1) ? <Cotizacion id={this.props.id._id}/> : <Orden id={this.props.id._id}/>}
                        </Col>
                    </Row>
                </Col>
            </>

        )
    }
}




/**
 *@function ModalDetalle
 *@description 
 */
export default function (props) {

    let { visible, close } = props

    return (
        <Modal
            visible={visible}
            title={null}
            footer={null}
            maskClosable={true}
            destroyOnClose={true}
            closable={true}
            onCancel={close}
            zIndex={1000}
            onOk={close}
            width={850}
        >
            <ModalVenta {...props} />
        </Modal>
    )
}