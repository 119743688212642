import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, message, Upload, Checkbox, Spin, Radio, Space, DatePicker } from 'antd';
import { PlusOutlined,  DeleteOutlined, LoadingOutlined } from '@ant-design/icons';



const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment')

class ModalPagos extends Component {


    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
            proveedores: [],
            proveedor_variantes: [],
        }
    }

    refModalPagos = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

       
    }

   

   

    /**
     * @memberof ModalCuentas
     * @method onFinish
     * @description Obtiene los proveedores de la variante
     * 
     */
    onFinish = (values) => {
    	console.log(this.props)
     	axios.post('/pagos/add',{
     		...values,
     		cliente_id: this.props.cliente_id
     	}).then(response => {
     		message.success('Pago registrado')
     	}).catch(error =>{
     		console.log("error", error);
     		message.error('Error al registrar')
     	})   
    }

    


    render() {

        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.refModalPagos}
                onFinish={this.onFinish}
                initialValues={{
                	fecha: moment()
                }}

            >
                <Spin spinning={this.state.loading}>

                    <Row className="mt-2" justify="center" gutter={[20,20]} style={{minHeight: '200px'}}>
                        <Col span={20}>
                            <Form.Item
                                label="Monto a Abonar"
                                name="monto"
                                rules={[{ required: true, message: 'Por favor ingresa el monto a abonar' }]}
                            >
                                <InputNumber min={1} className="width-100"/>
                            </Form.Item>
                        </Col> 
                        <Col span={20}>
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                rules={[{ required: true, message: 'Por favor ingresa la fecha del pago' }]}
                            >
                                <DatePicker className="width-100"/>
                            </Form.Item>
                        </Col> 
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24} className="mt-1">
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading || this.state.loadingImage}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="flex-column">
            <Title level={3}>Pagar Cotizaciones</Title>
        </div>
        <ModalPagos {...props} />
    </Modal>

}