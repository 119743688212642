import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, InputNumber, Space, Divider } from 'antd';

import CustomAvatar from "../../../Widgets/Avatar/Avatar";

const axios = require('axios').default;
const { Option } = Select;
const { Title, Text } = Typography;
const { TextArea } = Input;
const moment = require('moment');

/**
 *
 *
 * @class ModalCompraDetalle
 * @extends {React.Component}
 * @description Formulario de categorias
 */
class ModalCompraDetalle extends Component {


    formModalCategoria = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            compra: {},
            costo: 0,
            total: 0,
            orden: {},
            productos: [],

        }
    }


    /**
     * @methodOf ModalCompraDetalle
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        
        if(this.props.orden_id){
            this.getOrden()
        }
    }

    /**
     * @methodOf ModalCompraDetalle
     *
     * @function getOrden
     * @description Obtiene la informacion de una compra
     *
     * */
    getOrden = () => {
        axios.get('/ordenes/detalle',{
            params:{
                orden_id: this.props.orden_id
            }
        }).then(response => {
            console.log("response", response.data);
            this.setState({
                orden: response.data.orden,
                productos: response.data.orden_detalles
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la información')
        })
    }






    render() {

        const { loading, compra, orden, productos } = this.state

        return (
            <>
                <div className="center">
                    <Title level={3} className="text-orange">Cotizacion {orden.folio} </Title>
                </div>
                <Row className="mt-1 mb-2">
                    <Col span={10} className="flex-left">
                        <Text strong className="text-orange">{moment(orden.createdAt).format('DD-MM-YYYY')}</Text>
                    </Col>
                    {this.props.change ? <Col span={14} className="">
                        <Space>
                            <Button 
                                className={`btn-materiales active`} 
                                onClick={() => { this.props.onChangeVista({tipo_vista: 'ventas'}) }}
                            >   
                                Cotizaciones
                            </Button>
                            
                            <Button 
                                className={`btn-materiales`} 
                                onClick={() => { this.props.onChangeVista({tipo_vista: 'compras'}) }}
                            >
                                Orden de Compra
                            </Button>
                        </Space>
                    </Col> : null}
                </Row>
                
                <Spin spinning={this.state.loading}>
                    <Row className="table-titles mt-1" gutter={[16,16]}>
                        <Col className="center" span={8}>
                            <Text>Producto</Text>
                        </Col>
                        <Col className="center" span={5}>
                            <Text>Cantidad</Text>
                        </Col>
                        <Col className="center" span={5}>
                            <Text>Precio</Text>
                        </Col>
                        <Col className="center" span={5}>
                            <Text>Total</Text>
                        </Col>
                        
                    </Row>
                    <div className="list-productos mt-1">
                        {
                            productos.map((producto,index) =>{
                                return <Form
                                    onValuesChange={(values, all) => this.updateProducto(all, index)}
                                    initialValues={{...producto}}
                                >
                                    <Form.Item name="total" hidden/>
                                    <Form.Item name="_id" hidden/>
                                    <Row gutter={[16,16]} style={{marginTop: '12px'}}>
                                        <Col span={8} className="flex-left-column">
                                            <Text ellipsis strong style={{fontSize: '12px'}}>{producto?.variante_id?.nombre}</Text>
                                            <Text ellipsis style={{fontSize: '12px'}}>{producto?.producto_id?.nombre}</Text>
                                        </Col>
                                        <Col span={5}  className="center">
                                            {/*<Form.Item name="cantidad">
                                                <InputNumber min={1} precision={0} className="width-100"/>
                                            </Form.Item>*/}
                                            <Text>{producto.cantidad}</Text>
                                        </Col>
                                        <Col span={5} className="center">
                                            {/*<Form.Item name="precio">
                                                <InputNumber min={1} precision={0} className="width-100"/>
                                            </Form.Item>*/}
                                            <Text>{producto.precio.toMoney()}</Text>
                                        </Col>
                                        <Col span={5} className="flex-right">
                                            <Text strong>$ {producto?.total_venta?.toMoney()} mxn</Text>
                                        </Col>
                                    </Row>
                                </Form>
                            })
                        }
                    </div>
                    <Divider/>
                    <div className="carrito-card mt-1">
                        <Row justify="center">
                            <Col span={6}>
                                <Text className="titulo">Subtotal</Text>
                            </Col>
                            <Col span={6} className="flex-right">
                                <Text className="dato">$ {orden?.subtotal_venta?.toMoney()} mxn</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={6}>
                                <Text className="titulo">IVA </Text>
                            </Col>
                            <Col span={6} className="flex-right">
                                <Text className="dato">$ {orden?.iva_venta?.toMoney()} mxn</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={6}>
                                <Text className="titulo">Total</Text>
                            </Col>
                            <Col span={6} className="flex-right">
                                <Text className="dato">$ {orden?.total_venta?.toMoney()} mxn</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={14} className="mt-1">
                                <Button 
                                    className="generar" 
                                    htmlType="submit" 
                                    type="primary" 
                                    loading={this.state.loading}
                                    onClick={()=>this.props.onCancel()}
                                >
                                   Cerrar
                                </Button>
                            </Col>
                        </Row>
                    </div>

                </Spin>
            </>
        )
    }
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {

    let { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
            width={700}
        >
            <ModalCompraDetalle {...props} />
        </Modal>
    )
}