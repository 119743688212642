import React, { Component } from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import "../../Styles/Global/sidebar-admin.css";

import { User } from '../../Hooks/Logged';

import { FaCreditCard, FaBox, FaDollyFlatbed, FaIdCardAlt, FaTags} from "react-icons/fa";
import { AppstoreFilled, MonitorOutlined, SwapOutlined, WalletOutlined, ProfileOutlined, PartitionOutlined, FontSizeOutlined, LineChartOutlined } from '@ant-design/icons';
import { IconUsuarios, IconHaciendas, IconPOS, IconProductos, IconCategorias, IconVentas, IconCompras, IconInventarios, IconCRM, IconCotizaciones, IconCostos } from '../Widgets/Iconos'


const { Sider } = Layout;
const { SubMenu } = Menu;

/**
 * @export
 * @class Sidebar
 * @extends {Component}
 */
class Sidebar extends Component {

    state = {
        selectedProject: null,
        loading: true,
        proyectos: {
            data: [],
            page: 1,
            limit: 10,
            total: 0,
        },
    }



    render() {

        const { user } = this.props
        const { roles } = user || {}

        return (
            <Sider
                theme="light"
                className=""
                width={250}
                breakpoint="lg"
                collapsedWidth="0"
            >
                <Menu className="hm-menu" defaultSelectedKeys={['0']} mode="inline" >
                    <Menu.Item key="0" icon={<AppstoreFilled />}>
                        <Link to={"/admin/dashboard"}>
                            Dashboard
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="1" icon={<IconVentas />}>
                        <Link to={"/admin/ventas"}>
                            Ventas
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="2" icon={<IconCompras />}>
                        <Link to={"/admin/compras"}>
                            Compras
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="3" icon={<IconInventarios />}>
                        <Link to={"/admin/inventario"}>
                            Inventario
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="4" icon={<FaBox />}>
                        <Link to={"/admin/productos"}>
                            Productos
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="5" icon={<FaDollyFlatbed />}>
                        <Link to={"/admin/proveedores"}>
                            Proveedores
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="6" icon={<IconCotizaciones />}>
                        <Link to={"/admin/cotizaciones"}>
                            Ordenes
                        </Link>
                    </Menu.Item>


                    <Menu.Item key="7" icon={<IconCostos />}>
                        <Link to={"/admin/precios"}>
                            Precios
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="8" icon={<IconCRM />}>
                        <Link to={"/admin/crm"}>
                            CRM
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="9" icon={<IconUsuarios />}>
                        <Link to={"/admin/usuarios"}>
                            Usuarios
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="11" icon={<FaIdCardAlt />}>
                        <Link to={"/admin/tipos"}>
                            Tipos de cliente
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="10" icon={<FaCreditCard />}>
                        <Link to={"/admin/cuentas"}>
                            Cuentas
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="1ccc" icon={<FaTags />}>
                        <Link to={"/admin/categorias"}>
                            Categorias
                        </Link>
                    </Menu.Item>

                </Menu>
            </Sider>
        )
    }
}

export default function (props) {

    const user = React.useContext(User)

    return <Sidebar {...props} user={user} />

};