import React from 'react'
import { Route, Switch } from "react-router-dom";
import Variantes from '../../components/Admin/Variantes/Variantes';


/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterVariantes( props) {
    return (
        <Switch>
            <Route exact path="/admin/variantes/:producto_id" render={() => <Variantes {...props} />} />
        </Switch>
    )
}

export default RouterVariantes;
