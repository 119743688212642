import React, { Component, useContext } from 'react'
import { Modal, Row, Col, Form, Input, Button, Spin, Image, Checkbox, List, message, Typography, Alert } from 'antd';
import { UserOutlined, LockOutlined, PhoneOutlined } from '@ant-design/icons';
import { Link, Redirect } from "react-router-dom";



import { User, SetUser } from '../../Hooks/Logged';
import '../../Styles/Global/auth.css';
import { IconBus, IconBuyBag, IconUserCircle, IconHotDeals, IconCloseModal } from "../Widgets/Iconos";




const axios = require("axios").default;

const { Title, Text } = Typography;

class UpdatePassword extends Component {

    static contextType = SetUser


    /**
     *Creates an instance of UpdatePassword.
     * @param {*} props
     * @memberof UpdatePassword
     */
    constructor(props) {
        super(props);
        this.state = {
            log: false,
            isAdmin: false,
            isLogged: false,
            loading: false,
            isRecovery: false,
            isRecoveryLoading: false,
            redirect: false,
            form: {}
        }
    }

    recoveryPassword = React.createRef();

    redirectTo = "/login"


    /**
     *
     *
     * @memberof UpdatePassword
     */
    async componentDidMount(): void {
        const { token, email } = this.props.match.params;
        if (token === undefined || email === undefined) {
            this.redirectTo = "/login";
            this.setState({ redirect: true })
        } else {
            await this.setState({
                form: { email, token }
            }, () => this.recoveryPassword.current.resetFields())

        }
    }



    /**
     *
     *
     * @memberof UpdatePassword
     */
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={this.redirectTo} />;
        }
    };


    /**
     *
     *
     * @memberof UpdatePassword
     */
    onFinish = async values => {
        axios.put('/password/recovery/update', values)
            .then(() => {
                message.success("Tu contraseña ha sido actualizada!")
                this.props.history.push('/')
            })
            .catch(() => {
                Modal.error({
                    title: "Error al actualizar.",
                    content: "El token no es valido o el correo no existe."
                })
            })
    };

    recoveryPassword = React.createRef();

    render() {
        return (
            <section className="cnt-login">
                <Row className="w-100" >
                    <Col className="col-form"
                        xs={{ span: 24, order: 1 }}
                        sm={{ span: 24, order: 1 }}
                        md={{ span: 24, order: 1 }}
                        lg={{ span: 12, order: 1 }}
                        xl={{ span: 12, order: 1 }}
                        xxl={{ span: 12, order: 1 }}
                    >
                        <Spin spinning={this.state.loading}>
                            <Row className="w-100" justify="center" >
                                <Col xs={{ span: 24, offset: 2, pull: 1 }}
                                    sm={{ span: 24, offset: 0, pull: 0 }}
                                    md={{ span: 20, offset: 0 }}
                                    lg={{ span: 20, offset: 1, pull: 1 }}
                                    xl={{ span: 20, offset: 0, pull: 0 }}
                                    xxl={{ span: 20, offset: 0, pull: 0 }} >

                                    <Row justify="center" className="mb-3">
                                        <Image src={'/images/logo.svg'} wrapperClassName="center" preview={false} width={185} />
                                    </Row>

                                    <h3 className="h3-title">Registrarse</h3>
                                    <Form name="basic" layout={"vertical"} onFinish={this.onFinish} initialValues={this.state.form} ref={this.recoveryPassword} requiredMark={false}>
                                        {/* <Form onFinish={this.onFinish} layout={"vertical"} requiredMark={false} > */}

                                        <Form.Item name="token" noStyle >
                                            <Input type="hidden" />
                                        </Form.Item>
                                        <Form.Item
                                            label="Correo electronico"
                                            name="email"
                                            rules={[{
                                                required: true,
                                                message: 'Para iniciar sesión, necesitas ingresar tu correo electronico.'
                                            }]}
                                        >
                                            <Input size="large" className="input-login" />
                                        </Form.Item>

                                        <Form.Item
                                            name="password"
                                            label="Contraseña"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Por favor, ingresa la contraseña',
                                                },
                                            ]}
                                            hasFeedback
                                        >
                                            <Input.Password size="large" className="input-login" />
                                        </Form.Item>

                                        <Form.Item
                                            name="confirm"
                                            label="Confirmar contraseña"
                                            dependencies={['password']}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Por favor, confirma tu contraseña',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject('Las contraseñas no coinciden');
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password size="large" className="input-login" />
                                        </Form.Item>

                                        {/* <Form.Item
                                                label="Correo Electrónico"
                                                name="email"
                                                rules={[{
                                                    required: true,
                                                    message: 'Para poder iniciar sesión, debe haber ingresado su correo electrónico'
                                                }]}
                                            >
                                                <Input className="input-login" />
                                            </Form.Item> */}
                                        {/* <Form.Item name="password" rules={[{ required: true, message: 'Por favor ingresa tu  contraseña' }]}>
                                                <Input.Password type="password" placeholder="Contraseña" size="large" className="input-login" />
                                            </Form.Item> */}

                                        <Row>
                                            <Col span={12}>
                                                <Form.Item name="rememberme" >
                                                    <Checkbox className="checkbox-bdf " > Recuerdame </Checkbox>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>

                                                <Link to={'#'} onClick={() => this.setState({ isRecovery: true })} className="mb-1"> Olvide mi contraseña </Link>
                                            </Col>
                                        </Row>

                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" block size="large" className="button-login">Registrarse</Button>
                                        </Form.Item>

                                        <Form.Item>
                                            <p> Ya tienes una cuenta? <Link to={"#"} onClick={this.props.onClickLoginButton}> Inicia sesión  </Link></p>
                                        </Form.Item>

                                    </Form>

                                    {this.state.error?.success == false ?
                                        <Alert
                                            message="¡Ha ocurrido un error!"
                                            description={this.state.error.message}
                                            type="error"
                                            closable
                                            className="pd-1"

                                            onClose={() => this.setState({ error: { success: null, description: null } })}
                                        />
                                        : null}
                                </Col>
                            </Row>
                            <Row className="w-100">
                                <Col className="txt-pie-modal alg-l"> Copyright © 2021 <Text> Manantial Materiales </Text> Todos los derechos reservados. </Col>
                                <Col className="txt-pie-modal alg-r"> <Text>Terminos y Condiciones</Text> </Col>
                            </Row>
                        </Spin>
                    </Col>
                    <Col
                        xs={{ span: 24, order: 1 }}
                        sm={{ span: 24, order: 1 }}
                        md={{ span: 24, order: 1 }}
                        lg={{ span: 12, order: 2 }}
                        xl={{ span: 12, order: 2 }}
                        xxl={{ span: 12, order: 2 }}
                        style={{ background: "url('/images/bg-modal.png')" }} className="col-img" >
                    </Col>
                </Row >

                <Modal
                    title="I forgot my password"
                    visible={this.state.isRecovery}
                    onOk={() => this.recoveryPassword.current.submit()}
                    onCancel={() => this.setState({ isRecovery: false })}
                    maskClosable={!this.state.isRecoveryLoading}
                    okText="Enviar"
                    cancelText="Cancelar"
                >
                    <Form onFinish={this.handleRecoveryPassword} layout={"vertical"} className="pd-2" ref={this.recoveryPassword}>
                        <Form.Item
                            name="email"
                            label="Ingresa tu correo electronico."
                            rules={[{
                                required: true,
                                message: 'Por favor, ingresa tu correo electronico.'
                            }]}>
                            <Input type="email" size="large" placeholder="Ingresa tu correo electronico" />
                        </Form.Item>
                    </Form>
                </Modal>
            </section>
        )
    }
}






export default function (props) {


    const { visible, onCancel } = props

    let user = useContext(User)
    const setUser = useContext(SetUser)

    return <Modal
        visible={visible}
        onCancel={onCancel}
        width={1000}
        maskClosable={false}

        className="bdf-modal modal-radius"
        footer={null}

        closable={true}
        closeIcon={<div title="Cerrar"> <IconCloseModal /> </div>}

        destroyOnClose={true}
        mask={true}

        maskStyle={{
            backdropFilter: 'blur(0.5rem)'
        }}
    >
        <UpdatePassword {...props} user={user} setUser={setUser} />
    </Modal>

}