import React, { useState, useEffect, useContext, createElement } from 'react';
import { Link, Redirect } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Input, Dropdown, Menu, Badge, Tooltip, Comment, Spin, Grid, Avatar, } from 'antd';
import { BsBellFill } from "react-icons/bs";
import CustomAvatar from "../Widgets/Avatar/Avatar";
import { User, SetUser } from '../../Hooks/Logged'
// import io from "socket.io-client";

import './../../Styles/Modules/headers.scss'

const { Header } = Layout;
const { Search } = Input;
const moment = require('moment')
const { useBreakpoint } = Grid;
/**
 * @const Navbar
 * @description Header del sistema
 */
const Navbar = (props) => {

	let user = useContext(User)
	let setUser = React.useContext(SetUser)
	const [redirect, setRedirect] = useState(false)


	// NOTIFICACIONES
	const [loading, setLoading] = useState(false)
	const [notificaciones, setNotificaciones] = useState([])
	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(1)

	//socket init
	// const socket = io.connect(process.env.REACT_APP_WEB_SERVICE);


	/**
	 * @const cerrarSesion
	 * @description Cierra la sesion
	 */
	const cerrarSesion = () => {
		setRedirect(true);
		sessionStorage.clear();
		setUser(undefined);
	};

	/**
	 * @const useEffect
	 * @description inicializa los sockets para recibir las notificaciones
	 */
	useEffect(() => {
		if (user.tipo == 1) {
			console.log('CONECTANDO NOTIFICACIONES')
			// socket.emit('notifications-connect', sessionStorage.getItem('token'))
			// socket.on('notifications-on', IO_Conect);
		}
	}, [])


	/**
	 * @const IO_Conect
	 * @description Inicia la conexion al socket
	 */
	const IO_Conect = (data) => {
		console.log('data conect notif', data)
		if (data) {
			console.log('conectado a las notificaciones')
			// socket.emit('notifications_get')//pido las notificaciones
			// socket.on('list_notifications', IO_GetNotificaciones)//almaceno notificaciones
			// socket.on('notifications_new', addNotifications)
		}
		if (!data) {
			console.log('no conectado')
		}
	}

	/**
	 * @const IO_GetNotificaciones
	 * @description Almacena las notificaciones en una lista
	 */
	const IO_GetNotificaciones = (notifications) => {
		console.log('io lista de notificaciones ', notifications)
		const list = notifications.itemsList;
		const paginator = notifications.paginator;
		const currentItemsList = notificaciones;
		const newItemsList = list.concat(currentItemsList);

		setNotificaciones(newItemsList)
		setPage(paginator.page)
		setTotal(paginator.itemCount)

	}

	/**
	 * @const updateNotifications
	 * @description Manda la peticion al socket para traer las notificaciones paginadas
	 */
	const updateNotifications = () => {
		page = page + 1;
		setLoading(true)
		// this.socket.emit('notifications', { page: page });
	};


	/**
	 * @const addNotifications
	 * @description Realiza una peticion para cargar las notificaciones, ya que se creo una nueva.
	 */
	const addNotifications = () => {
		console.log('al recibir una nueva notificacion hago la solicitud de nuevo para cargar todas')
		// socket.emit('notifications_get')
	}
	/**
	 * @const handleScroll
	 * @description Manejo del scroll del dropdown para cargar mas notificaciones
	 */
	const handleScroll = (e) => {
		console.log('handleScroll e', e)
		if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
			updateNotifications();
		}
	};

	/**
	 * @const onCloseDropdown
	 * @description Cierra el dropdown y limpia las notificaciones
	 */
	const onCloseDropdown = async (e) => {
		if (!e) {
			setLoading(false)
			// socket.emit('notifications-read', notificaciones.map(item => item._id));
		}
	};


	const getNotifications = () => {
		return <Dropdown
			onVisibleChange={onCloseDropdown}
			overlay={
				<div onScroll={handleScroll}>
					{
						(notificaciones.length < 1) ? <Menu>
							<Menu.Item>
								<a>
									Sin notificaciones
								</a>
							</Menu.Item>
						</Menu> : <Menu className="notifications-list">{
							notificaciones?.map((notif, index) => {
								return <a href={`/admin/productos/${notif.variante_id.producto_id}/variantes/${notif.variante_id._id}/inventario`}><Menu.Item className="notification-element width-100" key={index} >
									<Comment
										actions={[
											<Tooltip key="comment-basic-like" title="Visto" onClick>
												<span >
													<span className="comment-action">{(notif.usuarios_destino[0].leido == true) ? 'leido' : 'Sin Leer'}</span>
												</span>
											</Tooltip>,
										]}
										author={<a>{notif.evento}</a>}
										content={<p className=' p-0 m-0 '>{`${notif.variante_id?.sku_variante} ${notif.detalle}`}</p>}
										datetime={
											<Tooltip
												title={moment(new Date(notif.createdAt.toString()), 'DD-MM-YYYU HH:mm:ss')}>
												<span>{moment(new Date(notif.createdAt.toString()), 'DD-MM-YYYU HH:mm:ss').fromNow()}</span>
											</Tooltip>
										}

									/>
								</Menu.Item>
								</a>
							})
						}
							{(loading) ? <Spin spinning={true} /> : null}
						</Menu>
					}
				</div>
			} trigger={['click']}
			className="notificaciones">
			<Badge count={total} overflowCount={99}>
				<BsBellFill className="icon-bell" style={{fontSize: '20px'}}/>
			</Badge>
		</Dropdown>
	}

	return (
		<Layout className='admin'>
			<Header className="header-admin">
				{redirect ? <Redirect to={'/'} /> : null}
				<Row className="width-100"  justify='space-between'>
					<Col className="logo-header center">
						<Link to={'/'}>
							<img src={'/images/logo-white.svg'} alt="logo" />
						</Link>
					</Col>
					<Col xxl={{ flex: 2 }} xl={{ span: 12 }} lg={{ span: 8 }} md={{ span: 10 }} sm={{ span: 10 }} xs={{ span: 24 }} className="flex-left">
						{props.showSearch ? <Search
							placeholder="Buscar"
							onSearch={(e) => { props.setFilterSearch(e) }}
							enterButton
							className="input-global-search flex-left"
						/> : null}
					</Col>
					<Col xxl={{ flex: 1 }} xl={{ span: 6 }} lg={{ span: 8 }} md={{ span: 6 }} sm={{ span: 6 }} xs={{ span: 24 }} >
						<Row className="width-100 header-options pd-1">
							<Col span={8} className="center">
								{getNotifications()}
							</Col>
							<Col span={16} className="center">
								<CustomAvatar 
									name={user?.nombre + ' ' + user?.apellido} 
									image={user?.avatar}  
									size="large" 
									className="avatar-user"
								/>
									<p className='pt-1 ml-1'>{user?.nombre}</p>
								<Dropdown
									arrow={true}
									overlay={
										<Menu>
											<Menu.Item key="0" onClick={cerrarSesion}>
												Cerrar Sesión
											</Menu.Item>
										</Menu>
									} trigger={['click']}>
									<div >
										<Link onClick={e => e.preventDefault()}
											style={{ marginLeft: '2rem' }}>
											{user?.nombres}{
												user?.vendedor ? ` (${user?.codigo_promocional})` : ''
											} <DownOutlined />
										</Link>
									</div>
								</Dropdown>
							</Col>
						</Row>

					</Col>
				</Row>
			</Header>
		</Layout>

	)
}

export default Navbar;