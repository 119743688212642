import React from 'react'
import { Route, Switch } from "react-router-dom";
import Categorias from '../../components/Admin/Categorias/Categorias';


/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCategorias( props) {
    return (
        <Switch>
            <Route exact path="/admin/categorias" render={() => <Categorias {...props} />} />
        </Switch>
    )
}

export default RouterCategorias;
