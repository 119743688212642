import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, InputNumber, Checkbox, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
const axios = require('axios').default;
const { Option } = Select;
const { Title, Text } = Typography;
const { TextArea } = Input;


/**
 *
 *
 * @class FormCategorias
 * @extends {React.Component}
 * @description Formulario de categorias
 */
class FormCategorias extends Component {


    formModalCompras = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            productos: [],
            variantes: [],
            proveedor_variantes: [],
            costo: 0,
            total: 0,
            edit_costo: false

        }
    }


    /**
     * @methodOf FormCategorias
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        
        this.getProductos()
    }

    /**
    * @memberof FormCategorias
    *
    * @method getProductos
    * @description  Obtiene el listado de productos
    *
    **/
    getProductos = search => {
        this.setState({loading: true})
        axios.get('/productos',{
            params:{
                search
            }
        }).then(response => {
            this.setState({
                productos: response.data.data.itemsList
            })
        }).catch(error => {
            console.log("error", error);

        }).finally(()=>this.setState({loading: false}))
    }

    /**
    * @memberof FormCategorias
    *
    * @method onSelectProducto
    * @description  Obtiene el listado de variantes del producto seleccionado
    *
    **/
    onSelectProducto = producto_id => {
        this.setState({producto_id: producto_id.producto_id.value},()=>{
            this.getVariantes()
        })
        this.formModalCompras.current.setFieldsValue({
            variante_id: null,
            proveedor_variante_id: null,
            cantidad: 0,
            costo_unitario: 0,
        })
        this.setState({costo: 0, total: 0, edit_costo: false,})
    }

    /**
    * @memberof FormCategorias
    *
    * @method getVariantes
    * @description  Obtiene el listado de variantes
    *
    **/
    getVariantes = (search) => {
        axios.get('/variantes',{
            params:{
                search,
                producto_id: this.state.producto_id
            }
        }).then(response => {
            this.setState({variantes: response.data.data.itemsList})
            
        }).catch(error => {
            console.log("error", error);

        }) 
    }

    /**
    * @memberof FormCategorias
    *
    * @method onSelectVariante
    * @description  Obtiene el listado de proveedores de la variante seleccionado
    *
    **/
    onSelectVariante = variante_id => {
        this.setState({variante_id: variante_id.variante_id.value},()=>{
            this.getProveedoresVariantes()
        })
        this.formModalCompras.current.setFieldsValue({
            proveedor_variante_id: null,
            cantidad: 0,
            costo_unitario: 0,
            
        })
        this.setState({costo: 0, total: 0, edit_costo: false})
    }

    /**
     * @memberof ModalCuentas
     * @method getProveedoresVariantes
     * @description Obtiene los proveedores de la variante
     * 
     */
    getProveedoresVariantes = () => {
        axios.get('/variantes/proveedores',{
            params:{
                variante_id: this.state.variante_id
            }
        }).then(response => {
            this.setState({proveedor_variantes: response.data.data})

        }).catch(error => {
            console.log("error", error);

        })
    }

    /**
    * @memberof FormCategorias
    *
    * @method onSelectproveedor
    * @description  Obtiene el listado de proveedores de la variante seleccionado
    *
    **/
    onSelectproveedor = proveedor_variante_id => {
        let index = this.state.proveedor_variantes.findIndex(e => e._id.toString() === proveedor_variante_id.toString())
        let cantidad = this.formModalCompras.current.getFieldValue('cantidad')
        if(index != -1){
            console.log(this.state.proveedor_variantes[index])
            this.setState({
                proveedor_variante_id: proveedor_variante_id,
                costo: this.state.proveedor_variantes[index].costo,
                total: cantidad !== undefined ? (cantidad * this.state.proveedor_variantes[index].costo) : 0,
                edit_costo: false,
            })
            this.formModalCompras.current.setFieldsValue({
                costo_unitario: this.state.proveedor_variantes[index].costo,
            })
        }
    }

    /**
    * @memberof FormCategorias
    *
    * @method onFinish
    * @description  Se ejecuta al dar finalizar al formulario
    *
    **/
    onFinish = values => {

        values.producto_id = values.producto_id.value
        values.variante_id = values.variante_id.value

        // if(this.state.edit_costo){
        //     Modal.confirm({
        //         title: 'Actualizar Costo Producto',
        //         content: 'El costo del producto será actualizado ¿Desea continuar?',
        //         okText: 'Si',
        //         cancelText: 'Cancelar',
        //         onOk: ()=>this.addCompra(values)
        //     })
        // }else
        this.addCompra(values)
        
    }

     /**
    * @memberof FormCategorias
    *
    * @method addCompra
    * @description  Se ejecuta al dar finalizar al formulario
    *
    **/
    addCompra = values => {
        this.setState({loading: true})
        axios.post('/compras/add',{
            ...values,
            edit_costo: this.state.edit_costo
        }).then(response => {
            message.success('Compra creada')
            this.props.onCancel()
        }).catch(error => {
            console.log("error", error);
            if(error?.response?.status === 403)
                message.error(error?.response?.data.message)
            else
                message.error('Error al registrar la compra')
        }).finally(()=>this.setState({loading: false}))
    }


    render() {

        const { loading } = this.state

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{this.props.compra_id ? 'Editar' : 'Crear'} Compra</Title>
                <Form 
                    layout="vertical" 
                    ref={this.formModalCompras} 
                    onFinish={this.onFinish}
                    initialValues={{
                        cantidad: 0,
                        actualizar_costo: false,
                        actualizar_inventario: true,
                    }}
                >

                    <Row gutter={[16,16]} justify="center">
                        <Col xs={22}>
                            <Form.Item
                                labelInValue
                                label="Producto" 
                                name="producto_id" 
                                rules={[{ required: true, message: "Por favor, seleccione un producto" }]} 
                            >
                                <Select
                                    labelInValue
                                    showSearch
                                    filterOption={false}
                                    onSearch={this.getProductos}
                                    onSelect={(producto_id)=>{
                                        this.onSelectProducto({producto_id})
                                    }}
                                    placeholder="Seleccionar un producto" 
                                >
                                    {this.state.productos.map(producto => <Option value={producto._id}>
                                        {producto.nombre} <small>{producto.sku_manantial}</small>
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={22}>
                            <Form.Item
                                labelInValue
                                label="Variante" 
                                name="variante_id" 
                                rules={[{ required: true, message: "Por favor, seleccione una variante" }]} 
                            >
                                <Select
                                    labelInValue
                                    showSearch
                                    filterOption={false}
                                    onSearch={this.getVariantes}
                                    onSelect={(variante_id)=>{
                                        this.onSelectVariante({variante_id})
                                    }}
                                    placeholder="Seleccionar una variante" 
                                >
                                    {this.state.variantes.map(variante => <Option value={variante._id}>
                                        {variante.nombre}
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col> 

                        <Col xs={22}>
                            <Form.Item
                                labelInValue
                                label="Proveedor" 
                                name="proveedor_variante_id" 
                                rules={[{ required: true, message: "Por favor, seleccione una proveedor" }]} 
                            >
                                <Select
                                    showSearch
                                    filterOption={false}
                                    onSelect={(proveedor_variante_id)=>{
                                        this.onSelectproveedor(proveedor_variante_id)
                                    }}
                                    placeholder="Seleccionar una proveedor" 
                                >
                                    {this.state.proveedor_variantes.map(proveedor => <Option value={proveedor._id}>
                                        {proveedor.proveedor_id.nombre} <small>costo: $ {proveedor.costo.toMoney()} mxn</small>
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={22}>
                            <Form.Item
                                label="Cantidad" 
                                name="cantidad" 
                                rules={[{ required: true, message: "Por favor, ingrese la cantidad" }]} 
                            >
                                <InputNumber 
                                    className="width-100" 
                                    min={0} 
                                    precision={0}
                                    onChange={(value)=>this.setState({
                                        cantidad: value,
                                        total: value * this.state.costo
                                    })}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={11}>
                            <Form.Item
                                label="Costo"
                                name="costo_unitario"
                            >
                                <InputNumber 
                                        className="width-100" 
                                        min={0} 
                                        precision={2}
                                        onChange={(value)=>{
                                            let cantidad = this.formModalCompras.current.getFieldValue('cantidad')
                                            this.setState({ total: cantidad * value, costo: value, edit_costo: true})
                                        }}
                                    /> 
                            </Form.Item>
                        </Col> 
                        <Col xs={11}>
                            <Form.Item
                                label="Total" 
                            >
                                <Text>$ {this.state.total.toMoney()} mxn</Text>
                            </Form.Item>
                        </Col> 

                        <Col xs={11}>
                            <Tooltip title="Al seleccionar, se actualizará el precio de este producto en la tienda y el cotizador" mouseEnterDelay={0.5}>
                                <Form.Item
                                    name="actualizar_costo"
                                    valuePropName="checked"
                                >
                                    <Checkbox>Actualizar costo global del producto</Checkbox> 
                                </Form.Item>
                            </Tooltip>
                        </Col> 
                        <Col xs={11}>
                            <Tooltip title="Al seleccionar, el inventariado de este producto se actualizará con las cantidades indicadas en la compra" mouseEnterDelay={0.5}>
                                <Form.Item
                                    name="actualizar_inventario"
                                    valuePropName="checked"
                                    className="flex-start-form-item"
                                >
                                    <Checkbox>Añadir al inventario</Checkbox> 
                                </Form.Item>
                            </Tooltip>
                        </Col> 

                    </Row>

                    <Row justify="center" align="middle" >
                        <Col span={24} className="flex-column mt-3">
                            <Form.Item>
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {

    let { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormCategorias {...props} />
        </Modal>
    )
}