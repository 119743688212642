import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, PageHeader, Tag, Button, Space, Typography, Card, Popconfirm, List, Avatar } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import { IconBus, IconEye, IconEdit, IconDelete } from "../../Widgets/Iconos";
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";
import '../../../Styles/Global/admin.scss';

//modales
import ModalPagos from './Modales/ModalPagos'


const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;
const { Title, Text } = Typography

const axios = require("axios").default;
const moment = require('moment');



const colors = ["#00000", "#87d068", "#FA9100"];
/**
 *
 *
 * @class PagosTab
 * @extends {Component}
 */
export default class PagosTab extends Component {
    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            pagos: [],
            page: 1,
            total: 0,
            limit: 10
        }
    }

    /**
    * @memberof PagosTab
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    *
    *
    **/
    componentDidMount() {
        /* this.props.setTitle('Ventas') */
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getPagos()
    }


    getPagos = (page = this.state.page) => {
        this.setState({loading: true})
        axios.get('/pagos',{
            params:{
                page: page,
                cliente_id: this.props.usuario_id
            }
        }).then(response => {
            console.log("response", response.data);
            this.setState({
                pagos: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
            })

        }).catch(error => {
            message.error('Error al obtener la información')
        }).finally(()=>this.setState({loading: false}))
    }



    



    

    
    render() {

        return (
            <div>
                <Content className="admin-content content-bg pd-1">
                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Pagos realizados" }}
                        dataSource={this.state.pagos}
                        pagination={{
                            current: this.state.page,
                            pageSize: 10,
                            total: this.state.total,
                            showSizeChanger: false,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            className:"flex-left",
                            onChange: this.getUsuarios
                            
                        }}

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">

                                    <Col span={6}  className="center">
                                        <Text strong>{moment(item.fecha).format('DD-MM-YYYY')}</Text>
                                    </Col>
                                    <Col span={6}  className="center">
                                        <Text strong></Text>
                                    </Col>
                                    <Col span={6}  className="center">
                                        <Text strong>$ {item.monto.toMoney()} mxn</Text>
                                    </Col>
                                        <Col span={6} className="center">
                                                {/*<Space>
                                                   
                                                    <Button type="primary" icon={<IconEdit/>} />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este usuario?"
                                                        
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button type="normal" danger  title="Eliminar" icon={<IconDelete/>}/>
                                                    </Popconfirm>
                                                </Space>*/}
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />
                <FloatingButton title={'Agregar Usuario'} onClick={()=>this.setState({modal_visible: true})}/>
                </Content>
                <ModalPagos
                    visible={this.state.modal_visible}
                    onCancel={()=>{
                        this.setState({modal_visible: false, orden_id: undefined})
                        
                    }}
                    cliente_id={this.props.usuario_id}
                />
            </div>
        )
    }
}

