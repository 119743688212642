import React, { Component, useEffect } from "react";

import { Layout, Row, Col, Typography, Card, Button, Carousel, } from 'antd';


import { CreditCardOutlined, HeartOutlined } from '@ant-design/icons'
import { IconBus, IconStar, IconTiming, IconConversation, IconHotDeals } from "../Widgets/Iconos";

import "../../Styles/Modules/Public/WeLoveOurClients.scss"

const { Title } = Typography

/**
 *
 *
 * @export
 * @class Landing
 * @extends {Component}
 * @description Pagina principal del sistema
 */
export default class Landing extends Component {
    render() {

        const contentStyle = {
            height: '400px',
            color: '#fff',
            lineHeight: '160px',
            textAlign: 'center',
            background: '#364d79',
        };

        return (

            <Row className="landing-section responsive" justify="center">
                <Col xs={23} sm={24}>
                    <Title level={2}> <HeartOutlined style={{ color: "#FBA228", fontSize: '22px' }}/> Amamos a Nuestros Clientes</Title>


                    <Row className="container-we-love-clientes">
                        <Card bordered={false} className="card-information">
                            <IconBus fill="#FF6814" size="1.4" className="custom-icon" style={{ top: '-13px', outline: '15px solid white' }} />
                            <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                <span style={{ fontWeight: 'bold' }}>Envio Gratis</span><br />
                                <span style={{ fontSize: 11 }}>CDMX & Edo de México</span>
                            </p>
                        </Card>
                        <Card bordered={false} className="card-information">
                            <IconStar fill="#FF6814" size="0.6" className="custom-icon" style={{ top: '-20px', outline: '5px solid white' }} />
                            <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                <span style={{ fontWeight: 'bold'}}>Más Alta Calidad</span><br />
                                <span style={{ fontSize: 11 }}>en Nuestros Productos</span>
                            </p>
                        </Card>
                        <Card bordered={false} className="card-information">
                            <IconTiming fill="#FF6814" size="2" className="custom-icon" style={{ top: '-20px', outline: '5px solid white' }} />
                            <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                <span style={{ fontWeight: 'bold' }}>Disponibilidad</span><br />
                                <span style={{ fontSize: 11 }}>Entrega Inmediata</span>
                            </p>
                        </Card>
                        <Card bordered={false} className="card-information">
                            <IconConversation fill="#FF6814" size="0.5" className="custom-icon" style={{ top: '-20px', outline: '5px solid white' }} />
                            <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                <span style={{ fontWeight: 'bold' }}>Soporte 24/7</span><br />
                                <span style={{ fontSize: 11 }}>Atención al Cliente</span>
                            </p>
                        </Card>
                        <Card bordered={false} className="card-information">
                            <CreditCardOutlined className="custom-icon" style={{ top: '-15px', outline: '5px solid white', color: "#FF6814", fontSize: 30 }} />
                            <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                <span style={{ fontWeight: 'bold' }}>Pagos Seguros</span><br />
                                <span style={{ fontSize: 11 }}>SPEI & Tarjetas</span>
                            </p>
                        </Card>
                    </Row>

                    {/*<div className="container-we-love-clientes">
                        <Row justify="center" gutter={6}>
                            <Col xs={12} md={4} className="center mb-1">
                                <Card bordered={false} className="card-information">
                                    <IconBus fill="#FF6814" size="1.4" className="custom-icon" style={{ top: '-13px', outline: '15px solid white' }} />
                                    <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                        <span style={{ fontWeight: 'bold' }}>Envio Gratis</span><br />
                                        <span style={{ fontSize: 11 }}>CDMX & Edo de México</span>
                                    </p>
                                </Card>
                            </Col>
                            <Col xs={12} md={4} className="center mb-1">
                                <Card bordered={false} className="card-information">
                                    <IconStar fill="#FF6814" size="0.6" className="custom-icon" style={{ top: '-20px', outline: '5px solid white' }} />
                                    <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                        <span style={{ fontWeight: 'bold'}}>Más Alta Calidad</span><br />
                                        <span style={{ fontSize: 11 }}>en Nuestros Productos</span>
                                    </p>
                                </Card>
                            </Col>
                            <Col xs={24} md={4} className="center mb-1">
                                <Card bordered={false} className="card-information">
                                    <IconTiming fill="#FF6814" size="2" className="custom-icon" style={{ top: '-20px', outline: '5px solid white' }} />
                                    <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                        <span style={{ fontWeight: 'bold' }}>Disponibilidad</span><br />
                                        <span style={{ fontSize: 11 }}>Entrega Inmediata</span>
                                    </p>
                                </Card>
                            </Col>
                            <Col xs={12} md={4} className="center mb-1">
                                <Card bordered={false} className="card-information">
                                    <IconConversation fill="#FF6814" size="0.5" className="custom-icon" style={{ top: '-20px', outline: '5px solid white' }} />
                                    <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                        <span style={{ fontWeight: 'bold' }}>Soporte 24/7</span><br />
                                        <span style={{ fontSize: 11 }}>Atención al Cliente</span>
                                    </p>
                                </Card>
                            </Col>
                            <Col xs={12} md={4} className="center mb-1">
                                <Card bordered={false} className="card-information">
                                <CreditCardOutlined className="custom-icon" style={{ top: '-15px', outline: '5px solid white', color: "#FF6814", fontSize: 30 }} />
                                <p style={{ textAlign: "center", marginTop: '0.4em' }}>
                                    <span style={{ fontWeight: 'bold' }}>Pagos Seguros</span><br />
                                    <span style={{ fontSize: 11 }}>SPEI & Tarjetas</span>
                                </p>
                            </Card>
                            </Col>
                        </Row>
                    </div>*/}

                </Col>
            </Row>
        )
    }
}