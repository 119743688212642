import React from 'react'
import { Route, Switch } from "react-router-dom";
import Inventario from '../../components/Admin/Inventario/Inventario';
import Movimientos from '../../components/Admin/Inventario/Movimientos';

// componentes

/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function InventarioRouter( props) {
    return (
        <Switch>
            <Route exact path="/admin/inventario" render={() => <Inventario {...props} />} />
            <Route exact path="/admin/inventario/:variante_id" render={(props_p) => <Movimientos {...props} {...props_p}/>} />
        </Switch>
    )
}

export default InventarioRouter;
