import React, { Component } from 'react'
import { Row, Col, Card, Typography, Tag, List, Image, Avatar } from 'antd';
import { Productos_Categorias, Productos_Activos } from './../Widgets/graficas'
import { UserOutlined, StockOutlined } from "@ant-design/icons";
import '../../Styles/Modules/Analytics/analytics.css'
import axios from 'axios';

const {Text} = Typography

class Analytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            montado: false,
            productos: []
        }

    }


    render() {

        const { data } = this.props

        return (
            <Card className="card-container  width-100 heigth-100" >
                <Row>
                    <Col xs={24} sm={24}>
                        <Card bordered={false}>
                            <Col>
                                <Row className='center'>
                                    <Text className='titulo'>Utilidad Total</Text>
                                </Row>
                                <Row className='center'>
                                    <Text className='titulo-dato'>$ {(data?.ventas_totales?.total - data?.compras_totales?.total).toMoney()} MXN</Text>
                                </Row>
                                <Row className='mt-1 center'>
                                    <Col>
                                        <Tag className='center icono'><StockOutlined/></Tag>
                                    </Col>
                                    <Col>
                                        <Text>{this.props?.margen_utilidad?.toMoney()}%</Text>
                                    </Col> 
                                </Row>
                                <Row className='pt-2 center'>
                                    <Text className='subtitulo'>Venta Total</Text>
                                </Row>
                                <Row className='center'>
                                    <Text className='subtitulo-dato'>$ {data?.ventas_totales?.total?.toMoney()} MXN</Text>
                                </Row>
                                <Row className='pt-1 center'>
                                    <Text className='subtitulo'>Compra Total</Text>
                                </Row>
                                <Row className='center'>
                                    <Text className='subtitulo-dato'>$ {data?.compras_totales?.total?.toMoney()} MXN</Text>
                                </Row>

                                <Row className='pt-2 pb-1 flex-left'>
                                    <Text>Productos más vendidos</Text>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                    <List
                                        loading={this.state.loading}
                                        className="component-list"
                                        itemLayout="horizontal"
                                        locale={{ emptyText: "Sin Usuarios" }}
                                        dataSource={this.props.data.productos}
                                        renderItem={item => (
                                            <List.Item className="component-list-item">
                                                <Card className="card-list">
                                                    <Row className="width-100 ">

                                                        <Col span={3}  className="center">
                                                            <Avatar src={`${axios.defaults.baseURL}/upload/${item.producto_id.imagenes[0]}`}/>
                                                        </Col>
                                                        <Col span={7}  className="center">
                                                            <Text ellipsis>{item.producto_id.nombre}</Text>
                                                        </Col>
                                                        <Col span={7}  className="center">
                                                            <Text>{item.vendido} vendidos</Text>                                                        
                                                        </Col>
                                                        <Col span={7}  className="center">
                                                            <Text strong>$ {item.total.toMoney()}</Text>
                                                        </Col>
                                                    
                                                    </Row>
                                                </Card>
                                            </List.Item>
                                        )}
                                    />
                                    </Col>
                                </Row>
                            </Col>
                        </Card>
                    </Col>

                </Row>


            </Card>
        );
    }

}

export default Analytics;