import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, PageHeader, Tag, Button, Space, Typography, Card, Popconfirm, List, Image, Avatar } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { IconBus, IconEye, IconEdit, IconDelete } from "../../Widgets/Iconos";

//modales



const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;
const { Title, Text } = Typography

const axios = require("axios").default;
const moment = require('moment');



const colors = ["#00000", "#87d068", "#FA9100"];
/**
 *
 *
 * @class Compras
 * @extends {Component}
 */
export default class Compras extends Component {
    constructor(props) {
        super(props);
        this.state = {

            data: [],
            sending: false,
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,
            search: '',
            modaltrack_visible: false,
            modaldetalle_visible: false,
            venta_id: undefined,
            venta_email: undefined,
        }
    }

    /**
    * @memberof Compras
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    *
    *
    **/
    componentDidMount() {
        /* this.props.setTitle('Ventas') */
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getPrecios();
    }



    /**
    * @memberof Compras
    *
    * @method   getPrecios
    * @description  Metodo que realiza el paginado de las Ventas
    * @param page  Number con la pagina a buscar, 
    * @param search String para buscar datos de la tabla, por defecto cadena vacia para busquedas
    *
    **/
    getPrecios = (page = this.state.currentPage, search = this.state.search) => {
        axios.get('/precios',
            {
                headers: { Authorization: sessionStorage.getItem("token") },
                params: {
                    page: page,
                    limit: 10,
                    paginate: true
                }
            }).then(res => {

                let data = res.data.data.itemsList;
                console.log('data', res);
                this.setState({
                    data: data,
                    currentPage: res.data.data.paginator.currentPage,
                    itemCount: res.data.data.paginator.itemCount,
                    perPage: res.data.data.paginator.perPage,
                    pageCount: res.data.data.paginator.pageCount,
                });

            })
    }
    /**
  * @memberof Compras
  *
  * @method   searchData
  * @description  Metodo que realiza la busqueda de productos
  * @param search String   con la info a buscar, por defecto cadena vacia para busquedas
  *
  **/
    searchData = (search) => {
        this.setState({ search: search });
        this.getPrecios(1, search);

    }

    render() {

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title={
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                <span className="ant-page-header-heading-title">
                                    Precios
                                </span>
                            </Col>
                        </Row>
                    }
                />
                <Content className="admin-content content-bg pd-1">
                <Row className="mb-1">
						<Col xs={24} md={12}>
							<Search
								className="search-bar"
								placeholder="Buscar..."
								enterButton="Buscar"
								onSearch={this.searchData}
							/>
						</Col>
				</Row>
                <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Usuarios" }}
                        dataSource={this.state.data}
                        pagination={{
                            current: this.state.currentPage,
							pageSize: this.state.perPage,
							total: this.state.itemCount,
							position: 'bottom',
							className: "flex-left",
                            onChange: (page) => this.getPrecios(page)
                            
                        }}
                        

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">

                                    <Col span={2}  className="center">
                                        
                                        { (Array.isArray(item.producto_id?.imagenes) && item?.producto_id?.imagenes.length  > 0) ? 
                                            <Avatar style={{ marginRight: 10 }} shape="square" src={axios.defaults.baseURL + "/upload/" + item?.producto_id?.imagenes[0] } size={60} /> : 
                                            <Avatar style={{ marginRight: 10 }} shape="square" src={'/images/img-video.png' } size={60} />
                                        }
                                    </Col>

                                    <Col span={7}  className="center">
                                       <Text strong>{item.producto_id?.nombre}</Text>
                                    </Col>
                                    <Col span={8}  className="center">
                                        <Text className="text-gray">Ultima Actualización: {moment(item.actualizado).format('LLL')}</Text>
                                    </Col>
                                    <Col span={5}  className="center">
                                        <Text strong>{`$ ${item.precio.toMoney(true)} MXN`}</Text>
                                    </Col>
                                    <Col span={2} className="center">
                                                <Space>
                                                    <Link to={`/admin/precios/detalle/${item.producto_id?._id}`}>
                                                        <Button type="primary" icon={<IconEye/>}/>
                                                    </Link>
                                                </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />
                </Content>

            </>
        )
    }
}

