import React, { useEffect, useState, Component, useContext } from 'react';
import { Link } from "react-router-dom"
import {
    Layout, Row, Col, Typography, Card, Button, Spin, Menu, Dropdown, Form, Slider, Select, message, Image, Modal, Carousel
} from 'antd';
import '../../Styles/Modules/Public/store.scss';
import { Carrito, SetCarrito } from '../../Hooks/Carrito';


import ProductControl from '../Widgets/ProductControl/ProductControl';

const { Title, Paragraph, Text } = Typography
const { Option } = Select

const axios = require('axios')


function InputControlProduct(props) {

    const { limit, onChange } = props
    let [value, setValue] = useState(props.value)


    // value, quantity
    const onQuantityChange = (index, quantity) => {
        // let tempListProducts = listProducts
        const valueA = parseFloat(quantity)

        if (isNaN(valueA))
            return message.error("No es un numero valido.")

        if (valueA > limit)
            return message.error("No hay stock.")

        if (valueA <= 0)
            return message.error("Debe de haber al menos un artículo en el carrito.")

        onChange(valueA)
        setValue(valueA)
    }

    return <ProductControl
        className="product"
        onQuantityChange={onQuantityChange}
        index={null}
        quantity={value}
        limit={limit}
        deleteOption={false}

    />
}



class CarouselImagenes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        })
    }

    sections = [
        '/img/logos-tech/mongodb.svg',
        '/img/logos-tech/react.svg',
        '/img/logos-tech/express.svg',
        '/img/logos-tech/nodejs.svg',
        '/img/logos-tech/scala.svg',
        '/img/logos-tech/scala.svg',
    ]

    render() {

        const { imagenes } = this.props

        console.log('IM', imagenes);

        return (
            <>
                {Array.isArray(imagenes) && imagenes.length > 4 ? <Carousel
                    asNavFor={this.state.nav1}
                    ref={slider => (this.slider2 = slider)}
                    slidesToShow={4}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    arrows={false}
                    dots={false}

                    responsive={[
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            },

                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        },
                        {
                            breakpoint: 576,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        }
                    ]}
                // responsive={{}}
                >
                    {imagenes.map((data) => {
                        return <div className='image-product-container'>
                            <img className='image-product' src={axios.defaults.baseURL + '/upload/' + data} />
                        </div>
                    })}
                </Carousel> : <div className='image-product-list'>
                    {imagenes.length != 1 && imagenes.map((data, index) => {
                        return <div className='image-product-container' onClick={e => {
                            console.log('this.state.nav2', this.slider1)
                            this.slider1.goTo(index)
                        }}>
                            <img className='image-product' src={axios.defaults.baseURL + '/upload/' + data} />
                        </div>
                    })}
                </div>

                }

                <div style={{ marginTop: '1em' }} />
                <Image.PreviewGroup
                    preview={{
                        onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                    }}
                >
                    <Carousel
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        arrows={false}
                        adaptiveHeight={true}
                        slidesToShow={1}
                        swipeToSlide={true}
                        dots={false}
                    >
                        {imagenes.map((data) => {
                            return <Image src={axios.defaults.baseURL + '/upload/' + data} />
                        })}
                        {/* {this.sections.map((data) => {

                        return <Image src={'https://throwcode.dev/' + data} />
                    })} */}
                    </Carousel>
                </Image.PreviewGroup>


            </>
        );
    }
}

/**
 *
 *
 * @export
 * @class ProductoDetalle
 * @extends {Component}
 * @description Pagina tipo tienda del sistema
 */
class ProductoDetalle extends Component {

    formProducto = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            producto_id: this.props.match.params.producto_id,
            producto: {},
            imagen: '',
            imagenes: [],
            variantes: [],
            variante: {},
            variante_info: {},
            modalImage: false,
            spinning: false,
        }
    };

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProducto()
        this.getVariantes()
        window.scrollTo(0, 0)
    }


    /**
   * @memberof ProductoDetalle
   *
   * @method   getProducto
   * @description  Metodo que realiza la busqueda de productos
   **/
    getProducto = () => {
        this.setState({ loading: true })
        axios.get(`/productos/get`, {
            params: {
                id: this.props.match.params.producto_id,
            }
        }).then(async res => {
            console.log("res", res.data.data);
            this.setState({
                producto: res.data.data.producto,
                imagen: res.data.data.producto.imagenes[0],
                imagenes: res.data.data.producto.imagenes,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener el producto.')
        })
            .finally(() => this.setState({ loading: false }))

    }

    /**
   * @memberof ProductoDetalle
   *
   * @method   getVariantes
   * @description  Metodo que realiza la busqueda de variantes del producto
   **/
    getVariantes = () => {
        this.setState({ loading: true })
        axios.get('/variantes/producto', {
            params: {
                producto_id: this.props.match.params.producto_id,
                tienda: true,
                page: 1,
                limit: 50
            }
        })
            .then(res => {
                console.log("res", res.data);
                const data = res.data.data

                this.setState({
                    variantes: data,
                    variante: data[0] ? data[0] : {}
                })

                this.getVariante(data[0]._id)
                this.formProducto.current.setFieldsValue({
                    variante_id: data[0] ? data[0]._id : null
                })
            }).catch(res => {
                console.log("error", res);
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof FormVariantes
    *
    * @method getVariantes
    * @description  trae la informacion de una categoria
    *
    **/
    getVariante = (id) => {
        this.setState({ loading: true })
        axios.get(`/variantes/get`, {
            params: {
                id: id
            }
        }).then(async res => {
            let variante = res.data.data.variante;
            this.setState({ variante_info: variante })
        }).catch(res => {
            message.error('Error al obtener la información ')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
   * @memberof ProductoDetalle
   *
   * @method   getProducto
   * @description  Metodo que se ejecuta al agregar al carrito
   **/
    onFinish = (values) => {
        this.setState({ loading: true })
        console.log("this.state.variante_info", this.state.variante_info);
        console.log("this.state.producto", this.state.producto);

        let element = this.state.variante_info
        element.variante_info = {...this.state.variante_info}
        element.producto = this.state.producto
        element.categoria = this.state.producto?.categoria_id
        element.producto.categoria_id = this.state.producto?.categoria_id?._id
        element.size = this.state.variante.size_id
        element.size_id = this.state.variante?.size_id?._id
        element.producto_id = this.state.producto?._id
        element.quantity = values.cantidad
        element.limit = this.state.variante_info?.inventarios?.existencias
        let carrito_actual = this.props.carrito;

        if (carrito_actual.length > 0) {
            let index = carrito_actual.findIndex(carr => carr._id == element._id)
            if (index === -1) {
                carrito_actual.push(element)
                message.success('Agregado al carrito')
            } else {
                carrito_actual[index] = element
                message.success('¡Cantidad actualizada!')
            }
        } else {
            carrito_actual.push(element)
            message.success('Agregado al carrito')
        }


        this.props.setCarrito(carrito_actual, () => this.setState({ loading: false }))




    }


    render() {

        let { imagen, producto, imagenes, variante } = this.state

        return (
            <>
                <Layout className="store p-1">
                    <Spin spinning={this.state.loading}>
                        <Row className="store-section responsive" justify="center">
                            <Col xs={23} xl={24}>
                                <Row gutter={16}>
                                    <Col xs={24} md={12} lg={8} className="mb-1">
                                        <CarouselImagenes imagenes={variante.imagenes || imagenes} />
                                    </Col>
                                    <Col xs={24} md={10} lg={10} className="mb-1">
                                        <Title className="producto-title" level={4}>{producto.nombre}</Title>
                                        <Title level={4} className="m-0">{variante.nombre}</Title>
                                        <Text>Fabricante: {producto.fabricante}</Text>
                                        <div className="producto-price mt-1">{variante.precio_venta ? '$ '+ variante.precio_venta?.toMoney()+'MXN' : ''}  </div>
                                        <small className='iva'>IVA incluido</small>
                                        <div className="producto-descripcion-title">Descripcion:</div>
                                        <Paragraph className="producto-descripcion">{variante.descripcion ? variante.descripcion : <Text className="text-gray">Sin Descripción</Text>}</Paragraph>
                                    </Col>
                                    <Col xs={18} lg={6} align="center" className="mb-1">

                                        <Form
                                            layout="vertical"
                                            ref={this.formProducto}
                                            onFinish={this.onFinish}
                                            initialValues={{
                                                cantidad: 1
                                            }}
                                        >

                                            <Row>
                                                <Col xs={24}>
                                                    <Form.Item
                                                        name="variante_id"
                                                        label="Tipo"
                                                        className="mb-1"
                                                    >
                                                        <Select
                                                            placeholder="Seleccionar opción"
                                                            onChange={(value, { option }) => {
                                                                this.getVariante(value)
                                                                this.setState({ variante: option })
                                                            }}
                                                            // allowClear
                                                            style={{ width: "100%" }}
                                                        >
                                                            {
                                                                this.state.variantes.map(variante => {
                                                                    return <Option value={variante._id} option={variante}>{variante?.nombre}</Option>
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} className='input-compra'>
                                                    <Form.Item
                                                        name="cantidad"
                                                        label="Cantidad"
                                                    >
                                                        <InputControlProduct
                                                            quantity={1}
                                                            limit={this.state.variante_info?.inventarios?.existencias}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row justify="center" align="middle" >
                                                <Col span={24} className="flex-column">
                                                    <Form.Item className="w-100">
                                                        <Button htmlType="submit" type="primary" className="w-100">
                                                            Agregar al carrito
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Spin>
                </Layout>
                <Modal
                    className="modal-image"
                    visible={this.state.modalImage}
                    onCancel={() => this.setState({ modalImage: false })}
                    title={null}
                    footer={null}
                    maskClosable={true}
                    destroyOnClose={true}
                    zIndex={1000}
                >
                    <img src={`${axios.defaults.baseURL}/upload/${imagen}`} className="w-100" />
                </Modal>
            </>
        )
    }
}

export default function Producto(props) {


    let setCarrito = React.useContext(SetCarrito)
    let carrito = React.useContext(Carrito)

    return (
        <ProductoDetalle setCarrito={setCarrito} carrito={carrito}  {...props} />
    );

}