import React, { Component, useEffect } from "react";
import { Row, Col, Typography, Card, Button, } from 'antd';
import Carousel from 'react-multi-carousel';

import { IconHotDeals } from "../Widgets/Iconos";
import {CardProducto} from "../Widgets/Cards"

import 'react-awesome-slider/dist/styles.css';


import '../../Styles/Modules/Public/landing.css';
import 'react-multi-carousel/lib/styles.css';
import axios from "axios";


const { Title, Paragraph, Text, } = Typography

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

/**
 *
 *
 * @export
 * @class Landing
 * @extends {Component}
 * @description Pagina principal del sistema
 */
export default class Landing extends Component {
    constructor(props){
        super(props);
        this.state ={
            productos:[]

        }
    }


    componentDidMount(){
        console.log('MOUNTED!')
        axios.get('/productos/hot')
        .then(success=>{
            console.log('productos',success.data.data)
            this.setState({
                productos:success.data.data.itemsList
            })
        })
        .catch(error=>{

        })
    }

    render() {
        return (
            <Row className="landing-section responsive" justify="center">
                <Col xs={23} sm={24}>
                    <Title level={2}> <IconHotDeals /> Ofertas</Title>
                    <Carousel 
                        responsive={responsive}>
                        {this.state.productos.map(e => (
                            <div className="pd-1">
                                <CardProducto producto={e}/>
                            </div>
                        ))}

                    </Carousel>
                </Col>
            </Row>
        )
    }

}