import React, { Component, useState } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, Select, PageHeader, Tag, Button, Switch, Space, Typography, Card, Popconfirm, List, Avatar, Tabs, Image, Form, Spin, Drawer } from 'antd';
import { Redirect } from 'react-router-dom';
import { FilePdfFilled } from '@ant-design/icons';

//componentes
import { IconBus, IconEye, IconEdit, IconDelete } from "../../../Widgets/Iconos";
import { CarritoOrden } from "../OrdenDeCompra"
import {ProductosCotizacion, CarritoCotizacion} from "../Cotizacion"
import OrdenFechas from "./OrdenFechas"
import OrdenCotizacion from "./OrdenCotizacion"
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
//css
import '../../../../Styles/Global/admin.scss';
//modales
import ModalVariante from '../ModalVariante'


const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;
const { Title, Text } = Typography
const { TabPane } = Tabs;
const {Option} = Select

const axios = require("axios").default;
const moment = require('moment');



const colors = ["#00000", "#87d068", "#FA9100"];
/**
 *
 *
 * @class Cotizaciones
 * @extends {Component}
 */
class Cotizaciones extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seleccion: 1,
            sending: false,
            orden: {
                subtotal_costo: 0,
                iva_costo: 0,
                total_costo: 0
            },
            modal_visible: false,
            clientes: [],
            usuario_id: undefined,
            redirect: false,
            productos: [],
            tipo: 1,
            loading: false,
            tipo_vista: 'compras',
            direcciones: []
        }
    }

    usuarioRef = React.createRef()
    cotizacionRef = React.createRef()
    
    componentDidMount() {
        /* this.props.setTitle('Ventas') */
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getUsuarios()
        this.getOrden()
        
    }

    componentDidUpdate(prevProps){
        if(this.props.orden_id && prevProps.orden_id != this.props.orden_id){
            this.setState({tipo_vista: 'compras'})
            this.getOrden()
        }
    }

    /**
    * @memberof Cotizaciones
    *
    * @method getOrden
    * @description Obtiene la orden y sus productos
    **/
    getOrden = () => {
        axios.get('/ordenes/detalle',{
            params:{
                orden_id: this.props.orden_id
            }
        }).then(response => {

            let cliente = response.data.orden.usuario_id
            this.setState({
                productos: []
            },()=>{
                this.setState({
                    productos: response.data.orden_detalles,
                    orden: { ...response.data.orden, usuario_id: response.data.orden.usuario_id._id},
                    direcciones: cliente.direcciones
                })
            })


            this.usuarioRef.current.setFieldsValue({
                usuario_id:{
                    value: response.data.orden.usuario_id?._id,
                    key: response.data.orden.usuario_id?._id,
                    label: <>
                        <CustomAvatar 
                            name={cliente.nombre + ' ' + cliente.apellido} 
                            image={cliente.avatar}  
                            color={cliente?.tipo_usuario_id?.color ? cliente?.tipo_usuario_id?.color : '#9664FF'}
                            size="small"
                        />
                        <Text strong>{cliente.nombre + ' ' + cliente?.apellido} </Text>
                    </>
                },
                direccion_id: response.data?.orden?.direccion?._id
            })

        }).catch(error => {
            console.log("error", error);

        })
    }

    /**
    * @memberof Cotizaciones
    *
    * @method updateProducto
    * @description Actualiza la información de una orden detalle, y vuelve a obtener todos los detalles con los calculos
    **/
    updateProducto = (values, index) => {

        let productos = this.state.productos

        productos[index].fecha_entrega_min = values.fecha_entrega_min
        productos[index].fecha_entrega_max = values.fecha_entrega_max

        this.setState({ productos })

    }

    
    /**
    * @memberof Cotizaciones
    *
    * @method   onChangeOrden
    * @description actualiza la orden para quitar o añadir el iva, y asignar un cliente, en caso de ser confirmada la orden se redirige a la vista de 
    * lista de ordenes
    **/
    onChangeOrden = (values) => {

        let { iva_agregado, metodo_pago, metodo_entrega, usuario_id, direccion_id } = values

        let orden = this.state.orden

        //actualiza el cliente
        if(usuario_id != undefined) {
            orden.usuario_id = usuario_id.value
            let index = this.state.clientes.findIndex(c => c._id.toString() === usuario_id.value.toString())
            if(index != -1 && this.state.clientes[index].direcciones.length > 0){
                this.setState({direcciones: this.state.clientes[index].direcciones})
            }else{
                this.setState({direcciones: []})
            }
            this.usuarioRef.current.setFieldsValue({
                direccion_id: null
            })
            orden.direccion = 'null'
        }
        //actualizamos la direccion
        if(direccion_id != undefined){
            let index = this.state.direcciones.findIndex(d => d._id.toString() === direccion_id.toString())
            if(index != -1){
                orden.direccion = { ...this.state.direcciones[index] }
            }
        }

        this.setState({orden: orden},()=>{
            this.saveOrden()
        }) 
    }

    /**
    * @memberof Cotizaciones
    *
    * @method   getUsuarios
    * @description actualiza la orden para quitar o añadir el iva
    **/
    getUsuarios = (search) => {        

        axios.get('/usuarios',{
            params:{
                tipo: 2,
                search
            }
        }).then(response => {
            this.setState({clientes: response.data.data.itemsList})

        }).catch(error => {
            console.log("error", error);

        })
    }


    /**
    * @memberof Cotizaciones
    *
    * @method   saveOrden
    * @description actualiza la orden para quitar o añadir el iva
    **/
    saveOrden = () => {
        this.setState({loading: true})
        axios.post('/ordenes/update/all',{
            orden: {...this.state.orden,},
            orden_detalles: []
        }).then(response => {
            message.success('Orden Actualizada')
            //this.props.onCancel()
        }).catch(error =>{
            console.log("error", error);
            if(error?.response?.status === 403){
                message.error(error?.response?.data?.message)
            }else if(error?.response?.status === 402){
                message.warning(error?.response?.data?.message)
            }else{
                message.error('Error al actualizar')
            }
        }).finally(()=>this.setState({loading: false}))
    }

    /**
    * @memberof Cotizaciones
    *
    * @method addProducto
    * @description Metodo que permite PSEUDO-AÑADIR una orden detalle a la orden, crea el registro de la orden_detalle en la bd, para realizar los calculos y busquedas de costos y precio
    * para depues eliminarlo inmediatamente de la bd y nos regresa una copia para añadirlo
    **/
    addProducto = (values) => {
        this.cotizacionRef.current.addProducto(values)
    }

    render() {

        const { orden, redirect, tipo, tipo_vista } = this.state

        return (
            <Spin spinning={this.state.loading}>
                <Card className="carrito-card" bordered={false}>
                    <Row align="end">
                        <Col span={24}>
                            <Text className="folio">FOLIO {orden.folio}</Text>
                        </Col>
                        <Form
                            ref={this.usuarioRef}
                            className="width-100"
                        >
                            <Col className="flex-right pt-1" span={24}>
                                    <Form.Item
                                        name="usuario_id"
                                        style={{width: '300px'}} 
                                    >
                                        <Select
                                            labelInValue
                                            className="clientes" 
                                            showSearch
                                            allowClear
                                            filterOption={false}
                                            onSearch={this.getUsuarios}
                                            onSelect={(usuario_id)=>{
                                                this.onChangeOrden({usuario_id})
                                            }}
                                            placeholder="Seleccionar un usuario" 
                                        >
                                            {this.state.clientes.map(cliente => <Option value={cliente._id}>
                                                <CustomAvatar 
                                                    name={cliente.nombre + ' ' + cliente.apellido} 
                                                    image={cliente.avatar}  
                                                    color={cliente?.tipo_usuario_id?.color ? cliente?.tipo_usuario_id?.color : '#9664FF'}
                                                    size="small"
                                                />
                                                <Text strong>{cliente.nombre + ' ' + cliente?.apellido} </Text>
                                            </Option>)}
                                        </Select>
                                    </Form.Item>
                                
                            </Col>
                            <Col className="flex-right pt-1" span={24} style={{minHeight: '50px'}}>

                                    <Form.Item
                                        name="direccion_id"
                                        style={{width: '300px'}} 
                                    >
                                        {this.state.orden.usuario_id ? <Select 
                                            className="clientes" 
                                            onSelect={(direccion_id)=>{
                                                this.onChangeOrden({direccion_id})
                                            }}
                                            placeholder="Seleccionar una dirección" 
                                        >
                                            {this.state.direcciones.map(direccion => <Option value={direccion._id}>
                                                {direccion.nombre} <br/><small>{direccion.calle}</small>
                                            </Option>)}
                                        </Select> : null }
                                    </Form.Item>
                            </Col>
                        </Form>
                    </Row>
                    <Row className="">

                        <Col span={24} className="mt-2 mb-2">
                            <Space>
                                <Button 
                                    className={`btn-materiales ${tipo_vista === 'ventas' ? 'active' : ''}`} 
                                    onClick={() => { 
                                        this.setState({tipo_vista: 'ventas'}) 
                                        this.props.changeWidth()
                                    }}
                                >   
                                    Cotizaciones
                                </Button>
                                
                                <Button 
                                    className={`btn-materiales ${tipo_vista === 'compras'? 'active' : ''}`} 
                                    onClick={() => { 
                                        this.setState({tipo_vista: 'compras'}) 
                                        this.props.changeWidth(false)
                                    }}
                                >
                                    Orden de Compra
                                </Button>
                            </Space>
                        </Col>
                    </Row>

                    {this.state.tipo_vista === 'compras' ? <OrdenFechas
                        orden_id={this.props.orden_id}
                        onCancel={()=>{this.props.onCancel()}}
                    /> : <OrdenCotizacion
                        orden_id={this.props.orden_id}
                        onCancel={()=>{this.props.onCancel()}}
                        ref={this.cotizacionRef}
                    />}
                    
                </Card>
            </Spin>
                       
        )
    }
}



export default function (props) {

    const { visible = false, onCancel = () => { } } = props

    const [width, setWidth] = useState(600);
    const [modalVisible, setModalVisible] = useState(false);
    const [variante, setVariante] = useState(undefined);
    const [varianteID, setVarianteID] = useState(undefined);


    let cotizacionRef = React.createRef()


    const changeWidth = (open = true) => {
        if(open)
            setWidth(1185)
        else
            setWidth(600)
    }

    const closeDrawer = () => {
        onCancel()
        setWidth(600)
    }    

    return <>
        <Drawer
            className="drawer-ordenes"
            visible={visible}
            onClose={closeDrawer}
            width={width}
           // destroyOnClose={true}
        >   
            <div className="drawer-container">
                <div className={`drawer-productos-wrapper ${width === 1185 ? 'active' : ''} `}>
                    <div className={`drawer-productos-content`}>
                        {/*Lista de productos para añadir a la orden al editar*/}
                        <ProductosCotizacion
                            responsive={{
                                xs: 24,
                                sm: 12
                            }}
                            size="sm"
                            openModal={(variante)=>{
                                setVariante(variante)
                                setVarianteID(variante._id)
                                setModalVisible(true)
                            }}
                        />
                    </div>
                </div>
                <div className="drawer-detalles">
                    {/*detalles de la cotizacion*/}
                    <Cotizaciones 
                        {...props} 
                        changeWidth={changeWidth} 
                        onCancel={closeDrawer}
                        ref={cotizacionRef}
                    />
                </div>
            </div>
        </Drawer>
        <ModalVariante
            visible={modalVisible}
            onCancel={()=>{
                setModalVisible(false)
                setVariante(undefined)
                setVarianteID(undefined)
            }}
            variante_id={varianteID}
            variante={variante}
            addProducto={(values)=>{
                console.clear()
                cotizacionRef.current.addProducto(values)
            }}
        />
    </>

}
