import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Switch, PageHeader, Button, message, Collapse, Avatar, Popconfirm, Modal, Space, List, Typography, Card } from 'antd';
import { Link } from 'react-router-dom';
import {
	EditOutlined,
	PlusOutlined, MinusOutlined, EyeOutlined, DeleteOutlined, ExclamationCircleOutlined, CloseOutlined, CopyOutlined
} from '@ant-design/icons';
//componentes
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton"
import { IconDelete, } from '../../Widgets/Iconos';
//modal
import ModalProductos from './ModalProductos'
import ModalVariantes from "../Variantes/ModalVariantes";
import ModalVariantesProveedores from "../Variantes/ModalVariantesProveedores";
import ModalProductosMultiples from "./ModalProductosMultiples";


import {
	FloatingMenu,
	MainButton,
	ChildButton,
	Directions
} from 'react-floating-button-menu';


const { Header, Content } = Layout;
const { Search } = Input;
const { Panel } = Collapse;
const { Text } = Typography;
const axios = require('axios').default;

class Productos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nombreVista: 'Productos',
			user: 'Administrador',
			productos: [],
			itemCount: undefined,
			perPage: 10,
			pageCount: 1,
			currentPage: 1,
			slNo: 1,
			hasPrevPage: false,
			hasNextPage: false,
			prev: null,
			next: null,

			isOpen: false,
			modalProductosMultiples: false,
		}
	}

	/**
	* @memberof Productos
	*
	* @method componentDidMount
	* @description  Al montar el componente trae los datos de los usuarios y los asigna al state.users
	*
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		/*LLAMADA AL PAGINADO*/
		this.receivedData();


	}


	/**
	* @memberof Productos
	*
	* @method   receivedData
	* @description  Metodo que realiza el paginado de los productos
	*  @param values Number con la pagina a buscar
	*
	**/
	receivedData(page = this.state.currentPage, limit = this.state.perPage) {
		this.setState({ loading: true })
		axios.get('/productos', {
			params: {
				page: page,
				limit: limit,
			}
		}).then(res => {
			const data = res.data.data.itemsList
			console.log(res)
			this.setState({
				productos: data,
				currentPage: res.data.data.paginator.currentPage,
				itemCount: res.data.data.paginator.itemCount,
				perPage: res.data.data.paginator.perPage,
			});
		}).catch(res => {
			console.log("error", res);
		}).finally(() => this.setState({ loading: false }))
	}

	/**
	* @memberof Productos
	*	
	* @method   searchData
	* @description  Metodo que realiza la busqueda de productos
	* @param values String   con la info a buscar, por defecto cadena vacia para busquedas
	*
	**/
	searchData = (values) => {
		axios.get('/productos', {
			params: {
				search: values
			}
		})
			.then(res => {
				const data = res.data.data.itemsList
				console.log("Listado de productos", data)
				this.setState({
					productos: data,
					currentPage: res.data.data.paginator.currentPage,
					itemCount: res.data.data.paginator.itemCount,
					perPage: res.data.data.paginator.perPage,
				});
			}).catch(res => {
				console.log("error", res);
			});
	}


	/**
	* @memberof Productos
	*
	* @method   handlePageClick
	* @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
	*
	**/
	handlePageClick = page => {
		this.receivedData(page);
	};

	/**
		  * @memberof Tamaños
		  *
		  * @method   showDeleteConfirm
		  * @description  describir funcion por favor
		  *
		  **/
	showDeleteConfirm = (item) => {
		const tm_nombre = item.nombre;
		const tm_id = item._id;

		Modal.confirm({
			title: 'Eliminar Producto',
			icon: <ExclamationCircleOutlined />,
			content: '¿Estas seguro de eliminar el producto ' + tm_nombre + ' ?',
			okText: 'Continuar',
			okType: 'danger',
			cancelText: 'Cancelar',
			onOk: () => {
				axios({
					method: 'delete',
					url: '/productos/delete',
					data: { id: tm_id }
				})
					.then((response) => {
						message.success('Producto eliminado');
						this.receivedData()
					}).catch((error) => {
						message.error('Error al eliminar');
					})
			}
		})
	}

	/**
		  * @memberof Tamaños
		  *
		  * @method   showDeleteConfirm
		  * @description  Abre el modal para eliminar una variante
		  *
		  **/
	showDeleteConfirmVariante = (item) => {
		const tm_nombre = item.nombre;
		const tm_id = item._id;

		Modal.confirm({
			title: 'Eliminar Variante',
			icon: <ExclamationCircleOutlined />,
			content: '¿Estas seguro de eliminar la variante?',
			okText: 'Continuar',
			okType: 'danger',
			cancelText: 'Cancelar',
			onOk: () => {
				axios({
					method: 'delete',
					url: '/variantes/delete',
					data: { id: tm_id }
				})
					.then((response) => {
						message.success('Variante eliminado');
						this.receivedData()
					}).catch((error) => {
						console.log("error", error);
						message.error('Error al eliminar');
					})
			}
		})
	}

	showModal = (producto_id = undefined) => this.setState({ modalProductos: true, producto_id })

	showModalVariante = (variante_id = undefined, producto_id = undefined) => { this.setState({ modalVariante_visible: true, variante_id, producto_id }) }

	render() {

		const { showModal, showModalVariante } = this

		return (
			<>
				<PageHeader
					className="site-page-header"
					title={
						<Row className="width-100" justify="space-between" align="middle">
							<Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
								<span className="ant-page-header-heading-title">
									Productos
								</span>
							</Col>
						</Row>
					}
				/>
				<Content className="admin-content content-bg pd-1">
					<Row className="mb-1">
						<Col xs={24} md={12}>
							<Search
								className="search-bar"
								placeholder="Buscar..."
								enterButton="Buscar"
								onSearch={this.searchData}
							/>
						</Col>
					</Row>
					<List
						locale={{ emptyText: "Sin Productos" }}
						dataSource={this.state.productos}
						pagination={{
							current: this.state.currentPage,
							pageSize: this.state.perPage,
							total: this.state.itemCount,
							position: 'bottom',
							className: "flex-left",
							onChange: (page, limit) => this.receivedData(page, limit)
						}}
						loading={this.state.loading}
						className="scroll"

						renderItem={item => (
							<Collapse
								key={item._id}
								bordered={false}
								defaultActiveKey={['1']}
								expandIcon={({ isActive }) => isActive ? <MinusOutlined style={{ marginTop: 13, color: '#000000', fontSize: 16 }} /> : <PlusOutlined style={{ color: "currentColor" }} style={{ marginTop: 13, color: '#000000', fontSize: 16 }} />}
								className="collapse-productos mt-1"
								style={{ border: "1px solid #F9A528" }}
							>
								<Panel
									header={
										<Row className="width-100 header-collapse">
											<Col xs={15} className="flex-left">
												<Text className="collapse-text-big">{item?.nombre}</Text>
											</Col>
											<Col span={5} className="flex-column">
												<Text>Variantes</Text>
												<Text>{item?.variantes.length}</Text>
											</Col>
											<Col xs={4} className="flex-right">
												<Space>												<Button type="primary" icon={<PlusOutlined style={{ color: "currentColor" }} />} onClick={() => showModalVariante(undefined, item._id)} />
													<Button type="primary" icon={<EditOutlined style={{ color: "currentColor" }} />} onClick={() => showModal(item._id)} />
													<Button
														title="Eliminar"
														type="danger"
														onClick={() => this.showDeleteConfirm(item)}
														icon={<DeleteOutlined style={{ color: "currentColor" }} />}>
													</Button></Space>
											</Col>
										</Row>
									}
								>
									<Row className="header-list width-100" >
										<Col xs={5} className="center">
											<Text strong>Nombre</Text>
										</Col>

										<Col xs={9} className="center">
											<Text strong>Descripción</Text>
										</Col>
										<Col xs={4} className="center">
											<Text strong>Precio Venta</Text>
										</Col>
										<Col xs={4} className="center">
											<Text strong>SKU</Text>
										</Col>
										<Col xs={2} className="center">
											<Text strong>Acciones</Text>
										</Col>
									</Row>
									{item.variantes?.map(variante => {
										return <Row className="width-100 pt-1 pb-1">
											<Col xs={5} className="center">
												<Text>{variante.nombre}</Text>
											</Col>
											<Col xs={9} className="center">
												{variante.descripcion ? <Text>{variante.descripcion}</Text> : <Text className="text-gray">Sin Descripción</Text>}
											</Col>
											<Col xs={4} className="center">
												<Text>$ {variante.precio_venta?.toMoney()} mxn</Text>
											</Col>
											<Col xs={4} className="center">
												<Text>{variante.sku_variante}</Text>
											</Col>
											<Col xs={2} className="center">
												<Space>
													<Button
														type="primary"
														icon={<EditOutlined style={{ color: "currentColor" }} />}
														onClick={() => this.setState({ modal_proveedores: true, variante_id: variante._id })}
													/>
													<Button
														type="primary"
														icon={<EditOutlined style={{ color: "currentColor" }} />}
														onClick={() => showModalVariante(variante._id, item._id)}
													/>
													<Button
														title="Eliminar"
														type="danger"
														onClick={() => this.showDeleteConfirmVariante(variante)}
														icon={<DeleteOutlined style={{ color: "currentColor" }} />}>
													</Button>
												</Space>
											</Col>
										</Row>
									})}
								</Panel>
							</Collapse>
						)}
					/>

				</Content>
				<FloatingMenu
					style={{ position: "fixed", bottom: '1em', right: '1em', }}
					slideSpeed={500}
					direction={Directions.Left}
					spacing={8}
					isOpen={this.state.isOpen}
				>
					<MainButton
						iconResting={<PlusOutlined style={{ fontSize: 20, color: "white" }} />}
						iconActive={<CloseOutlined style={{ fontSize: 20, color: "white" }} />}
						background="#F9A528"
						onClick={() => this.setState({ isOpen: !this.state.isOpen, })}
						size={56}
					/>
					<ChildButton
						icon={<PlusOutlined style={{ fontSize: 20, color: "white" }} />}
						background="#F9A528"
						size={40}
						onClick={() => this.setState({ isOpen: !this.state.isOpen, modalProductos: true })}
					/>
					<ChildButton
						icon={<CopyOutlined style={{ fontSize: 20, color: "white" }} />}
						background="#F9A528"
						size={40}
						onClick={() => this.setState({ isOpen: !this.state.isOpen, modalProductosMultiples: true })}
					/>
				</FloatingMenu>
				<ModalProductos
					visible={this.state.modalProductos}
					producto_id={this.state.producto_id}
					onCancel={() => {
						this.setState({ modalProductos: false, producto_id: undefined })
						this.receivedData()
					}}
				/>
				<ModalProductosMultiples
					visible={this.state.modalProductosMultiples}
					onCancel={() => {
						this.setState({ modalProductosMultiples: false })
						this.receivedData()
					}}
				/>
				<ModalVariantes
					visible={this.state.modalVariante_visible}
					onCancel={() => {
						this.setState({ modalVariante_visible: false, variante_id: undefined, producto_id: undefined })
						this.receivedData()
					}}
					variante_id={this.state.variante_id}
					producto_id={this.state.producto_id}
				/>
				<ModalVariantesProveedores
					visible={this.state.modal_proveedores}
					onCancel={() => {
						this.setState({ modal_proveedores: false, variante_id: undefined })
						this.receivedData()
					}}
					variante_id={this.state.variante_id}
					producto_id={this.state.producto_id}
				/>

			</>
		)
	}
}

export default Productos;