import React, { Component } from "react";
import { Table, Layout, Input, Row, Col,Switch, PageHeader, Button, message, Modal, Space, List, Typography, Card} from 'antd';
import { Link } from 'react-router-dom';
import {FormOutlined,PlusOutlined, EyeOutlined, DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';

//componentes
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";
//modal
import ModalProveedores from './ModalProveedores'

const { Header, Content } = Layout;
const { Text, Title } = Typography;
const { Search } = Input;
const axios = require('axios').default;

class Proveedores extends Component {
 	

 	constructor(props) {
		super(props);
		this.state = {
		 	nombreVista: 'Productos',
		 	user: 'Administrador',
		 	productos: [],
		 	itemCount: undefined,
		 	perPage: 10,
		 	pageCount: 1,
		 	currentPage: 1,
		 	slNo: 1,
		 	hasPrevPage: false,
		 	hasNextPage: false,
		 	prev: null,
		 	next: null,
		}
 	}

	/**
	* @memberof Productos
	*
	* @method componentDidMount
	* @description  Al montar el componente trae los datos de los usuarios y los asigna al state.users
	*
	**/
	componentDidMount() {
		axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
		/*LLAMADA AL PAGINADO*/
		this.receivedData();

	}


	/**
	* @memberof Productos
	*
	* @method   receivedData
	* @description  Metodo que realiza el paginado de los productos
	*  @param values Number con la pagina a buscar
	*
	**/
	receivedData(page){
		axios.get('/proveedores', {
			params: {
				page:page,
				limit:10
			}
		}).then(res => {
			const data = res.data.data.itemsList
			console.log(data)
			this.setState({
				proveedores: data,
				currentPage:  res.data.data.paginator.currentPage,
				itemCount:    res.data.data.paginator.itemCount,
				perPage:      res.data.data.paginator.perPage,
			});
		}).catch(res => {
			console.log("error", res);
		});
	}

	/**
	* @memberof Productos
	*	
	* @method   searchData
	* @description  Metodo que realiza la busqueda de productos
	* @param values String   con la info a buscar, por defecto cadena vacia para busquedas
	*
	**/
	searchData=(values)=>{
		axios.get('/proveedores', {
			params: {
				search: values
			}
		})
		.then(res => {
			const data = res.data.data.itemsList
			console.log(data)
			this.setState({
				proveedores: data,
				currentPage:  res.data.data.paginator.currentPage,
				itemCount:    res.data.data.paginator.itemCount,
				perPage:      res.data.data.paginator.perPage,
				pageCount:    res.data.data.paginator.pageCount,		 
			});
		}).catch(res => {
			console.log("error", res);
		});
	}


	/**
	* @memberof Productos
	*
	* @method   handlePageClick
	* @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
	*
	**/
	handlePageClick = page => {
		this.receivedData(page);
	};

	/**
	* @memberof Tamaños
	*
	* @method   showDeleteConfirm
	* @description  describir funcion por favor
	*
	**/
	showDeleteConfirm = (item) => {
		const tm_nombre = item.nombre;
		const tm_id = item._id;

			Modal.confirm({
				title: 'Eliminar Producto',
				icon: <ExclamationCircleOutlined />,
				content: '¿Estas seguro de eliminar el proveedor ' + tm_nombre + ' ?',
				okText: 'Continuar',
				okType: 'danger',
				cancelText: 'Cancelar',
				onOk: () => {
					axios({
						method: 'delete',
						url: '/proveedores/delete',
						data: { id: tm_id }
					})
					.then((response) => {
						message.success('Proveedor eliminado');
						this.receivedData()
					}).catch((error) => {
						message.error('Error al eliminar');
					})
				}
			})
	}

	render() {

		return (
			<>
				<PageHeader
					className="site-page-header"
					title={
						<Row className="width-100" justify="space-between" align="middle">
							<Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
								<span className="ant-page-header-heading-title">
									Proveedores
								</span>
							</Col>
						</Row>
					}
				/>
				<Content className="admin-content content-bg pd-1">
					<Row className="mb-1">
						<Col xs={24} md={12}>
							<Search
								className="search-bar"
								placeholder="Buscar..."
								enterButton="Buscar"
								onSearch={this.searchData}
							/>
						</Col>
						
					</Row>
					<List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Proveevores" }}
                        dataSource={this.state.proveedores}
                        pagination={{
                            onChange: this.handlePageClick,
							total: this.state.itemCount,
							pageSize:this.state.perPage,
							current: this.state.currentPage,
                            showSizeChanger: false,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            className:"flex-left",
                            
                        }}
                        header={<Row className="width-100 pr-1 pl-1" >
                            <Col span={5}  className="center">
                                <Text strong>Nombre</Text>
                            </Col>

                            <Col span={5}  className="center">
                                <Text strong>Razón Social</Text>
                            </Col>
                            <Col span={4}  className="center">
                                <Text strong>RFC</Text>
                            </Col>
                            <Col span={4}  className="center">
                                <Text strong>Crédito</Text>
                            </Col>
                            <Col span={3}  className="center">
                                <Text strong>Saldo a favor</Text>
                            </Col>
                            
                            <Col span={3}  className="center">
                                <Text strong>Acciones</Text>
                            </Col>

                        </Row>
                        }

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">

	                                    <Col span={5}  className="center">
	                                        <Text strong>{item.nombre}</Text>
	                                    </Col>

	                                    <Col span={5}  className="center">
	                                        <Text strong>{item.razon_social}</Text>
	                                    </Col>
	                                    <Col span={4}  className="center">
	                                        <Text strong>{item.rfc}</Text>
	                                    </Col>
	                                    <Col span={4}  className="center">
	                                        <Text strong>$ {Number(item.credito).toMoney(true)} MXN</Text>
	                                    </Col>
	                                    <Col span={3}  className="center">
	                                        <Text strong>$ {Number(item.saldo_favor).toMoney(true)} MXN</Text>
	                                    </Col>
	                                    <Col span={3} className="center">
	                                        <Space>
												<Button 
													title="Editar" 
													type="secondary" 
													icon={<FormOutlined   style={{  color: "currentcolor" }} />}
													onClick={()=>this.setState({modalProveedores: true, proveedor_id: item._id})}
												>
												</Button>
												
												<Button 
													title="Eliminar"
													type="danger"
													onClick={()=>this.showDeleteConfirm(item)}
													icon={<DeleteOutlined   style={{  color: "currentcolor" }}  /> }>
												</Button>
											</Space>
	                                    </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

				</Content>
				<FloatingButton title={'Agregar Usuario'} onClick={()=>this.setState({modalProveedores: true})}/>
				<ModalProveedores
					visible={this.state.modalProveedores}
					proveedor_id={this.state.proveedor_id}
					onCancel={()=>{
						this.setState({modalProveedores: false, proveedor_id: undefined})
						this.receivedData()
					}}
				/>
			</>
		)
	}
}

export default Proveedores;