import React, { Component } from "react";
import { Link } from "react-router-dom"
import {
    Layout, Row, Col, Typography, Card, Button, Pagination, Menu, Dropdown, Form, Slider, Select
} from 'antd';

import '../../Styles/Modules/Public/store.scss';
import { IconGridView, IconListView, IconHotDeals } from "../Widgets/Iconos";
import { DownOutlined } from "@ant-design/icons";
import {CardProducto} from "../Widgets/Cards"
const {Title, Paragraph} = Typography

const axios = require('axios')
/**
 *
 *
 * @description Componente que le da formato al numero debajo del slider
 */

const formatter = (value: number) => `$ ${value.toMoney(0)} MXN`;

/**
 *
 *
 * @export
 * @class Store
 * @extends {Component}
 * @description Pagina tipo tienda del sistema
 */
export default class Store extends Component{

    filtrosForm = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            //Este es el arreglo de productos.
            productos: [],
            //pagina actual
            currentPage: 1,
            //numero de paginas
            totalPages: null,
            //Limite de Productos
            offset: 12,
            //Producto que se muestra en el modal
            producto: null,
            //Para que se muestre el loading al cargar el modal
            loading: false,
            //Para que se muestre el loading al cargar los productos
            spinning: true,
            categorias: [],
            filtro_slider:[],
            precio_min: 1,
            precio_max: 1000000,
            search: this.props.search,
            precio: 1,
            sort: 'Precio (Menor al Mayor)',

        }
    };

    componentDidMount(){
        this.getCategories();
        window.scrollTo(0, 0)
        //si se selecciona una categoria desde el header, filtramos los productos por categoria 
        if(this.props.categoria && this.props.categoria !== 'all'){
            this.onCategoriesChange(this.props.categoria)
            this.filtrosForm.current?.setFieldsValue({
                categorias_id: this.props.categoria
            })
        }else{
            this.getProductos();
        }
    }


    /**
    * @memberof Store
    * @function componentDidUpdate
    * @description si hay un cambio en los props ejecuta el metodo correspondinte
    *
    * */
    componentDidUpdate(prevProps){
        console.log("prevProps", prevProps.search);
        
        if(this.props.search !== prevProps.search){
            this.updateSearch(this.props.search)
        }

        //si se selecciona una categoria desde el header, filtramos los productos por categoria 
        if(this.props.categoria && this.props.categoria !== prevProps.categoria){
            this.onCategoriesChange(this.props.categoria)
            this.filtrosForm.current?.setFieldsValue({
                categorias_id: this.props.categoria
            })
        }
        //si se selecciona la opcion de todas las categorias desde el header, quitamos el filtro
        if((this.props.categoria === 'all' && this.props.categoria !== prevProps.categoria) || (this.props.hot && this.props.hot !== prevProps.hot)){
            this.onCategoriesChange(undefined)
            this.filtrosForm.current?.setFieldsValue({
                categorias_id: undefined
            })
        }
    }


    /**
    * @memberof Store
    * @function updateSearch
    * @description Actualiza el state con el nuevo valor de search traido del header, para filtrar
    *
    * */
    updateSearch = (search) => {
        this.setState({search: search === "" ? undefined : search},()=>this.getProductos())
    }

    /**
    * @memberof Store
    * @function getProductos
    * @description Obtiene la lista de productos para mostrar en la tienda
    *
    * */
   getProductos = async ({
        pagina = this.state.currentPage,
        offset = this.state.offset,
        categorias = this.state.categorias,
    } = {}) => {
    this.setState({ spinning: true });
    axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    axios.get(`/productos`,{
            params:{


                activo: true, //productos y variantes activos
                con_variantes: true ,//que contengan por lo menos una variante
                tienda: true, //que tengan una existencia


                
                page: pagina,
                limit: offset,
                precio_min:this.state.precio_min,
                precio_max:this.state.precio_max,
                search: this.state.search,
                categorias_id: this.state.filtro_slider,
                precio: this.state.precio,
                hot: this.props.hot,

            }
        })
        .then(async success => {
            const data = success.data.data;
            console.log(data)
            this.setState({
                productos:  data.itemsList,
                totalPages: data.paginator.pageCount,
                total: data.paginator.itemCount,
                currentPage: data.paginator.currentPage,
            });
            this.setState({ spinning: false });
        })
        .catch(error => this.setState({ spinning: false }));
    };

    /**
     *
     * @name getCategories
     * @memberof Store
     * @description Obtiene el listado de categorias
     */
    getCategories = async() => {
        axios.get('/categorias', {
                params: {
                    list: true
                }
            })
            .then(async success => {
                const data = success.data.data;
                this.setState({
                    categorias: data
                })
            })
            .catch(err => console.log(err))
    }

    /**
     *
     * @name onPrecioChange
     * @param {*} value Number Array que contiene los precios
     * @memberof Store
     * @description Actualiza el precio_max y precio_min para obtener nuevos productos
     */
    onPrecioChange = async(value)=>{
        //[min, max]
        this.setState({
            precio_min:value[0],
            precio_max:value[1]
        },()=>{
            this.getProductos();
        })

        console.log('CHANGE', value)
    }

    /**
     *
     * @name onPageChange
     * @param {*} page Number que indica la pagina que se cargara
     * @memberof Store
     * @description Actualiza la currentPage del State para obtener nuevos productos
     */
    onPageChange = async(page) => {
        this.setState({
            currentPage: page
        }, () => {
            this.getProductos();
        })

        console.log('PAGE', page);
    }

    /**
     *
     * @name onCategoriesChange
     * @param {*} values String Array que contiene los id de las categorias seleccionadas para filtrar
     * @memberof Store
     * @description Actualiza filtro_slider en el State con los ids de categorias para obtener nuevos productos
     */
    onCategoriesChange =  async(values) => {
        //this.props.setCategoria(null)
        this.setState({
            filtro_slider: values
        }, () => {
            this.getProductos();
        })
    }

    render(){

        const menu = (
            <Menu>
                <Menu.Item onClick={()=>this.setState({precio: 1, sort: 'Precio (Menor a Mayor)'},()=>this.getProductos())}>
                    Precio (Menor a Mayor)
                </Menu.Item>
                <Menu.Item onClick={()=>this.setState({precio: -1, sort: 'Precio (Mayor a Menor)'},()=>this.getProductos())}>
                    Precio (Mayor a Menor)
                </Menu.Item>
            </Menu>
        )

        return(
            <Layout className="store">
                <Row 
                    className="store-section responsive"
                    justify="space-between"
                >
                    <Col xs={{span:22, offset: 1}} md={{span: 6, offset: 0}} className="mb-1" >
                        <Title
                            level={2}
                        >
                            Filtros
                        </Title>
                        <Form
                            name=""
                            layout={'vertical'}
                            initialValues={{
                                layout: 'vertical'
                            }}
                            className="store-form"
                            ref={this.filtrosForm}
                        >
                            <Form.Item
                                name="price"
                                label="Rango de precio"
                            >
                                <Slider
                                    range
                                    min={1}
                                    max={50000}
                                    defaultValue={[this.state.precio_min,this.state.precio_max]}
                                    step={1}
                                    tipFormatter={formatter}
                                    tooltipVisible
                                    tooltipPlacement="bottom"
                                    onAfterChange={this.onPrecioChange}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Categorias"
                                name="categorias_id"
                                className="store-categories"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder={"Elige una categoría"} 
                                    className="store-categories-select" 
                                    onChange={(values) => {
                                        this.onCategoriesChange(values)
                                        this.props.setCategoria(null)
                                    }}
                                >
                                    {
                                        this.state.categorias.map(categoria => (
                                            <Select.Option value={categoria._id}>
                                                {categoria.nombre}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col
                        xs={{span:22, offset: 1}} md={{span:16}}
                    >
                        <Row className="store-head-section"
                            align="middle"
                        >
                            <Col
                                flex="auto"
                            >
                                <Title level={2}>Productos {this.props.hot ? <> <IconHotDeals /> OFERTAS</> : null}</Title>
                            </Col>
                            <Col
                                flex="none"
                            >
                                <Row
                                    gutter={24}
                                >
                                   <Col
                                        flex="none"
                                   >
                                       <Row
                                        gutter={12}
                                       >
                                            {/*<Col
                                                flex="none"
                                            >
                                                <IconGridView
                                                    className="store-icon-grid-view store-icon-active"
                                                />
                                            </Col>
                                            <Col
                                                flex="none"
                                            >
                                                <IconListView
                                                    className="store-icon-list-view"
                                                />
                                            </Col>*/}
                                       </Row>
                                   </Col>
                                   <Col
                                        flex="none"
                                   >
                                       <Row
                                        gutter={16}
                                       >
                                            <Col
                                                flex="none"
                                            >
                                                Mostrar por
                                            </Col>
                                            <Col
                                                flex="none"
                                            >
                                                <Dropdown overlay={menu} trigger={['click']} >
                                                    <a className="ant-dropdown-link"  onClick={e => e.preventDefault()}>
                                                        {this.state.sort} <DownOutlined/>
                                                    </a>
                                                </Dropdown>
                                            </Col>
                                       </Row>
                                   </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="store-products">
                            {this.state.productos.map(producto=>{
                                return <Col className="store-product" xs={24} sm={12} md={12} lg={8} xl={6}>
                                    <CardProducto 
                                        producto={producto}
                                    />
                                </Col>   
                            })}

                           
                        </Row>
                        <Row className="store-pagination"
                        >
                            <Col
                                span={24}
                            >
                                <Pagination
                                    defaultCurrent = {1}
                                    current={this.state.currentPage}
                                    defaultPageSize = {this.state.offset}
                                    total = {this.state.total}
                                    showTotal = {
                                        (total, range) => `Mostrando ${range[1] - range[0] + 1} de ${total}`
                                    }
                                    onChange={this.onPageChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Layout>
        )
    }
}