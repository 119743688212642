import React, { Component } from "react";
import { Layout, Input, Row, Col, Modal, message, PageHeader, Button, Switch, List, Card, Space, Typography } from 'antd';
import { ExclamationCircleOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons';
import CustomAvatar from "../../Widgets/Avatar/Avatar";

//modal
import ModalUsuarios from './ModalUsuario'
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton"
import './../../../Styles/Modules/Usuarios/usuarios.css'

const { Content } = Layout;
const { Text } = Typography;
const { Search } = Input;
const { confirm } = Modal;

const axios = require("axios").default;

class Usuarios extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nombreVista: '',
			user: 'Administrador',
			isAdmin: true,
			dataUsuarios: [],
			itemCount: undefined,
			perPage: 10,
			pageCount: 1,
			currentPage: 1,
			slNo: 1,
			hasPrevPage: false,
			hasNextPage: false,
			prev: null,
			next: null,
			usuario_id: undefined
		}
	}
	/**
	 * @memberof Usuarios
	 *
	 * @method componentDidMount
	 * @description Se define header de axios, se llama metódo de consulta inicial receivedData
	 *
	 **/

	componentDidMount() {
		/* this.props.setTitle('Usuarios') */
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.receivedData();
	}


	/**
	* @memberof Categorias
	*
	* @method   receivedData
	* @description  Metodo que realiza el paginado de los usuaros
	* @param values String  o Number con la info a buscar, por defecto cadena vacia para busquedas
	*
	**/
	receivedData = (page = this.state.page) => {
		


		axios.get('/usuarios', {
			params: {
				page: page,
				limit: 10,
				paginate: true,
				search: this.state.search,
			}
		})
			.then(res => {
				if (res.data !== undefined) {
					this.setState({
						dataUsuarios: res.data.data.itemsList,
						page: res.data.data.paginator.currentPage,
						total: res.data.data.paginator.itemCount,
					});
				}
			}).catch(error => {
				console.log("error", error);
				message.error('Error al traer la informacion')
			})
	}




	/**
	  * @memberof Usuarios
		*
		* @method   handlePageClick
		* @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
		*
		**/
	handlePageClick = page => {
		this.setState({ currentPage: page }, () => {
			this.receivedData();
		})
	};

	/**
	* @memberof Usuarios
	  *
	  * @method   handlePageClick
	  * @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
	  *
	  **/
	handleSearch = search => {
		this.setState({ search: search }, () => {
			this.receivedData();
		})
	};

	/**
	  * @memberof Usuarios
	  *
	  * @method   showDeleteConfirm
	  * @description  Se muestra modal de confirmación para eliminar un usuario 
	  *
	  **/
	showDeleteConfirm = (item) => {
		const tm_nombre = item.nombre;
		const tm_id = item._id;

		confirm({
			title: 'Eliminar usuario',
			icon: <ExclamationCircleOutlined />,
			content: '¿Estas seguro de eliminar el usuario ' + tm_nombre + ' ?',
			okText: 'Continuar',
			okType: 'danger',
			cancelText: 'Cancelar',
			onOk: () => {
				axios({
					method: 'delete',
					url: '/usuarios/delete',
					data: { id: tm_id }
				})
					.then((response) => {
						message.success('Usuario eliminado');
						this.receivedData()
					}).catch((error) => {
						if(error?.response?.status === 403)
							message.error(error?.response?.data?.message)
						else
							message.error('Error al eliminar');
					})
			}
		})
	}

	render() {

		return (
			<>	
				<PageHeader
                    className="site-page-header"
                    title={
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                <span className="ant-page-header-heading-title">
                                    Usuarios
                                </span>
                            </Col>
                        </Row>
                    }
                />
                <Content className="admin-content content-bg pd-1">
					<Row className="mb-1">
						<Col xs={24} md={12}>
							<Search
								className="search-bar"
								placeholder="Buscar..."
								enterButton="Buscar"
								onSearch={this.handleSearch}
							/>
						</Col>
					</Row>

					<List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Usuarios" }}
                        dataSource={this.state.dataUsuarios}
                        pagination={{
                            current: this.state.page,
                            pageSize: 10,
                            total: this.state.total,
                            showSizeChanger: false,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            className:"flex-left",
                            onChange: this.receivedData
                            
                        }}
                        

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">
	                                    <Col span={5}  className="flex-left">
	                                        <CustomAvatar 
	                                        	name={item.nombre + ' ' + item?.apellido} 
	                                        	image={item.avatar}  
	                                        	size="large" 
	                                        	className="mr-1"
	                                        	color={item.tipo_usuario_id?.color ? item?.tipo_usuario_id?.color : '#dedede'}
	                                        />
											{item.nombre} {item.apellido ?? ''}
	                                    </Col>
	                                    <Col span={5}  className="center">
	                                        <Text strong>{item.email}</Text>
	                                    </Col>
	                                    <Col span={4}  className="center">
	                                        <Text strong>{item.telefono ? item.telefono : 'Sin telefono'}</Text>
	                                    </Col>
	                                    <Col span={4}  className="center">
	                                        <Text strong>{item.tipo === 1 ? 'Administrador' : 'Cliente'}</Text>
	                                    </Col>
	                                    <Col span={2}  className="center">
	                                        <Switch checked={item.activo} disabled />
	                                    </Col>
	                                    <Col span={4} className="center">
	                                        <Space>
	                                           
	                                          
												<Button
													title="Editar"
													type="secondary"
													icon={<FormOutlined  style={{  color: "currentcolor" }}  />}
													onClick={() => {
														this.setState({ ModalUsuarios: true, usuario_id: item._id })
													}}
												>
												</Button>
												<Button
													title="Eliminar"
													type="danger"
													onClick={() => this.showDeleteConfirm(item)}
													icon={<DeleteOutlined style={{  color: "currentcolor" }} />}>
												</Button>
	                                          
	                                        </Space>
	                                    </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />
					
				</Content>
				
				<FloatingButton title={'Agregar Usuario'} onClick={() => this.setState({ ModalUsuarios: true })}/>
				
				<ModalUsuarios
					visible={this.state.ModalUsuarios}
					usuario_id={this.state.usuario_id}
					onCancel={() => {
						this.setState({ ModalUsuarios: false, usuario_id: undefined })
						this.receivedData()
					}}
				/>
			</>
		)
	}
}



export default Usuarios;