import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, message, Upload, Checkbox, Spin, Radio, Space } from 'antd';
import { PlusOutlined,  DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import ColorPicker from '../../Widgets/ColorPicker';


const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;


class ModalVariante extends Component {


    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
            proveedores: [],
            proveedor_variantes: [],
        }
    }

    refModalVariante = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getProveedores()

        if(this.props.variante_id){
            this.getProveedoresVariantes()
            this.getVariante()
        }

    }

    /**
     * @memberof ModalCuentas
     * @method getProveedores
     * @description Obtiene los proveedores
     * 
     */
    getProveedores = (search) => {

        axios.get('/proveedores',{
            params:{
                search: search,
                variante_id: this.props.variante_id
            }
        }).then(response => {
            this.setState({proveedores: response.data.data.itemsList})

        }).catch(error => {
            console.log("error", error);

        })
    }

    /**
    * @memberof FormVariantes
    *
    * @method getVariantes
    * @description  trae la informacion de una categoria
    *
    **/
    getVariante = () => {
        this.setState({ loading: true })
        axios.get(`/variantes/get`, {
            params: {
                id: this.props.variante_id
            }
        }).then(async res => {
            let variante = res.data.data.variante;
            this.setState({ variante: variante })
        }).catch(res => {
            message.error('Error al obtener la información ')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalCuentas
     * @method getProveedoresVariantes
     * @description Obtiene los proveedores de la variante
     * 
     */
    getProveedoresVariantes = (values) => {
        axios.get('/variantes/proveedores',{
            params:{
                variante_id: this.props.variante_id
            }
        }).then(response => {
            this.setState({proveedor_variantes: response.data.data})

        }).catch(error => {
            console.log("error", error);

        })
    }

    /**
     * @memberof ModalCuentas
     * @method onFinish
     * @description Obtiene los proveedores de la variante
     * 
     */
    onFinish = (values) => {
        
        this.addProveedorVariante(values)
        
    }

    /**
     * @memberof ModalCuentas
     * @method addProveedorVariante
     * @description Obtiene los proveedores de la variante
     * 
     */
    addProveedorVariante = (values) => {
        axios.post('/variantes/proveedores/update',{
            ...values,
            variante_id: this.props.variante_id,
            proveedor_variantes: this.state.proveedor_variantes
        }).then(response => {
            message.success('Proveedor actualizado con exito')
            this.props.onCancel()
        }).catch(error => {
            console.log("error", error);
            if(error.response.status === 403)
                message.error(error.response.data.message)
            else
                message.error('Error al agregar el proveedor')
        })
    }

     /**
     * @memberof ModalCuentas
     * @method updateProducto
     * @description Actualiza los valores del los prodcutos en el state
     * 
     */
    updateProducto = (values, index) => {
    	console.log("index", index);
    	console.log("values", values);

       	let proveedor_variantes = this.state.proveedor_variantes

       	if(proveedor_variantes[index]){
       		proveedor_variantes[index].costo = values.costo
       	}

       	this.setState({proveedor_variantes})
    }




    render() {

        return (
        	<>
        	<div className="flex-column">
	            <Title level={3}>Proveedores Costos</Title>
	            <Title level={4}> Precio Variante: $ {this.state.variante?.precio_venta?.toMoney()} mxn</Title>
	        </div>
        
            <Spin spinning={this.state.loading}>
            	<Title level={5} style={{fontWeight: '400'}} className="pl-2 mt-2 text-orange">Lista de Proveedores</Title>
                <Row className="" gutter={[20,20]} style={{minHeight: '200px'}}>
                    {
                        this.state.proveedor_variantes.map((p_v,index) =>{
                            return <Form
                            	className="width-100 "
                            	layout="vertical"
                                onValuesChange={(values, all) => this.updateProducto(all, index)}
                                initialValues={{...p_v}}
                            >
                                <Form.Item name="_id" hidden/>
                                <Row justify="center">
                                    <Col span={14} className="">
                                    	<Form.Item 
                              				label={<Text strong>{p_v.proveedor_id.nombre}</Text>}
                                        >	
                                            	
                                            <Text >{p_v.proveedor_id.razon_social}</Text>
                                            
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item 
                                        	label="Costo"
                                        	name="costo">
                                            <InputNumber min={1} precision={2} className="width-100" size="small"/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        })
                    }
                </Row>
                <Form
	                layout="vertical"
	                name="form-materiales"
	                ref={this.refModalVariante}
	                onFinish={this.onFinish}

	            >
	            	<Row justify="center" gutter={[16,16]}>
                        <Col span={21}>
                            <Form.Item
                                label="Agregar proveedor"
                                name="proveedor_id_new"
                            >
                                <Select 
                                    showSearch
                                    allowClear
                                    filterOption={false}
                                    onSearch={this.getProveedores}
                                    onSelect={(proveedor_id)=>{
                                        this.refModalVariante.current.setFieldsValue({
                                            proveedor_id: null
                                        })
                                        this.setState({proveedor_id})
                                    }}
                                    placeholder="Seleccionar opción" 
                                >
                                    {this.state.proveedores.map(function (proveedor, index) {
                                        return <Option value={proveedor._id}>{proveedor.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        {this.state.proveedor_id ? <Col span={21}>
                            <Form.Item
                                label="Costo de la variante"
                                name="costo"
                                rules={[{ required: true, message: 'Por favor ingresa el costo de la variante' }]}
                            >
                                <InputNumber min={0} className="width-100"/>
                            </Form.Item>
                        </Col> : null}
            		</Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24} className="mt-3">
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading || this.state.loadingImage}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        </>)
    }
}



export default function (props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        destroyOnClose={true}
        zIndex={1000}
    >
        <ModalVariante {...props} />
    </Modal>

}