import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, List, Switch, Upload } from 'antd';

import {  FileImageOutlined } from '@ant-design/icons';

import PropTypes from "prop-types";
import Logged from '../../../Hooks/Logged';
import CustomAvatar from "../../Widgets/Avatar/Avatar";


const axios = require('axios').default;
const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;
const { Dragger } = Upload;

/**
 *
 *
 * @class FormProductos
 * @extends {React.Component}
 * @description Formulario de categorias
 */
class FormProveedores extends Component {


    formModalProveedores = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            categorias: [],
            loadingImage: false,
            form: {},           
            id: null,
            image: null,
            negocios: [],
            fileList:[],


        }
    }


    /**
     * @methodOf FormProductos
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if(this.props.proveedor_id){
            this.getData()
        }
    }

    

    /**
   * @memberof FormProductos
   *
   * @method onFinish
   * @description  Se ejecuta al dar finalizar al formulario
   *
   **/
    onFinish = values => {
        this.setState({loading: true})
        if(this.props.proveedor_id !== undefined){
            this.updateProveedor(values)
        }else{
            this.addProveedor(values);
        }
        
    }


    /**
    * @memberof FormProductos
    *
    * @method getData
    * @description  trae la informacion de una categoria
    *
    **/
    getData = (values) => {
        this.setState({loading: true})
        console.log(this.props.proveedor_id)
        axios.get(`/proveedores/get`,{
            params: {
                id: this.props.proveedor_id
            }
        }).then(async res => {
            console.log("respuesta",res)
            console.log(res.data.data)

            let proveedor = res.data.data

            
            
            

            this.formModalProveedores.current?.setFieldsValue({
                nombre:        proveedor.nombre,
                razon_social:  proveedor.razon_social,
                rfc:           proveedor.rfc,
                credito:       proveedor.credito,
                saldo_favor:   proveedor.saldo_favor,
            })

                
               
        }).catch(res => {
            console.log("error", res);
            message.error('Error al cargar la informacion')
        }).finally(()=>this.setState({loading: false}));
    }


    /**
    * @memberof FormProductos
    *
    * @method updateProducto
    * @description  Actualiza la informacion de una categoria
    *
    **/
    updateProveedor = (values) => {
        axios.put('/proveedores/update', {
            id: this.props.proveedor_id,
            nombre:        values.nombre,
            razon_social:  values.razon_social,
            rfc:           values.rfc,
            credito:       values.credito,
            saldo_favor:   values.saldo_favor,
        }).then((res) => {
            message.success('Proveedor Actualizado');
            this.props.onCancel()
        }).catch((error) => {
            console.log(error)
            message.error('Error al actualizar')
        }).finally(()=>this.setState({loading: false}));   
    }

    /**
    * @memberof FormProductos
    *
    * @method addProducto
    * @description  Añade una categoria
    **/
    addProveedor = (values) => {
        axios.post('/proveedores/add', {
            nombre:        values.nombre,
            razon_social:  values.razon_social,
            rfc:           values.rfc,
            credito:       values.credito,
            saldo_favor:   values.saldo_favor,
            

        }).then((res) => {
            message.success(res.data.message);
            this.props.onCancel()
        }).catch((error) => {
            Modal.warning({
                title: 'Error',
                content:
                    <List
                        size="small"
                        bordered
                         dataSource="No se ha podido crear el proveedor"
                    />
            });
        }).finally(()=>this.setState({loading: false}));
       
    }

    /**
    * @memberof FormProductos
    *
    * @method addProducto
    * @description  Añade una categoria
    **/
    normFile = (event) => {
        console.log('upload event', event)

        let update_fileList = false;
        let delete_file = false;
            
        //Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
             
        if (event.file.status == "done") {
            update_fileList = true;
        }
            
        if (event.file.status == "removed") {
            update_fileList = true;
            delete_file = true;
        }
            
        //if (delete_file)
        //    this.removeFile((event.file.response.filename != undefined) ? event.file.response.filename : event.file.name);

    
        if (update_fileList){
            let arrayList = [];

            for (let x = 0; x < event.fileList.length; x++){
                try{
                    let filename = event.fileList[x].response.filename
                    arrayList.push(event.fileList[x].response.filename)
                }catch (e) {
                    console.log(e)
                }
            }


            console.log('ARRY', arrayList)

            this.setState({
                fileList: arrayList
            });
        }
        
        return event && event.fileList;
       
    }






    render() {

        const { loading } = this.state

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">Proveedores</Title>
                <Form 
                    layout="vertical" 
                    ref={this.formModalProveedores} 
                    onFinish={this.onFinish} 
                >

                    <Row>
                        <Col xs={24} lg={24}>
                            <Form.Item 
                                name="nombre" 
                                label="Nombre" 
                                rules={[{
                                    required: true,
                                    message: 'Por favor ingresa nombre del proveedor'
                                }]}
                            >
                                <Input placeholder="Nombre del proveedor" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={24} lg={24}>
                            <Form.Item 
                                name="razon_social" 
                                label="Razon Social" 
                                rules={[{
                                    required: true,
                                    message: 'Por favor ingresa la razon social del proveedor'
                                }]}
                            >
                                <Input placeholder="Razon Social" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={24} lg={24}>
                            <Form.Item 
                                name="rfc" 
                                label="RFC" 
                                rules={[{
                                    required: true,
                                    message: 'Por favor ingresa el RFC del proveedor'
                                }]}
                            >
                                <Input placeholder="RFC" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={24} lg={24}>
                            <Form.Item 
                                name="credito" 
                                label="Credito" 
                                rules={[{
                                    required: true,
                                    message: 'Por favor ingresa el Credito del proveedor'
                                }]}
                            >
                                <Input placeholder="10,000" />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={24} lg={24}>
                            <Form.Item 
                                name="saldo_favor" 
                                label="Saldo a favor" 
                                rules={[{
                                    required: true,
                                    message: 'Por favor ingresa el saldo a favor del proveedor'
                                }]}
                            >
                                <Input placeholder="10,000" />
                            </Form.Item>
                        </Col>

                    </Row>


                    <Row justify="center" align="middle" >
                        <Col span={24} className="mt-1 flex-column">
                            <Form.Item>
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {

    let { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormProveedores {...props} />
        </Modal>
    )
}