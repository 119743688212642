import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, PageHeader, Tag, Button, Space, Typography, Card, Popconfirm, List, Avatar, Drawer } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import { IconBus, IconEye, IconEdit, IconDelete, IconPDF } from "../../Widgets/Iconos";
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton"
//componentes
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import '../../../Styles/Global/admin.scss';
import '../../../Styles/Modules/cotizador.css';
import { Link } from "react-router-dom";

//modales
import ModalCotizacion from './Modales/ModalCotizaciones'
import ModalOrdenes from './Modales/ModalOrdenes'
import ModalDetalle from "./ModalDetalle";
import DrawerOrden from "./Modales/DrawerOrden";


const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;
const { Title, Text } = Typography

const axios = require("axios").default;
const moment = require('moment');



const colors = ["#00000", "#87d068", "#FA9100"];
/**
 *
 *
 * @class Cotizaciones
 * @extends {Component}
 */
export default class Cotizaciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            tipo: null,
            modal_visible: false,
            orden_id: undefined,
            
            page: 1,
            limit: 10,
            total:0,

        }
    }

    /**
    * @memberof Cotizaciones
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    *
    *
    **/
    componentDidMount() {
        /* this.props.setTitle('Ventas') */
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getDatos()

    }

    /**
    * @memberof Compras
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    **/
     getDatos = (page = this.state.page) => {
        this.setState({loading: true})
        axios.get('/ordenes',{
            params:{
                limit:10,
                page: page,
            }
        }).then(response => {
            this.setState({
                data: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
            })
            
        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la información')
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     *
     *
     * @memberof FormOrdenes
     * 
     * @method getPDF
     * @description Generamos el PDF de la contizacion
     */
    getPDF = (orden_id) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/ordenes/pdf');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('orden_id', orden_id)
        return urlPDF.href
    }


    render() {

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title={
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                <span className="ant-page-header-heading-title">
                                    Cotizaciones
                                </span>
                            </Col>
                        </Row>
                    }
                />
                <Content className="admin-content content-bg pd-1">

                    <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Cotizaciones" }}
                            dataSource={this.state.data}
                            pagination={{
                                current: this.state.page,
                                pageSize: 10,
                                total: this.state.total,
                                showSizeChanger: false,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                className:"flex-left",
                                onChange: (page) => this.getDatos(page)
                                
                            }}
                            header={<Row className="width-100 pr-1 pl-1" >
                                <Col span={1}  className="center">
                                    <Text strong>#</Text>
                                </Col>
                                <Col span={3}  className="center">
                                    <Text strong>Fecha</Text>
                                </Col>
                                <Col span={7}  className="center">
                                    <Text strong>Cliente</Text>
                                </Col>
                                <Col span={3}  className="center">
                                    <Text strong>Tipo</Text>
                                </Col>
                                <Col span={2}  className="center">
                                    <Text strong>Productos</Text>
                                </Col>
                                <Col span={4}  className="center">
                                    <Text strong>Monto</Text>
                                </Col>
                                <Col span={4}  className="center">
                                    <Text strong>Acciones</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                        <Col span={1}  className="center">
                                            <Text strong>{item.folio_number}</Text>
                                        </Col>
                                        <Col span={3}  className="center">
                                            <Text strong>{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                                        </Col>
                                        <Col span={7}  className="center">
                                            {item.usuario_id ? <>
                                                <CustomAvatar 
                                                    name={item.usuario_id?.nombre + ' ' + item.usuario_id?.apellido} 
                                                    image={item.usuario_id?.avatar}  
                                                    color={item.tipo_usuario_id?.color ? item?.tipo_usuario_id?.color : '#9664FF'}
                                                    style={{minWidth: '32px', marginRight:'8px'}}
                                                />
                                                <Text strong ellipsis style={{ marginRight:'8px'}}>{item.usuario_id?.nombre + ' ' + item.usuario_id?.apellido} </Text>
                                            </> : <Text>N/A</Text>}
                                            
                                        </Col>
                                        <Col span={3}  className="center">
                                            <Tag className="tipo-tag" strong>{(item.tipo == 1) ? "Cotizacion": "Orden de compra"}</Tag>
                                        </Col>
                                        <Col span={2}  className="center">
                                            <Text strong>{item.productos.length}</Text>
                                        </Col>
                                        <Col span={4}  className="center">
                                            <Text strong>$ {item.tipo === 1 ? item.total_venta?.toMoney() : item.total_venta?.toMoney()} mxn</Text>
                                        </Col>
                                            <Col span={4} className="center">
                                                <Space>
                                                    
                                                    {/*<a href={this.getPDF(item._id)} download target="_blank">
                                                        <Button
                                                            className="center"
                                                            type="primary" 
                                                            icon={<IconPDF/>} 
                                                        />
                                                    </a>*/}
                                                    <Button 
                                                        type="primary" 
                                                        icon={<IconEye/>} 
                                                        onClick={()=>this.setState({modal_visible: true, tipo: item.tipo, orden_id:item._id})}
                                                    />
                                                    <Button 
                                                        type="primary" 
                                                        icon={<IconEdit/>} 
                                                        onClick={()=>this.setState({drawer_visible: true, tipo: item.tipo, orden_id:item._id})}
                                                    /> 
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta cotizacion?"
                                                        okText="Si"
                                                        cancelText="No"
                                                        onConfirm={()=>{
                                                            axios.post('/ordenes/delete',{
                                                                orden_id: item._id
                                                            }).then(()=>this.getDatos()).catch(()=>message.error('Error al eliminar'))
                                                        }}
                                                    >
                                                        <Button type="normal" danger  title="Eliminar" icon={<IconDelete/>}/>
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                        
                </Content>
                <Link to={'/admin/cotizaciones/nueva'}>
                    <FloatingButton/>
                </Link>
                <ModalCotizacion
                    visible={this.state.modal_visible && this.state.tipo === 1}
                    onCancel={()=>{
                        this.setState({modal_visible: false, tipo: null, orden_id: undefined})
                        this.getDatos()
                    }}
                    orden_id={this.state.orden_id}
                    
                />
                <ModalOrdenes
                    visible={this.state.modal_visible && this.state.tipo === 2}
                    onCancel={()=>{
                        this.setState({modal_visible: false, tipo: null, orden_id: undefined})
                        this.getDatos()
                    }}
                    orden_id={this.state.orden_id}
                    
                />
                <ModalDetalle
					visible={this.state.modalDetalle}
					close={()=>{
						this.setState({modalDetalle: false})
					}}
                    tipo = {this.state.tipo}
                    id = {this.state.detalle}
				/>
                
                <DrawerOrden
                    visible={this.state.drawer_visible}
                    orden_id={this.state.orden_id}
                    onCancel={()=>{
                        this.setState({drawer_visible: false, orden_id: undefined})
                        this.getDatos()
                    }}
                />
                
            
            </>
        )
    }
}

