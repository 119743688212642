import React from 'react'
import { Route, Switch } from "react-router-dom";
import Tamanos from '../../components/Admin/Tamanos/Tamanos';


/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function TamanosRouter( props) {
    return (
        <Switch>
            <Route exact path="/admin/tamanos" render={() => <Tamanos {...props} />} />
        </Switch>
    )
}

export default TamanosRouter;
