import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, PageHeader, Tag, Button, Space, Typography, Card, Popconfirm, List } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import { IconBus, IconEye, IconEdit, IconDelete } from "../../Widgets/Iconos";
import { ExclamationCircleOutlined, FormOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";
//modales
import ModalCompras from './ModalCompras'
import ModalComprasDetalle from './ModalCompraDetalle'


const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;
const { Title, Text } = Typography

const axios = require("axios").default;
const moment = require('moment');



const colors = ["#00000", "#87d068", "#FA9100"];
/**
 *
 *
 * @class Compras
 * @extends {Component}
 */
export default class Compras extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            compras: [],
            page: 1,
            total: 0,
        }
    }

    /**
    * @memberof Compras
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    *
    *
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getCompras();
    }



    /**
    * @memberof Compras
    * @method   getCompras
    * @description  Obtitne las compras realizadas por loa clientes
    **/
    getCompras = (page = this.state.page) => {
        this.setState({loading: true})
        axios.get('/compras',{
            params:{
                page,
                //cotizacion: true
            }
        }).then(response => {
            this.setState({
                compras: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la información')
        }).finally(()=>this.setState({loading: false}))
    }

    render() {

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title={
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                <span className="ant-page-header-heading-title">
                                    Compras
                                </span>
                            </Col>
                        </Row>
                    }
                />
                <Content className="admin-content content-bg pd-1">

                    <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Usuarios" }}
                            dataSource={this.state.compras}
                            pagination={{
                                current: this.state.page,
                                pageSize: 10,
                                total: this.state.total,
                                showSizeChanger: false,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                className:"flex-left",
                                onChange: this.getUsuarios
                                
                            }}
                            header={<Row className="width-100 pr-1 pl-1" >
                                <Col span={8}  className="center">
                                    <Text strong>Proveedor</Text>
                                </Col>
                                <Col span={4}  className="center">
                                    <Text strong># Unidades</Text>
                                </Col>
                                <Col span={4}  className="center">
                                    <Text strong>Monto</Text>
                                </Col>
                                <Col span={4}  className="center">
                                    <Text strong>Fecha</Text>
                                </Col>
                                <Col span={4}  className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                                
                                

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">
                                            <Col span={8}  className="center">
                                                <Text strong>{item.proveedor_id.razon_social}</Text>
                                            </Col>
                                            <Col span={4}  className="center">
                                                <Text strong>{item.cantidad} Unidad(es)</Text>
                                            </Col>
                                            <Col span={4}  className="center">
                                                <Text strong>$ {item.total.toMoney()} mxn</Text>
                                            </Col>
                                            <Col span={4}  className="center">
                                                <Text strong>{moment(item.createdAt).format('DD-MM-YYYY HH:mm')}</Text>
                                            </Col>
                                            <Col span={4}  className="center">
                                                <Space>
                                                    <Button
                                                        title="Ver Detalle" 
                                                        type="primary" 
                                                        onClick={()=>this.setState({modal_visible_detalle: true, compra_id: item._id})}
                                                        icon={<IconEye/>}> 
                                                    </Button>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta compra?"
                                                        okText="Si"
                                                        cancelText="No"
                                                        onConfirm={()=>{
                                                            axios.delete(`/compras/${item._id}`).then(()=>this.getCompras()).catch(()=>message.error('Error al eliminar'))
                                                        }}
                                                    >
                                                        <Button type="normal" danger  title="Eliminar" icon={<IconDelete/>}/>
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                </Content>

                <FloatingButton title={'Agregar Usuario'} onClick={() => this.setState({ modal_visible: true })}/>

                <ModalCompras
                    visible={this.state.modal_visible}
                    onCancel={()=>{
                        this.setState({
                            modal_visible: false, compra_id: undefined
                        })
                        this.getCompras()
                    }}
                    compra_id={this.state.compra_id}
                />

                <ModalComprasDetalle
                    visible={this.state.modal_visible_detalle}
                    onCancel={()=>{
                        this.setState({
                            modal_visible_detalle: false, compra_id: undefined
                        })
                    }}
                    compra_id={this.state.compra_id}
                />

            </>
        )
    }
}

