import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, PageHeader, Tag, Button, Space, Typography, Card, Popconfirm, List, Avatar } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import { IconBus, IconEye, IconEdit, IconDelete } from "../../Widgets/Iconos";
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import '../../../Styles/Global/admin.scss';

//modales
import ModalVentaDetalle from '../Ventas/ModalDetalle'


const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;
const { Title, Text } = Typography

const axios = require("axios").default;
const moment = require('moment');



const colors = ["#00000", "#87d068", "#FA9100"];
/**
 *
 *
 * @class Cotizaciones
 * @extends {Component}
 */
export default class Cotizaciones extends Component {
    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            cotizaciones: [],

            page: 1,
            limit: 10,
            total: 0,
        }
    }

    componentDidMount() {
        /* this.props.setTitle('Ventas') */
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getVentas();
    }



    /**
    * @memberof Ventas
    *
    * @method   getVentas
    * @description  Metodo que realiza el paginado de las Ventas
    * @param page  Number con la pagina a buscar
    *
    **/
    getVentas = (page = this.state.currentPage) => {
        this.setState({loading: true})
         axios.get('/ventas',{
            params:{
                page,
                cotizacion: true,
                cliente_id: this.props.usuario_id
            }
        }).then(response => {
            console.log("response", response.data.data);
            this.setState({
                ventas: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
            })

        }).catch(error => {
            console.log("error", error);

        }).finally(()=>this.setState({loading: false}))
    }


    /**
     * @memberof Ventas
     * @description Renderiza el Tag correspondiente al status de la venta
     * @param status  1 = En proceso, 2= En transito , 3= Entregado
     */
    renderTag = (status) => {

        let text = "";
        switch (status) {
            case 1:
                text = "En Proceso";
                break;
            case 2:
                text = "Completado";
                break;
            

            default:
                break;
        }
        return <Tag color={colors[status]}>{text}</Tag>
    }

    /**
     * @memberof Ventas
     * @description Renderiza el Tag correspondiente al status de la venta
     * @param status  1 = En proceso, 2= En transito , 3= Entregado
     */
    renderTagEnvio = (status) => {

        let text = "";
        switch (status) {
            case 1:
                text = "En Proceso";
                break;
            case 2:
                text = "En Transito";
                break;
            case 3:
                text = "Entregado";
                break;

            default:
                text = "En Proceso";
                status = 1
                break;
        }
        return <Tag color={colors[status]}>{text}</Tag>
    }
        

    render() {

        return (
            <>
                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Usuarios" }}
                        dataSource={this.state.ventas}
                        pagination={{
                            current: this.state.page,
                            pageSize: 10,
                            total: this.state.total,
                            showSizeChanger: false,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            className:"flex-left",
                            onChange: (page) => this.getVentas(page)
                            
                        }}
                        header={<Row className="width-100 pr-1 pl-1" >
                            <Col span={3}  className="center">
                                <Text strong># Orden</Text>
                            </Col>

                            <Col span={3}  className="center">
                                <Text strong>Cliente</Text>
                            </Col>
                            <Col span={3}  className="center">
                                <Text strong># Productos</Text>
                            </Col>
                            <Col span={3}  className="center">
                                <Text strong>Total</Text>
                            </Col>
                            <Col span={3}  className="center">
                                <Text strong>Fecha</Text>
                            </Col>

                            <Col span={3}  className="center">
                                <Text strong>Estatus Venta</Text>
                            </Col>
                            <Col span={3}  className="center">
                                <Text strong>Estatus Envio</Text>
                            </Col>
                            <Col span={3}  className="center">
                                <Text strong>Acciones</Text>
                            </Col>

                        </Row>
                        }

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">

                                    <Col span={3}  className="center">
                                        <Text strong>{`${item.folio}`}</Text>
                                    </Col>

                                    <Col span={3}  className="center">
                                        {item.usuario_id ? <>
                                            <CustomAvatar 
                                                name={item.usuario_id?.nombre + ' ' + item.usuario_id?.apellido} 
                                                image={item.usuario_id?.avatar}  
                                                color={item.usuario_id?.tipo_usuario_id?.color}
                                            />
                                            <Text strong>{item.usuario_id?.nombre + ' ' + item.usuario_id?.apellido} </Text>
                                        </> : <Text>N/A</Text>}
                                    </Col>
                                    <Col span={3}  className="center">
                                        <Text strong>{item.productos?.length}</Text>
                                    </Col>
                                    <Col span={3}  className="center">
                                        <Text strong>{`$ ${item.total_venta.toMoney()} MXN`}</Text>
                                    </Col>
                                    <Col span={3}  className="center">
                                        <Text strong>{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                                    </Col>

                                    <Col span={3}  className="center">
                                        <Text strong>{this.renderTag(item.status)}</Text>
                                    </Col>
                                    <Col span={3}  className="center">
                                        <Text strong>{this.renderTagEnvio(item.status_envio)}</Text>
                                    </Col>
                                        <Col span={3} className="center">
                                            <Space>
                                               
                                                <Button type="primary" icon={<IconEdit/>} onClick={()=>this.setState({modal_visible: true, venta_id: item._id})}/>
                                                
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />
                <ModalVentaDetalle 
                    visible={this.state.modal_visible}
                    onCancel={()=>this.setState({
                        modal_visible: false
                    })}
                    venta_id={this.state.venta_id}
                />
            </>
        )
    }
}