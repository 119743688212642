import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, Select, PageHeader, Tag, Button, Switch, Space, Typography, Card, Popconfirm, List, Avatar, Tabs, Image, Form, Spin } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import { IconBus, IconEye, IconEdit, IconDelete } from "../../../Widgets/Iconos";
import '../../../../Styles/Global/admin.scss';
import Paragraph from "antd/lib/skeleton/Paragraph";
import { toHaveStyle } from "@testing-library/jest-dom/dist/matchers";
import { Redirect } from 'react-router-dom';
import { CarritoOrden } from "../OrdenDeCompra"
//modales



const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;
const { Title, Text } = Typography
const { TabPane } = Tabs;
const {Option} = Select

const axios = require("axios").default;
const moment = require('moment');



const colors = ["#00000", "#87d068", "#FA9100"];
/**
 *
 *
 * @class Cotizaciones
 * @extends {Component}
 */
class Cotizaciones extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seleccion: 1,
            sending: false,
            orden: {
                subtotal_venta: 0,
                iva_venta: 0,
                total_venta: 0
            },
            modal_visible: false,
            clientes: [],
            usuario_id: undefined,
            redirect: false,
            productos: [],
            tipo: 1,
            loading: false,
        }
    }

    usuarioRef = React.createRef()
    productosRef = React.createRef()
    
    componentDidMount() {
        /* this.props.setTitle('Ventas') */
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getOrden()
        
    }

    componentDidUpdate(prevProps){
        if(this.props.orden_id && prevProps.orden_id != this.props.orden_id){
            this.getOrden()
        }

    }

    /**
    * @memberof Cotizaciones
    *
    * @method getOrden
    * @description Obtiene la orden y sus productos
    **/
    getOrden = () => {
        axios.get('/ordenes/detalle',{
            params:{
                orden_id: this.props.orden_id
            }
        }).then(response => {

            this.setState({
                productos: []
            },()=>{
                this.setState({
                    productos: response.data.orden_detalles,
                    orden: response.data.orden,
                })
            })

        }).catch(error => {
            console.log("error", error);

        })
    }

    /**
    * @memberof Cotizaciones
    *
    * @method updateProducto
    * @description Actualiza la información de una orden detalle, y vuelve a obtener todos los detalles con los calculos
    **/
    updateProducto = (values, index) => {

        let productos = this.state.productos

        productos[index].fecha_entrega_min = values.fecha_entrega_min
        productos[index].fecha_entrega_max = values.fecha_entrega_max

        this.setState({ productos })

    }

    
    /**
    * @memberof Cotizaciones
    *
    * @method   onChangeOrden
    * @description actualiza la orden para quitar o añadir el iva, y asignar un cliente, en caso de ser confirmada la orden se redirige a la vista de 
    * lista de ordenes
    **/
    onChangeOrden = (values) => {

        let { iva_agregado, metodo_pago, metodo_entrega, usuario_id } = values
        console.log("values", values);

        let orden = this.state.orden


        if(iva_agregado === true){
            orden.iva_venta = ((orden.subtotal_venta * 100) * (0.16 * 100)) / 10000
            orden.total_venta =  ((orden.total_venta * 100) + ( orden.iva_venta * 100)) / 100
        }else if(iva_agregado === false){
            orden.iva_venta = 0
            orden.total_venta = orden.subtotal_venta
        }

        if(iva_agregado != undefined) orden.iva_agregado_venta = iva_agregado

        if(metodo_entrega != undefined) orden.metodo_entrega = metodo_entrega

        if(metodo_pago != undefined) orden.metodo_pago = metodo_pago

        if(usuario_id != undefined) orden.usuario_id = usuario_id.value

        this.setState({orden: orden}) 
    }


    /**
    * @memberof Cotizaciones
    *
    * @method   confirmOrden
    * @description actualiza la orden para quitar o añadir el iva
    **/
    confirmOrden = () => {
        this.setState({loading: true})
        axios.post('/ordenes/update/all',{
            orden: {...this.state.orden, tipo: 2},
            orden_detalles: this.state.productos,
        }).then(response => {
            message.success('Orden Actualizada')
            this.props.onCancel()
        }).catch(error =>{
            console.log("error", error);
            if(error?.response?.status === 403){
                message.error(error?.response?.data?.message)
            }else{
                message.error('Error al actualizar')
            }
        }).finally(()=>this.setState({loading: false}))
    }

    render() {

        const { orden, redirect, tipo } = this.state

        if(redirect){
            return <Redirect to="/admin/cotizaciones"/>
        }

        return (
            <Spin spinning={this.state.loading}>
                <Row>
                    <Col span={24}>
                        <CarritoOrden
                            productos={this.state.productos}
                            updateProducto={(values, index)=>this.updateProducto(values,index)}
                            getOrden={()=>this.getOrden()}
                            onChangeOrden={this.onChangeOrden}
                            orden={orden}
                        />
                    </Col>
  
                </Row>
                <Row>
                    <Col span={12}>
                        <Text className="titulo">Subtotal</Text>
                    </Col>
                    <Col span={12} className="flex-right">
                        <Text className="dato">$ {orden.subtotal_venta.toMoney()} mxn</Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Text className="titulo">
                            IVA <Switch 
                                    size="small" 
                                    onChange={(check) => this.onChangeOrden({iva_agregado: check})}
                                    checked={orden.iva_agregado_venta}
                                />
                        </Text>
                    </Col>
                    <Col span={12} className="flex-right">
                        <Text className="dato">$ {orden.iva_venta.toMoney()} mxn</Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Text className="titulo">Total</Text>
                    </Col>
                    <Col span={12} className="flex-right">
                        <Text className="dato">$ {orden.total_venta.toMoney()} mxn</Text>
                    </Col>
                </Row>
                <Row className="center">
                    <Button
                        disabled={orden.total_venta === 0}
                        className="mt-2 generar"
                        onClick={() => this.confirmOrden()}
                    >{orden.tipo == 1 ? 'Generar Orden de Compra' : 'Guardar Orden de Compra'}</Button>
                </Row>
            </Spin>
                       
        )
    }
}



export default function (props) {
    const { visible = false, onCancel = () => { } } = props

    return <Cotizaciones {...props} />

}
