import React, { Component } from "react";
import { PageHeader, Layout, Row, Col, List, Button, Typography, Card, message, Switch, Space, Modal } from 'antd';
import { FormOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

//componentes
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton"
//modales
import ModalVariantes from './ModalVariantes'

const { Header, Content } = Layout;
const { Text } = Typography;

const axios = require('axios').default;


class Variantes extends Component {

	constructor(props){
		super(props)
		this.state = {
			loading: false,
			producto_id: this.props.match.params.producto_id,
			producto: {},
			variantes: [],

			page: 1,
			limit: 10,
			total: 0,
		}
	}

	componentDidMount(){
		axios.defaults.headers.common["Authorization"] = sessionStorage.getItem('token');
		this.getProducto()
		this.getVariantes()
	}

	/**
    * @memberof Variantes
    *
    * @method getProducto
    * @description  Obtiene la información del producto
    *
    **/
    getProducto = () => {
        this.setState({loading: true})
        axios.get(`/productos/get`,{
            params: {
                id: this.props.match.params.producto_id,
            }
        }).then(async res => {

            console.log(res.data.data.producto)

            let producto = res.data.data.producto

            this.setState({producto})
         
        }).catch(res => {
            console.log("error", res);
            message.error('Error al cargar la información')
        }).finally(()=>this.setState({loading: false}));
    }

    /**
    * @memberof Variantes
    *
    * @method getVariantes
    * @description  Obtiene la lista de variantes
    *
    **/
    getVariantes = (page = this.state.page) => {
        this.setState({loading: true})
        axios.get(`/variantes`,{
            params: {
                producto_id: this.props.match.params.producto_id,
            }
        }).then(async res => {
        	console.log("res", res.data.data.paginator);
        	this.setState({
        		variantes : res.data.data.itemsList,
        		page: res.data.data.paginator.currentPage,
        		total: res.data.data.paginator.itemCount,

        	})
         
        }).catch(res => {
            console.log("error", res);
            message.error('Error al cargar la información')
        }).finally(()=>this.setState({loading: false}));
    }

    /**
   	* @memberof Tamaños
   	*
   	* @method   showDeleteConfirm
   	* @description  Abre el modal para eliminar una variante
   	*
   	**/
   	showDeleteConfirm = (item) => {
  		const tm_nombre = item.nombre;
  		const tm_id = item._id;

			Modal.confirm({
				title: 'Eliminar Variante',
				icon: <ExclamationCircleOutlined />,
				content: '¿Estas seguro de eliminar la variante?',
				okText: 'Continuar',
				okType: 'danger',
				cancelText: 'Cancelar',
				onOk: () => {
					axios({
						method: 'delete',
						url: '/variantes/delete',
						data: { id: tm_id }
			  		})
			  		.then((response) => {
						message.success('Variante eliminado');
				 		this.getVariantes()
					}).catch((error) => {
						console.log("error", error);
						message.error('Error al eliminar');
					})
				}
	  		})
	}
  
	render() {

		const { producto } = this.state

		return (
			<>
				<PageHeader
                    className="site-page-header"
                    title={
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                <span className="ant-page-header-heading-title">
                                    Variantes de {producto.nombre}
                                </span>
                            </Col>
                        </Row>
                    }
                />
                <Content className="admin-content content-bg pd-1">
					<Row className="mb-1">
						<Col xs={24} md={12}>
							{/*<Search
								className="search-bar"
								placeholder="Buscar..."
								enterButton="Buscar"
								onSearch={this.searchData}
							/>*/}
						</Col>
					</Row>
					<List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Variantes" }}
                        dataSource={this.state.variantes}
                        pagination={{
                            onChange: this.handlePageClick,
                            total: this.state.total,
                            pageSize:this.state.limit,
                            current: this.state.page,
                            showSizeChanger: false,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            className:"flex-left",
                            
                        }}
                        

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">
	                                    <Col span={6}  className="flex-left">
	                                        <Text>{item.nombre}</Text>
	                                    </Col>
	                                    <Col span={7}  className="center">
	                                        <Text strong>{item.descripcion}</Text>
	                                    </Col>
	                                    <Col span={5}  className="center">
	                                        <Text strong>{item.categoria_id?.nombre}</Text>
	                                    </Col>
	                                    <Col span={2}  className="center">
	                                        <Switch checked={item.activo} disabled />
	                                    </Col>
	                                    <Col span={4} className="center">
	                                        <Space >                                        

												<Button 
													title="Editar"
													type="secondary" 
													icon={<FormOutlined />}
													onClick={()=>this.setState({modal_visible: true, variante_id: item._id})}
												>
												</Button>
												<Button 
													title="Eliminar"
													type="danger"
													onClick={()=>this.showDeleteConfirm(item)}
													icon={<DeleteOutlined /> }>
												</Button>
	                                          
	                                        </Space>
	                                    </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

				</Content>
				
				<FloatingButton title={'Agregar Producto'} onClick={()=>this.setState({modal_visible: true})}/> 

				<ModalVariantes
					visible={this.state.modal_visible}
					onCancel={()=>{
						this.setState({modal_visible: false, variante_id: undefined})
						this.getVariantes()
					}}
					variante_id={this.state.variante_id}
					producto_id={this.state.producto_id}
				/> 
	 		</>
		)
 	}
}

export default Variantes;