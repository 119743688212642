import React from 'react'
import { Route, Switch } from "react-router-dom";
import Ventas from '../../components/Admin/Compras/Compras';

// componentes

/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function ComprasRouter( props) {
    return (
        <Switch>
            <Route exact path="/admin/compras" render={() => <Ventas {...props} />} />
        </Switch>
    )
}

export default ComprasRouter;
