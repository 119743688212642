import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, Select, DatePicker ,Tabs, Image, Tag, Button, Space, Typography, Card, Popconfirm, List, Avatar, Divider, Form, InputNumber, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { IconBus, IconEye, IconEdit, IconDelete } from "../../Widgets/Iconos";

import '../../../Styles/Global/admin.scss';

//modales

const { RangePicker } = DatePicker;
const { TabPane } = Tabs
const { Header, Content } = Layout;
const { Option } = Select;
const { confirm } = Modal;
const { Title, Text } = Typography

const axios = require("axios").default;
const moment = require('moment');



const colors = ["#00000", "#87d068", "#FA9100"];


class CarritoOrden extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productos: [],
        }
    }

    /**
    * @memberof Cotizaciones
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    **/
    componentDidMount() {
        /* this.props.setTitle('Ventas') */
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
    * @memberof Cotizaciones
    *
    * @method 
    * @description  Carga los datos de la vista
    **/
    updateProducto = (values, index) => {
        values.fecha_entrega_min = values.fechas[0];
        values.fecha_entrega_max = values.fechas[1];
        this.props.updateProducto(values, index)


    }

    /**
    * @memberof Cotizaciones
    *
    * @method deleteProducto
    * @description Elimina un producto de la orden
    **/
    deleteProducto = (orden_detalle_id, index) => {

        if(this.state.productos.length === 1){
            message.warning('No se puede eliminar, mínimo un producto por orden')
            this.setState({loading: false})
            return
        }

        axios.post('/ordenes/producto/delete',{
            orden_detalle_id
        }).then(response => {
            
            this.props.getOrden()

        }).catch(error => {
            console.log("error", error);

        })
    }

    
    render() {

        const { productos } = this.props

        return (
            <>
                <Row className="table-titles">
                    <Col className="center" span={8}>
                        <Text>Producto</Text>
                    </Col>
                    <Col className="center" span={14}>
                        <Text>Fecha de Entrega</Text>
                    </Col>
                    
                </Row>
                <div className="list-productos">
                    {productos.map((producto, index)=> {
                        return <Form
                                onValuesChange={(values, all) => this.updateProducto(all, index)}
                                initialValues={{
                                    ...producto,
                                    fechas: producto.fecha_entrega_min ? [moment(producto.fecha_entrega_min),moment(producto.fecha_entrega_max)] : []
                                }}
                            >
                                <Form.Item name="total" hidden/>
                                <Form.Item name="_id" hidden/>
                                
                            <Row gutter={[8,8]} className="mb-1" justify="center" style={{background: '#f9f9f9', padding: '4px', borderRadius: '4px'}}>
                                <Col className=" flex-left " span={8}>
                                    <Text ellipsis>{producto.variante_id.nombre}</Text>
                                </Col>
                                <Col  span={14} className="">
                                    <Form.Item name="fechas">
                                        <RangePicker 
                                            format="DD-MM-YYYY HH:mm"
                                            showTime={{ format: 'HH:mm' }}
                                            size="small"
                                            className="width-100"/>
                                    </Form.Item>
                                </Col>
                                <Col className="center "span={2}>
                                    <Button
                                        size="small"
                                        title="Eliminar"
                                        type="danger"
                                        onClick={()=>this.deleteProducto(producto._id, index)}
                                        icon={<DeleteOutlined /> }>
                                    </Button>
                                </Col>
                                
                            </Row>
                        </Form>
                    })}
                </div>
                
                <Row align="middle">
                    <Col span={10}>
                        <Text className="titulo-sm">{'Metodo de Pago'} </Text>
                    </Col>
                    <Col className="flex-right" span={14}>
                        <Select 
                            className="clientes" 
                            value={this.props.orden?.metodo_pago}
                            onSelect={(value)=>this.props.onChangeOrden({metodo_pago: value})}
                        >
                            <Option value={0}> Tarjeta</Option>
                            <Option value={1}> Transferencia</Option>
                            <Option value={2}> Efectivo</Option>
                        </Select>
                    </Col>
                </Row>
                <Row className="mt-1" align="middle">
                    <Col span={10}>
                        <Text className="titulo-sm">{'Metodo de Entrega'} </Text>
                    </Col>
                    <Col className="flex-right" span={14}>
                        <Select 
                            className="clientes" 
                            value={this.props.orden?.metodo_entrega}
                            onSelect={(value)=>this.props.onChangeOrden({metodo_entrega: value})}
                        >
                            <Option value={0}> Envío</Option>
                            <Option value={1}> Recolección</Option>
                        </Select>
                    </Col>
                </Row>
                <Divider/>
                
            </>
        )
    }
}

export {
    CarritoOrden
}
