import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout, Typography, } from "antd";

//COMPONENTES
// import Header from '../components/Header/Header';

import SidebarAdmin from '../components/Sidebar/SidebarAdmin';
import { User, SetUser } from '../Hooks/Logged';
import AdminSidebar from "../components/Sidebar/Sidebar";
import { IconArrowBack } from './../components/Widgets/Iconos'
import CustomAvatar from "./../components/Widgets/Avatar/Avatar";
import Header from '../components/Nav/Header'
//ROUTERS
import CRMRouter from "./Admin/CRMRouter";
import RouterVentas from "./Admin/VentasRouter";
import CuentasRouter from "./Admin/CuentasRouter";
import RouterTamanos from "./Admin/TamañosRouter";
import ComprasRouter from "./Admin/ComprasRouter";
import RouterUsuarios from "./Admin/RouterUsuarios";
import RouterDashboard from "./Admin/DashboardRouter";
import RouterProductos from "./Admin/ProductosRouter";
import VariantesRouter from "./Admin/VariantesRouter";
import InvetarioRouter from "./Admin/InventarioRouter";
import RouterCategorias from "./Admin/CategoriasRouter";
import RouterProveedores from "./Admin/ProveedoresRouter";
import CotizacionesRouter from "./Admin/CotizacionesRouter";
import SuscriptoresRouter from "./Admin/SuscriptoresRouter";
import RouterTipoCliente from "./Admin/RouterTipoCliente";
import axios from 'axios';

//css
import '../Styles/Global/admin.scss';
import '../Styles/Global/admin.css';
import '../Styles/Theme/Admin/antd-zeus-admin-theme.css'
import Inventario from "../components/Admin/Inventario/Inventario";
import PreciosRouter from "./Admin/PreciosRouter";

import 'moment/locale/es';

const { Content } = Layout;
const { Text } = Typography;

class AdminRoutes extends Component {

    volver = () => {
        window.history.back();
    }

    static contextType = User

    constructor(props) {
        super(props)
        this.state = {
            back: false,
            responsiveSidebar: false,

            showSearch: false,
            filterSearch: "",
            searching: false,
            title: '...',
            volver: false,
        };
    }

    SetFilterSearch(e) {
        this.setState({ filterSearch: e })
    }

    updateFilterSearch = (ShowFilter) => {
        if (ShowFilter === undefined) { ShowFilter = false }
        this.setState({
            showSearch: ShowFilter,
        })
    };

    /**
    * @memberof AdminRoutes
    *
    *
    * @description Actualiza el titulo del modulo actual
    */
    setTitle = (title, volver = false) => {
        this.setState({ title, volver })
    }

    /**
     * @memberof AdminRoutes
     *
     *
     * @description Cierra sesion
     */
    cerrarSession = () => {
        this.props.setUser(0)
        sessionStorage.clear();
        localStorage.clear()
        axios.defaults.headers.common['Authorization'] = null
    };


    render() {
        const { title, back } = this.state;
        const { updateFilterSearch } = this;
        return (
            <Layout>
                <Header showSearch={this.state.showSearch} setFilterSearch={this.setFilterSearch} />
                <Layout className="content-bg admin-content">
                    <SidebarAdmin />
                    <Content className="">
                        <Switch>
                            <Route exact path="/admin/dashboard" render={(props) => <RouterDashboard  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} setTitle={this.setTitle} />}
                            />

                            <Route path="/admin/proveedores" render={(props) => <RouterProveedores {...props} updateFilterSearch={updateFilterSearch} search={this.state.search} />} />

                            <Route path="/admin/productos" render={(props) => <RouterProductos {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />
                            
                            <Route path="/admin/usuarios" render={(props) => <RouterUsuarios {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />

                            <Route path="/admin/precios" render={(props) => <PreciosRouter {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />
                            
                            <Route path="/admin/inventario" render={(props) => <InvetarioRouter {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />

                            <Route path="/admin/cotizaciones" render={(props) => <CotizacionesRouter {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />

                            <Route path="/admin/compras" render={(props) => <ComprasRouter {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />

                            <Route path="/admin/ventas" render={(props) => <RouterVentas {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />

                            <Route path="/admin/crm" render={(props) => <CRMRouter {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />

                            <Route path="/admin/cuentas" render={(props) => <CuentasRouter {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />

                            <Route path="/admin/tipos" render={(props) => <RouterTipoCliente {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />

                            <Route path="/admin/categorias" render={(props) => <RouterCategorias {...props} updateFilterSearch={this.updateFilterSearch} search={this.state.search} />} />

                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        )

    }
}


export default (props) => <AdminRoutes {...props} setUser={React.useContext(SetUser)} />