import React, { Component } from "react";
import { Layout, Menu } from 'antd';
import '../../Styles/Global/sider.css';
import { useHistory, Link, Redirect } from 'react-router-dom';


import {
    IconDashboard,
    IconUsuarios,
    IconRubros,
    IconVentas,
} from '../Widgets/Iconos.js'


import {
    FullscreenOutlined,
    TagOutlined,
    LogoutOutlined
} from '@ant-design/icons'

const { SubMenu } = Menu;
const { Sider } = Layout;



/**
 *
 *
 * @export
 * @class Admin_Sidebar
 * @extends {Component}
 */
class AdminSidebar extends Component {


    render() {

        return (
            <Sider theme="light" width={300} className="custom-sider sider-width">
                <a href="/"> <img src="/images/logo.svg" className="logo"></img></a>
                <Menu mode="inline" theme="light" style={{ height: '100%', borderRight: 0 }} >
                    <Menu.ItemGroup key="g1" title="NAVEGACIÓN">
                        <Menu.Item key="1" icon={<IconDashboard />}>
                            <Link to={'/admin/dashboard'}>Dashboard</Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.Divider className="group-sider-divider " />
                    <Menu.ItemGroup key="g2" title="AJUSTES">
                        <Menu.Item key="8" icon={<IconUsuarios />}>
                            <Link to={'/admin/usuarios'}>Usuarios</Link>
                        </Menu.Item>
                        <Menu.Item key="tags" icon={<TagOutlined />}>
                            <Link to={'/admin/productos'}>Productos</Link>
                        </Menu.Item>
                        

                        <Menu.Item key="areas" icon={<IconRubros />}>
                            <Link to={'/admin/categorias'}>Categorias</Link>
                        </Menu.Item>
                        <Menu.Item key="4" icon={<FullscreenOutlined />} >
                            <Link to="/admin/tamanos"> Tamaños</Link>
                        </Menu.Item>
                        <Menu.Item key="5" icon={<IconVentas />} >
                            <Link to="/admin/ventas"> Ventas</Link>
                        </Menu.Item>
                        <Menu.Item key="6" icon={<IconVentas />} >
                            <Link to="/admin/suscriptores"> Suscriptores</Link>
                        </Menu.Item>
                    </Menu.ItemGroup>

                </Menu>

            </Sider>
        )
    }
}

export default (props) => <AdminSidebar history={useHistory()} {...props} />
