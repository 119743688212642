
import React from 'react'
import { Spin, Layout, Col, Row, PageHeader, Avatar, Typography, Image, Card, Statistic, Tag } from "antd";
import { VentasSemana } from '../../Widgets/graficas'
import Analytics from '../../Widgets/analytics'
import { UserOutlined, StockOutlined } from "@ant-design/icons";
import { IconSalesGreen, IconGroupPersons, IconOrdenes } from "../../Widgets/Iconos"
import '../../../Styles/Modules/Dashboard/dashboard.scss'
import { FaShoppingCart, FaBoxes } from "react-icons/fa";
import ReactEcharts from "echarts-for-react";


const axios = require('axios')

const { Content } = Layout;
const { Title, Text } = Typography;
const moment = require('moment')


export default class PrecioDetalle extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            actuales:[],
            historial:{},
            producto:{},
            proveedores:{},
            historial:[],
            loading: false,
            ventas_totales: 0,
            ventas_semana: 0,
            total_productos: 0,
            total_usuarios: 0,
            dataValue: {
                "lunes": 0,
                "martes": 0,
                "miercoles": 0,
                "jueves": 0,
                "viernes": 0,
                "sábado": 0,
                "domingo": 0,
            }
        }
    }

    chart = React.createRef()


    /**
     *
     *
     * @memberof Dashboard
     * @description Carga los datos a la vista
     */

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProducto() 
        this.getActuales()
        this.getMinMax()
        this.getProveedores()
        this.getHist()

        let meses = []
        for(let i = 0; i < 6; i++){
            let month  = moment().subtract(i, "month").format('MMMM');
            meses.unshift(month)
            this.setState({meses})
        }


    }


    /**
	* @memberof CRMDetalle
	* @method getProducto
	* @description  Obtiene los datos del producto
	**/
	getProducto = () => {
		this.setState({loading: true})
		axios.get('/productos/get',{
			params:{
				id: this.props.match.params.producto_id
			}
		}).then(response => {
			console.log("getProducto", response.data.data);


			this.setState({
				producto: response.data.data.producto,
                loading: false
			})

		}).catch(error => {
			console.log("error", error);

		})
	}

    /**
	* @memberof CRMDetalle
	* @method getActuales
	* @description  Obtiene los precios actuales de cada variante existente para el producto
	**/
	getActuales = () => {
		this.setState({loading: true})
		axios.get('/precio/get/actuales',{
			params:{
				id: this.props.match.params.producto_id
			}
		}).then(response => {
			console.log("Actuales", response.data.data);
            let data = response.data.data;

            data.sort((a, b) => {
                return a.mostRecent.precio - b.mostRecent.precio;
            });

            console.log("Actuales ordenados de mayor a menor", data)

			this.setState({
				actuales: response.data.data,
                loading: false
			})

            console.log("Test",this.state.actuales[0])

		}).catch(error => {
			console.log("error", error);

		})
	}

    /**
	* @memberof CRMDetalle
	* @method getMinMax
	* @description  Obtiene los datos de minimo y maximo historico
	**/
	getMinMax = () => {
		this.setState({loading: true})
		axios.get('/precio/get/minmax',{
			params:{
				id: this.props.match.params.producto_id
			}
		}).then(response => {
			console.log("Historial", response.data.data[0]);

			this.setState({
				historial: response.data.data[0],
                loading: false
			})

		}).catch(error => {
			console.log("error", error);

		})
	}

    /**
	* @memberof CRMDetalle
	* @method getProveedores
	* @description  Obtiene un listado de proveedores disponibles para el producto
	**/
	getProveedores = () => {
		this.setState({loading: true})
		axios.get('/precio/get/proveedores',{
			params:{
				id: this.props.match.params.producto_id
			}
		}).then(response => {
			console.log("Proveedores", response.data.data);

			this.setState({
				proveedores: response.data.data,
                loading: false
			})

		}).catch(error => {
			console.log("error", error);

		})
	}

    /**
	* @memberof CRMDetalle
	* @method getMinMax
	* @description  Obtiene los datos para la grafica de precios
	**/
	getHist = () => {
		this.setState({loading: true})
		axios.get('/precio/get/historial',{
			params:{
				id: this.props.match.params.producto_id
			}
		}).then(response => {
			console.log("Historial", response.data.data);

			this.setState({
				chartData: response.data.data.precios.map(e => ({
                    name: e.info.sku_variante,
                    type: 'line',
                    data: e.montos,
                })),
                loading: false
			})

		}).catch(error => {
			console.log("error", error);

		})
	}





    render() {

        return (
            <Spin spinning={this.state.loading} >
                <PageHeader
                    className="site-page-header"
                    title={
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                <span className="ant-page-header-heading-title">
                                    Precios {this.state.producto.nombre}
                                </span>
                            </Col>
                        </Row>
                    }
                />

                
                <Content className='producto'>
                    <Row className="dashboard width-100  pd-1" gutter={[8, 8]}  >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <Row gutter={[16, 16]} className="width-100">
                                <Col span={24} xs={24} lg={8} xl={8}>
                                    <Card className="card-container">
                                        <Col>
                                            <Row>
                                                <Card.Meta
                                                title="Producto"
                                                />
                                            </Row>
                                            <Row>
                                                <Text className='label mt-1'>Nombre</Text>
                                            </Row>
                                            <Row>
                                                <Text className='info mb-1'>{this.state.producto.nombre}</Text>
                                            </Row>
                                            <Row>
                                                <Text className='label'>Fabricante</Text>
                                            </Row>
                                            <Row>
                                                <Text className='info mb-1'>{this.state.producto?.sku_fabricante}</Text>
                                            </Row>
                                            <Row>
                                                <Text className='label'>Grupo Manantial</Text>
                                            </Row>
                                            <Row>
                                                <Text className='info'>{this.state.producto?.sku_manantial}</Text>
                                            </Row>
                                        </Col>
                                    </Card>
                                </Col>
                                <Col span={24} xs={24} lg={8} xl={8}>
                                    <Card className="card-container">
                                            <Col>
                                                <Row>
                                                    <Card.Meta
                                                    title="Proveedores"
                                                    />
                                                </Row>
                                                <Row className='mt-1 mb-1' justify='space-between' align='middle'>
                                                    <Col>
                                                        <Row>
                                                            <Text className='label'>Mejor Precio Actual</Text>
                                                        </Row>
                                                        <Row>
                                                            <Text className='mejor'>{`$ ${this.state.actuales[0]?.mostRecent.precio} MXN`}</Text>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Text className='proveedor-label'>Proveedor</Text>
                                                        </Row>
                                                        <Row>
                                                            <Text className='proveedor'>{this.state.actuales[0]?.proveedor_variante.proveedor.nombre}</Text>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className='mb-1' justify='space-between' align='middle'>
                                                    <Col>
                                                        <Row>
                                                            <Text className='label'>Peor Precio Actual</Text>
                                                        </Row>
                                                        <Row>
                                                            <Text className='peor'>{`$ ${this.state.actuales[this.state.actuales?.length-1]?.mostRecent.precio} MXN`}</Text>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Text className='proveedor-label'>Proveedor</Text>
                                                        </Row>
                                                        <Row>
                                                            <Text className='proveedor'>{this.state.actuales[this.state.actuales?.length-1]?.proveedor_variante.proveedor.nombre}</Text>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Text className='label'>Numero de Proveedores</Text>
                                                </Row>
                                                <Row>
                                                    <Text className='info-grey'>{this.state.proveedores.length}</Text>
                                                </Row>
                                            </Col>
                                        </Card>
                                </Col>
                                <Col span={24} xs={24} lg={8} xl={8}>
                                <Card className="card-container">
                                        <Col>
                                            <Row>
                                                <Card.Meta
                                                title="Historial"
                                                />
                                            </Row>
                                            <Row className='mt-1 mb-1' justify='space-between' align='middle'>
                                                <Col>
                                                    <Row>
                                                        <Text className='label'>Precio más bajo</Text>
                                                    </Row>
                                                    <Row>
                                                        <Text className='info-grey'>{`$ ${this.state.historial?.minimo?.precio} MXN`}</Text>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Text className='proveedor-label'>Proveedor</Text>
                                                    </Row>
                                                    <Row>
                                                        <Text className='proveedor'>{this.state.historial?.minimo?.proveedor_variante.proveedor.nombre}</Text>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row justify='space-between' align='middle'>
                                                <Col>
                                                    <Row>
                                                        <Text className='label'>Precio Más Alto</Text>
                                                    </Row>
                                                    <Row>
                                                        <Text className='info-grey'>{`$ ${this.state.historial?.maximo?.precio} MXN`}</Text>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Text className='proveedor-label'>Proveedor</Text>
                                                    </Row>
                                                    <Row>
                                                        <Text className='proveedor'>{this.state.historial?.maximo?.proveedor_variante.proveedor.nombre}</Text>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                    </Card>
                                </Col>
                                <Col span={24}  >
                                    <Card className="card-container "  >
                                        <Card.Meta
                                            title="Precios Historicos"
                                        />
                                        <div>
                                            <ReactEcharts 
                                                ref={this.chart}
                                                notMerge={true}
                                                style={{ height: 400 , width:'auto', maxWidth: '900px', margin: 'auto'}}
                                                option = {{
                                                    tooltip: {
                                                        trigger: 'axis',
                                                        formatter: (series => {
                                                            let tooltip = '';
                                                            const [primeraSerie] = series;
                                                            const titulo = primeraSerie.axisValue
                                                            tooltip += `<div>${titulo}</div>`;

                                                            series.forEach((s) => {
                                                                tooltip += `<div>${s?.marker} ${s?.seriesName}: $ ${s?.value?.toMoney(true)} MXN</div>`
                                                            })

                                                            return tooltip
                                                        })
                                                    },
                                                    grid: {
                                                        left: '3%',
                                                        right: '4%',
                                                        bottom: '3%',
                                                        containLabel: true
                                                    },
                                                    xAxis: {
                                                        type: 'category',
                                                        boundaryGap: false,
                                                        data: this.state.meses
                                                    },
                                                    yAxis: {
                                                        type: 'value'
                                                    },
                                                    series:this.state.chartData 
                                                }} 
                                            />
                                        </div>
                                    </Card>
                                </Col>


                            </Row>
                        </Col>
                    </Row >
                </Content >
            </Spin >
        )
    }
}