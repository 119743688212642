import React, {  Component } from 'react';
import { Col, Row,  Input,Button, Steps, Form, Typography,  Card, Radio, InputNumber } from 'antd';


import Busqueda from '../../Widgets/Busqueda';
import PhoneInput from '../../Widgets/Inputs/PhoneInput'
import { User } from '../../../Hooks/Logged'
import '../../../Styles/Modules/ShoppingCart/direcciones.scss';

import axios from 'axios';


const { Step } = Steps;
const { Title, Paragraph } = Typography;

/**
 *
 *
 * @param {*} props
 * @return {*} 
 * @name Direcciones
 * @description Step de direcciones para el carrito de compras
 */


export default class Direcciones extends Component {
    static contextType = User;

    state = {
        direcciones: []
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.obtenerDirecciones()

    }


    obtenerDirecciones = () => {
        axios.get('/usuarios/direcciones')
            .then(data => {
                console.log('XXXX', data.data.data)
                this.setState({ direcciones: [...data.data.data] })
            })
            .catch(response => {
                console.log(response)
            })
    }

    onAddressSave = (values) => {
        axios.post('/usuarios/direccion/add', values)
            .then(({ data }) => {

                this.obtenerDirecciones()
            })
            .catch(({ response }) => {
                console.log(response)
            })
    }



    formRef = React.createRef()
    formRefNewDireccion = React.createRef()


    render() {

        let user = this.context;

        const { direcciones } = this.state

        user = !(user == 0 || user == null || user == undefined)


        return (
            <Col span={24} className="direcciones">
                {user ? <Form
                    ref={this.formRef}
                    layout="vertical"
                    name="direcciones">
                    <Title level={5} className="direcciones-title pd-1">Direcciones guardadas</Title>
                    <Row className="direcciones-section pd-1">
                        <Col lg={24}>
                            <Form.Item name="direccion" rules={[{ required: true, message: "Necesitas seleccionar una direccion para continuar" }]}>
                                <Radio.Group className="direcciones-radio-group">
                                    {direcciones.map(direccion => {
                                        const { _id, nombre, codigo_postal, localization, notas } = direccion
                                        console.log("Direccion",direccion);
                                        return <Radio
                                            className="direcciones-radio"
                                            value={_id}
                                        >
                                            <Card>
                                                <Title level={5}>{nombre} <small>{codigo_postal}</small></Title>
                                                <Paragraph>{localization.place_name}</Paragraph>
                                                <Paragraph>{notas}<br />{localization?.text_es}</Paragraph>
                                            </Card>
                                        </Radio>
                                    })}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form> : null}


                {user ? <Title level={5} className="pd-1">Direccion Nueva</Title> : null}


                <Form
                    ref={this.formRefNewDireccion}
                    layout="vertical"
                    name="direcciones"
                    className="pd-1"
                    onFinish={this.onAddressSave}>
                    <Row gutter={[24, 24]}>
                        <Col lg={24}>
                            <Form.Item
                                label="Nombre Completo"
                                name="nombre"
                            >
                                <Input size="large" />
                            </Form.Item>
                        </Col>

                        {(!user) ? [
                            <Col lg={12}>
                                <Form.Item
                                    label="Correo electronico"
                                    name="email"
                                    className="width-100"

                                    rules={[
                                        { required: true, message: "Por favor, introduce tu correo electronico" },
                                        { required: true, type: "email", message: "¡El correo electronico enviado no es valido!" }
                                    ]}
                                >
                                    <Input size="large" />
                                </Form.Item>
                            </Col>,
                            <Col lg={12}>
                                <Form.Item
                                    label="Numero de teléfono"
                                    name="telefono"
                                >
                                    <PhoneInput/>
                                </Form.Item>
                            </Col>
                        ] : null}
                        <Col lg={4}>
                            <Form.Item
                                label="Código Postal"
                                name="codigo_postal"
                                className="width-100"
                            >
                                <InputNumber size="large" className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col lg={20}>
                            <Form.Item
                                label={<Paragraph className='direcciones-form-label'>Dirección <small>Solo hacemos entregas/ envios al Estado de Mexico y CDMX</small></Paragraph>}
                                name="localization"

                            >
                                <Busqueda />
                            </Form.Item>
                        </Col>
                        <Col lg={24}>
                            <Form.Item
                                label="Notas"
                                name="notas"
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Col>
                        <Col lg={24}>
                            <Form.Item
                                label="Especificaciones de envío"
                                name="especificaciones"
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Col>

                        {user ? <Col lg={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ float: "right" }}>Agregar direccion</Button>
                            </Form.Item>
                        </Col> : null}

                    </Row>
                </Form>
            </Col>
        )
    }


}