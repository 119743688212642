import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, Tabs, Image, Tag, Button, Space, Typography, Card, Popconfirm, List, Avatar, InputNumber, Form,Pagination, Spin,Tooltip} from 'antd';
import { FilePdfFilled, DeleteOutlined } from '@ant-design/icons';
import { IconBus, IconEye, IconEdit, IconDelete } from "../../Widgets/Iconos";

import '../../../Styles/Global/admin.scss';
import '../../../Styles/Modules/cotizador.css';

//modales


const { TabPane } = Tabs
const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;
const { Title, Text } = Typography

const axios = require("axios").default;
const moment = require('moment');

/**
 *
 *
 * @class ProductosCotizacion
 * @extends {Component}
 */
class ProductosCotizacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            categorias: [],
            productos: [],
            categoria_id: null,
            loading: false,

            page: 1,
            limit: 10,
            total: 0
        }
    }

    /**
    * @memberof ProductosCotizacion
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    **/
    componentDidMount() {
        /* this.props.setTitle('Ventas') */
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getCategorias()
    }

    /**
    * @memberof ProductosCotizacion
    *
    * @method getCategorias
    * @description  Obtiene las categorias de productos
    **/
    getCategorias = () => {
        axios.get('/categorias',{
            params: {
                paginate: false
            }
        }).then(response => {
            this.setState({
                categorias: response.data.data,
                //categoria_id: response.data.data.length > 0 ? response.data.data[0]._id : null
            })
        }).then(()=> {
            this.getProductos()
        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener las categorias.')

        })
    }

    /**
    * @memberof ProductosCotizacion
    *
    * @method getProductos
    * @description  Obtiene los productos de una categoria
    **/
    getProductos = (page = this.state.page) => {
        this.setState({loading: true})
        axios.get('/productos',{
            params: {
                page,
                categoria_id: this.state.categoria_id,
                activo: true, //productos y variantes activos
                con_variantes: true ,//que contengan por lo menos una variante
                tienda: true, //que tengan una existencia
            }
        }).then(response => {


            this.setState({
                productos: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
            })
            
        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener los productos.')

        }).finally(()=>this.setState({loading: false}))
    }

    /**
    * @memberof ProductosCotizacion
    *
    * @method onChangeTabs
    * @description  Busca los productos de una categoria seleccionada
    **/
    onChangeTabs = (categoria_id) => {
        this.setState({
            categoria_id: categoria_id === 'null' ? null : categoria_id
        },()=>{
            this.getProductos(1)
        })
    }

    render() {

        const { categorias, productos } = this.state

        const responsive = {
            xs: 24,
            sm: 12,
            ms: 12,
            xl: 8,
            xxl: 6,
        }

       // let style = this.props.size === 'sm' ? {maxHeight: '650px', overflow: 'hidden', overflowY: 'scroll', display: 'flex', flexFlow: 'row wrap'} : 

        return (
            <> 
                <Tabs 
                    onChange={this.onChangeTabs}
                    className="tabs-productos"
                >   
                    <TabPane tab={'Todas'} key={null}>
                        
                    </TabPane>
                    {categorias.map((categoria, index)=> (
                        <TabPane tab={`${categoria.nombre}`} key={categoria._id}>
                        </TabPane>
                    ))}
                </Tabs>
                <Spin spinning={this.state.loading}>
                    <Row gutter={[20,20]} className="tabs-productos">
                        <Col span={24} className="center">
                            {productos.length === 0 ? <Text className="text-gray">No hay productos disponibles</Text> : null}
                        </Col>
                        <div className={`ant-row ${this.props.size === 'sm' ? 'scroll-productos' : ''}`} >
                            {productos.map(item => {
                                
                                let url = item.imagenes.length > 0 ? `${axios.defaults.baseURL}/upload/${item.imagenes[0]}` : '/images/img-video.png'

                                let r = this.props.responsive ? {...this.props.responsive} : {...responsive}
                                
                                return <Col {...r} className="grid">
                                    <Card 
                                        className="producto-card"
                                       
                                    >
                                        <div className="image-container">
                                            <div className="image-producto" style={{backgroundImage: `url(${url})`}}/>
                                        </div>
                                        <p>{item.nombre}</p>
                                        <p>Disponibles {item.existencias}</p>
                                        {item.variantes.map(variante => <Tooltip placement="topRight" title={variante.nombre}><Tag
                                            className="tag-producto"
                                            color="orange" 
                                            style={{margin: '0 4px 4px 0'}}
                                            onClick={()=>this.props.openModal(variante)}
                                        > {variante.nombre}</Tag></Tooltip>)}
                                    </Card>
                                </Col>
                            })}
                        </div>
                    </Row>
                </Spin>
                <Row className="mt-2 mb-3">
                    <Col span={12}>
                        <Pagination 
                            current={this.state.page}
                            pageSize={10}
                            total={this.state.total}
                            onChange={this.getProductos}
                        />
                    </Col>
                </Row>

            </>
        )
    }
}

class CarritoCotizacion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orden_id: null,
            productos: [],
        }
    }

    /**
    * @memberof Cotizaciones
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    **/
    componentDidMount() {
        /* this.props.setTitle('Ventas') */
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

    }


    

    /**
    * @memberof Cotizaciones
    *
    * @method deleteProducto
    * @description Elimina un producto de la orden
    **/
    deleteProducto = (orden_detalle_id, index) => {
        axios.post('/ordenes/producto/delete',{
            orden_detalle_id
        }).then(response => {
            
            this.props.getOrden()

        }).catch(error => {
            console.log("error", error);

        })
    }


   
    
    render() {

        const { productos, tipo } = this.props

        return (
            <>
                <Row className="table-titles">
                    <Col className="center" span={12}>
                        <Text>Producto</Text>
                    </Col>
                    <Col className="center" span={6}>
                        <Text>Cantidad</Text>
                    </Col>
                    <Col className="center" span={6}>
                        <Text>{tipo === 1 ? 'Precio' : 'Costo'}</Text>
                    </Col>
                    
                </Row>
                <div className="list-productos">
                    {productos.map((producto, index)=> {
                        return <Form
                                onValuesChange={(values, all) => this.props.updateProducto(all, index)}
                                initialValues={{...producto}}
                            >
                                <Form.Item name="total" hidden/>
                                <Form.Item name="_id" hidden/>
                                
                            <Row gutter={[8,8]} className="mb-1" style={{background: '#f9f9f9', padding: '4px', borderRadius: '8px'}}>
                                <Col className=" flex-left " span={12}>
                                    <Text ellipsis>{producto.variante_id.nombre}</Text>
                                </Col>
                                <Col className="" span={6}>
                                    <Form.Item name="cantidad">
                                        <InputNumber min={1} precision={0} className="width-100"/>
                                    </Form.Item>
                                </Col>
                                {/*Cotizacion - precio*/}
                                {tipo === 1 ? <Col className="" span={6}>
                                    <Form.Item name="precio">
                                        <InputNumber min={1} precision={2} className="width-100"/>
                                    </Form.Item>
                                </Col> : <Col className="" span={6}>
                                    <Form.Item name="costo">
                                        <InputNumber min={1} precision={2} className="width-100"/>
                                    </Form.Item>
                                </Col> }
                                <Col className="flex-left" span={12}>
                                    {tipo === 1 ? <Text>{producto.margen_utilidad.toUtilidad()}</Text> : '' } 
                                </Col>
                                <Col className="flex-right" span={12}>
                                    <Button
                                        size="small"
                                        title="Eliminar"
                                        type="danger"
                                        onClick={()=>this.deleteProducto(producto._id, index)}
                                        icon={<DeleteOutlined /> }>
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    })}
                </div>
            </>
        )
    }
}

export {
    ProductosCotizacion,
    CarritoCotizacion
}
