import React from 'react'
import { Route, Switch } from "react-router-dom";
import CRM from '../../components/Admin/CRM/CRM';
import CRMDetalle from '../../components/Admin/CRM/CRMDetalle';

// componentes

/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterVentas( props) {
    return (
        <Switch>
            <Route exact path="/admin/crm" render={() => <CRM {...props} />} />
            <Route exact path="/admin/crm/detalle/:usuario_id" render={(props_p) => <CRMDetalle {...props} {...props_p}/>} />
        </Switch>
    )
}

export default RouterVentas;
