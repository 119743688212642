import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, InputNumber } from 'antd';

import CustomAvatar from "../../Widgets/Avatar/Avatar";

const axios = require('axios').default;
const { Option } = Select;
const { Title, Text } = Typography;
const { TextArea } = Input;


/**
 *
 *
 * @class ModalCompraDetalle
 * @extends {React.Component}
 * @description Formulario de categorias
 */
class ModalCompraDetalle extends Component {


    formModalCategoria = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            compra: {},
            costo: 0,
            total: 0,

        }
    }


    /**
     * @methodOf ModalCompraDetalle
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        
        if(this.props.compra_id){
            this.getCompra()
        }
    }

    /**
     * @methodOf ModalCompraDetalle
     *
     * @function getCompra
     * @description Obtiene la informacion de una compra
     *
     * */
    getCompra = () => {
        axios.get('/compras/get',{
            params:{
                compra_id: this.props.compra_id
            }
        }).then(response => {
            console.log("response", response.data);
            this.setState({compra: response.data.data})

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la información')
        })
    }






    render() {

        const { loading, compra } = this.state

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{this.props.compra_id ? 'Detalle' : 'Crear'} Compra</Title>
                <Form 
                    layout="vertical" 
                    ref={this.formModalCategoria} 
                    onFinish={()=>this.props.onCancel()} 
                >

                    <Row gutter={[16,16]} justify="center">
                        <Col xs={22}>
                            <Form.Item
                                
                                label={<Text strong style={{fontSize: '16px'}}>Producto:</Text>}
                            >   
                                <div className="flex-left-column">
                                    <Text>{compra.producto_id?.nombre}</Text>
                                    <small className="text-gray">{compra.producto_id?.sku_manantial}</small>
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={22}>
                            <Form.Item
                                
                                label={<Text strong style={{fontSize: '16px'}}>Variante:</Text>}
                            >   
                                <div className="flex-left-column">
                                    <Text>{compra.variante_id?.nombre}</Text>
                                    <small className="text-gray">{compra.variante_id?.descripcion}</small>
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={22}>
                            <Form.Item
                                
                                label={<Text strong style={{fontSize: '16px'}}>Proveedor:</Text>}
                            >   
                                <div className="flex-left-column">
                                    <Text>{compra.proveedor_variante_id?.proveedor_id?.nombre}</Text>
                                    <small className="text-gray">{compra.proveedor_variante_id?.proveedor_id?.razon_social}</small>
                                </div>
                            </Form.Item>
                        </Col>


                        <Col xs={11}>
                            <Form.Item
                                label={<Text strong style={{fontSize: '16px'}}>Costo Unitario:</Text>}
                            >
                                <Text>$ {compra.costo?.toMoney()} mxn</Text>
                            </Form.Item>
                        </Col> 
                        <Col xs={11}>
                            <Form.Item
                                label={<Text strong style={{fontSize: '16px'}}>Cantidad:</Text>}
                            >
                                <Text>{compra.cantidad}</Text>
                            </Form.Item>
                        </Col> 
                        <Col xs={11}>
                            <Form.Item
                                label={<Text strong style={{fontSize: '16px'}}>Total:</Text>}
                            >
                                <Text>$ {compra.total?.toMoney()} mxn</Text>
                            </Form.Item>
                        </Col> 
                        <Col xs={11}>
                            <Form.Item
                                label={<Text strong style={{fontSize: '16px'}}>Creado por:</Text>}
                            >
                                <CustomAvatar 
                                    name={compra.usuario_id?.nombre + ' ' + compra.usuario_id?.apellido} 
                                    image={compra.usuario_id?.avatar}  
                                    color='#9664FF'
                                />
                                <Text className="ml-1" strong>{compra.usuario_id?.nombre + ' ' + compra.usuario_id?.apellido} </Text>
                            </Form.Item>
                        </Col> 
                        <Col xs={22}>
                            <Form.Item
                                label={<Text strong style={{fontSize: '16px'}}>¿Actualizo Inentario?</Text>}
                            >
                                <Text className="ml-1" strong>{compra.actualizar_inventario ? 'SI' : 'NO'} </Text>
                            </Form.Item>
                        </Col> 

                    </Row>

                    <Row justify="center" align="middle" >
                        <Col span={24} className="flex-column mt-3">
                            <Form.Item>
                                <Button htmlType="submit" type="primary">
                                    Cerrar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {

    let { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <ModalCompraDetalle {...props} />
        </Modal>
    )
}