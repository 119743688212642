import React, { Component, createRef, useEffect } from "react";
import { Avatar, Button, Col, DatePicker, Form, Input, InputNumber, List, message, Modal, Row, Select, Spin, Statistic, Typography, Upload, Checkbox, Tooltip, Popover, Tag, Space, Card } from 'antd';
import { UploadOutlined, QuestionCircleOutlined, FileExcelOutlined } from "@ant-design/icons";
import { Redirect, Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from 'moment'
import 'moment/locale/es';
//componentes

const axios = require('axios').default;
moment.locale('es');
const { Option } = Select;
const { Title, Text } = Typography;

function Uploader(props) {
    const {
        children,
        value = props.fileList,
        onChange = () => { },
        one = false
    } = props
    let [fileList, setFileList] = React.useState(value);
    const updateTriggerChange = (currentFileList) => {
        if (onChange)
            onChange(currentFileList);

        setFileList(currentFileList);
    }

    const triggerChange = (changedValue) => {

        let currentFileList = []
        if (fileList)
            if (one)
                currentFileList = [changedValue]
            else
                currentFileList = [...fileList, changedValue]
        else
            currentFileList = [changedValue]

        updateTriggerChange(currentFileList)
    }

    useEffect(() => {
        if (fileList?.length != value?.length)
            setFileList(value)
    }, [value])

    return <Upload
        {...props}
        fileList={fileList}
        customRequest={(custom) => {
            let { file } = custom
            file.status = "done"
            triggerChange(file);
        }}
        onRemove={deleteElement => {
            console.log('e', deleteElement)
            console.log('fileList', fileList.filter(e => {
                // console.log('e.uid', e.uid);
                // console.log('e.uid', deleteElement.uid);
                return String(e.uid) != String(deleteElement.uid)
            }))
            updateTriggerChange(fileList.filter(e => {

                console.log('e.uid', e.uid);
                console.log('deleteElement.uid', deleteElement.uid);
                console.log('deleteElement.uid', e.uid == deleteElement.uid);
                return e.uid != deleteElement.uid
            }))
        }}
    >
        {children}
    </Upload>
}


function FolderUploadComponent(props) {

    const {
        children,
        value = props.fileList,
        onChange = () => { },
    } = props

    let [fileList, setFileList] = React.useState(value);

    const updateTriggerChange = (currentFileList) => {
        if (onChange)
            onChange(currentFileList);

        setFileList(currentFileList);
    }


    useEffect(() => {
        if (fileList?.length != value?.length)
            setFileList(value)
    }, [value])

    const handleFileChange = (e) => {
        // console.log('e', e);
        updateTriggerChange(e.fileList.map(e => e.originFileObj))
    }

    return <Upload
        {...props}
        fileList={[]}
        webkitdirectory="true"
        mozdirectory="true"
        msdirectory="true"
        odirectory="true"
        directory="true"
        multiple
        onChange={handleFileChange}
        customRequest={(custom) => {
            let { file } = custom
            file.status = "done"
        }}
    >
        {children}
        {(fileList?.length > 0) && <Popover content={
            <Card>
                <Title level={5} style={{ marginBottom: '1em' }}>Lista de Archivos a Cargar</Title>
                <Row style={{ maxWidth: 250 }} gutter={[8, 8]}>
                    {fileList.map(e => <Tag>{e.name}</Tag>)}
                </Row>
            </Card>
        }>
            <Text>{(fileList?.length || 0)} archivos cargados</Text>
        </Popover>}
    </Upload>
}

class ModalProductosMultiples extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        },

    };

    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            updateChecker: false,
        }
    }

    //Referencia de form dentro del modal.
    modalTransaccion = createRef();

    /**
     * @memberof ModalTran
     * @method componentDidMount
     * @description Se ejecuta al finalizar el render de la vista.
     */
    componentDidMount() { axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token'); }


    onFinish = values => {
        console.log('va', values);

        const formData = new FormData()// formData.append("userpic", myFileInput.files[0], "chris.jpg");
        values.csv?.map(e => formData.append("csv", e, e.name))
        values.imagenes?.map(e => formData.append("imagenes", e, e.name))


        this.setState({ spinning: true })
        message.info("Estamos creando los productos, por favor espere")

        axios.post('/productos/multiple', formData)
            .then(e => {

                message.success("Estamos creando los productos, por favor espere")
            })
            .catch(e => {

                message.error("No se pudieron crear todos los archivos, creando reporte.")
            })

    }

    render() {
        const { modalVisible, accionModal, registroId } = this.props;
        const { modalTransaccion, normFile } = this;

        var titulo = (registroId) ? "Editar Transacción" : "Nueva Transacción";
        const gutter = [15, 45]



        return (
            <Spin spinning={this.state.spinning}>
                <Form
                    layout="vertical"
                    ref={this.modalTransaccion}
                    className="frm-transacciones"
                    name="formulario-transacciones"
                    onFinish={this.onFinish}
                >
                    <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                        <List
                            style={{ justifyContent: "center" }}
                            align="center"
                            size="small"
                            header={<div>Toma en cuenta las siguientes consideraciones.</div>}
                            footer={<div>Descarga el template CSV para subir múltiples transacciones.</div>}
                            dataSource={[
                                <div style={{ justifyContent: 'center', width: '100%' }}>- El formato de fecha en dd/mm/aaaa</div>,
                                <div style={{ justifyContent: 'center', width: '100%' }}>- Disminuir el uso de acentos, o evitarlo para prevenir una mala lectura</div>,
                                <div style={{ justifyContent: 'center', width: '100%' }}>- Eliminar el formato de moneda ($123)</div>,
                                <div style={{ justifyContent: 'center', width: '100%' }}>- Asegurarse que el dato esté escrito correctamente  igual que en el sistema (incluyendo mayúsculas y acentos)</div>]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />

                    </Row>
                    <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                        <label> Una vez que hayas guardado tu archivo CSV con las transacciones, súbelo aqui. </label>
                    </Row>
                    <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                    <a href="/template.csv" target="_blank">
                        <FileExcelOutlined style={{ fontSize: 36}} /> <Text>Descargar archivo de Excel</Text>
                    </a>
                    </Row>

                    <Row
                        style={{ textAlign: "center" }}
                    >
                        <Col span={24} className="colDocsComprobantes">
                            <Form.Item
                                name='csv'

                                rules={[
                                    {
                                        validator: async (_, values) => {
                                            if (!values || values.length < 1) {
                                                return Promise.reject(new Error('Debe ingresar el archivo CSV'));
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Uploader
                                    accept="csv"
                                    one={true}
                                >
                                    <Button block style={{ minWidth: 230 }}>
                                        <UploadOutlined /> Cargar el Archivo CSV
                                    </Button>
                                </Uploader>
                            </Form.Item>
                        </Col>
                        <Col span={24} className="colDocsComprobantes mt-1">
                            <Form.Item name='imagenes'>
                                <FolderUploadComponent
                                    style={{ width: "100%", }}
                                >
                                    <Button block style={{ minWidth: 250 }}>
                                        <UploadOutlined /> Cargar todas las Imagenes
                                    </Button>
                                </FolderUploadComponent>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ width: "100%", marginTop: '1em' }} align="center">
                        <Button htmlType="submit" type="primary" loading={this.state.loading}>Guardar</Button>
                    </Row>
                </Form>
            </Spin>
        )
    }
}


export default function ModalProductosMultiplesWrapper(props) {

    const { visible, onCancel } = props

    return <Modal
        title="Subir Multiples Archivos"
        destroyOnClose
        visible={visible}
        onCancel={onCancel}
        okButtonProps={{ style: { display: 'none' } }}
    >
        <ModalProductosMultiples {...props} />
    </Modal>
}
