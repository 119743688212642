import React, { Component } from "react";

import { Row, Col, Typography, Card, List, Empty, Avatar } from 'antd';

import {  User } from "../../../Hooks/Logged"
import axios from "axios";
const moment = require('moment');
moment.locale('es');

const { Title, Text, } = Typography

/**
 * @export
 * @class Pedidos
 * @extends {Component}
 * @description Pedidos del usuario
 */
export default class Pedidos extends Component {
    static contextType = User;
    constructor(props) {
        super(props);
        this.state = {
            pedidos: [],
            loading: false,
            user:null
        };
    }


    componentDidMount() {
        this.getUserPerdidos()
    }

 



    getUserPerdidos = async () =>{

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        axios.get('/ventas/history')
        .then(result=>{
            console.log('Resultados',result.data.data)
            this.setState({
                pedidos:result.data.data
            })
        })
        .catch(error=>{

            console.log('error', error)
        })
    }


    /**
     * @memberof Pedidos
     * @method renderEstatus
     * @description  Render para visualizar el estatus de un pedido
     * @param values (est)
     * Es el identificador del estatussz
     * @returns response (array)
     **/
    renderEstatus = (est) => {
        console.log(est)
        let estatus = {
            1: 'En proceso',
            2: 'En camino',
            3: 'Entregado'
        }

        let clase = {
            1: 'est-proceso',
            2: 'est-transito',
            3: 'est-entregado'
        }

        return <Text className={"txt-estatus " + clase[est]}> {estatus[est]}</Text>
    }



    render() {
        return (
            <Row className="w-100 Pedidos ant-row-center" justify="center" >

                <List
                    size="large"
                    dataSource={this.state.pedidos}
                    locale={{
                        emptyText: <Empty image="/images/stock-empty.svg" 
                        imageStyle={{
                            height: 300,
                          }}
                          description={
                            <span>
                                No se encontraron ordenes
                            </span>
                        } />
                    }}
                    className="list-pedidos"

                    loading={this.state.loading}
                    renderItem={(item, index) =>
                        <List.Item className="list-pedidos-item">
                            <Row className="w-100 row-pedido">
                                <Col xs={24} md={6} lg={4} xl={3} >
                                    <img src={process.env.REACT_APP_WEB_SERVICE +'/upload/'+ item?.productos[0]?.imagenes[0]}  className="avatar-pedido" shape="square" size="large" />
                                </Col>
                                <Col xs={24} md={17} lg={20} xl={6} >
                                    <Title className="orden-pedido" level={3} >Orden #{item?.order_id} </Title>
                                    <Title className="monto-pedido" > $ {parseFloat(item.total).toMoney()} </Title>
                                    <Text className="guia-pedido" >Numero de guía: {item?.numero_guia}</Text>
                                </Col>
                                <Col xs={24} md={9} xl={5} >
                                    <Title className="ttl-fecha-pedido" level={5}>  Fecha de orden </Title>
                                    <Text className="txt-fecha-pedido"> {moment(item.createdAt).format('MMM DD YYYY')}</Text>
                                </Col>
                                <Col xs={24} md={9} xl={5} >
                                    <Title className="ttl-fecha-pedido" level={5}>  Fecha de entrega </Title>
                                    <Text className="txt-fecha-pedido"> {(item?.guia_envio)?"Fedex":"Pendiente"} </Text>
                                </Col>
                                <Col xs={24} md={6} xl={5} >
                                    {this.renderEstatus(item.status)}
                                </Col>

                            </Row>



                        </List.Item>}
                />
            </Row>
        )
    }

}