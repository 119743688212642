import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


String.prototype.toMoney = function (toFixed = true, fractionDigits = 2) {
    if (!toFixed)
        return parseFloat(this).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    else
        return parseFloat(this).toFixed(fractionDigits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

Number.prototype.toMoney= function (fractionDigits = 2) {
    return this.toFixed(fractionDigits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

Number.prototype.toMoney2= function (fractionDigits = 2) {
    try{
        if(this){
            var r = this.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
            return parseFloat(r)
        }
        else
            return 0.00
    }catch(error){
        return 0.00
    }
}

Number.prototype.toUtilidad= function () {
    
    if(this <= 0)
        return <strong style={{color: '#FF560E'}}>{this.toMoney()} %</strong>
    else
        return <strong style={{color: '#2CD11E'}}>{this.toMoney()} %</strong>
}


FormData.prototype.appendMultiple = function (values) {
    for (const name in values) {
      if (values[name])
        if ("File" in window && values[name] instanceof File)
          this.append(name, values[name], values[name].name);
        else
          this.append(name, values[name]);
    }
  };

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
