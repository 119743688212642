

import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, List, Switch, InputNumber, Upload } from 'antd';

import { FileImageOutlined, UploadOutlined } from '@ant-design/icons';

// import PropTypes from "prop-types";
// import Logged from '../../../Hooks/Logged';
// import CustomAvatar from "../../Widgets/Avatar/Avatar";
import { Uploader, ImagePreviewer } from "../../Widgets/Uploaders";


const axios = require('axios').default;
const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;
const { Dragger } = Upload;


/**
 *
 *
 * @class FormVariantes
 * @extends {React.Component}
 * @description Formulario de categorias
 */
class FormVariantes extends Component {


	formModalVariante = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			loadingImage: false,
			form: {},

			id: null,
			image: null,
			negocios: [],
			proveedores: [],

		}
	}


	/**
	 * @methodOf FormVariantes
	 *
	 * @function componentDidUpdate
	 * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
	 *
	 * */
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getTamanos();
		this.getProveedores();
		if (this.props.variante_id) {
			this.getData()
		}
	}

	/**
	* @memberof FormVariantes
	*
	* @method getTamanos
	* @description metodo que contiene la peticion de obtener todos los tamaños 
	**/
	getTamanos = () => {
		axios.get('/sizes', {
			headers: { Authorization: sessionStorage.getItem("token") }
		})
			.then(res => {
				this.setState({
					sizes: res.data.data
				})
			})
			.catch(res => {
				console.log("error", res);
				message.error('Error al obtener los Tamaños')
			});
	}

	/**
	* @memberof FormVariantes
	*
	* @method getProveedores
	* @description Obtiene la lista de proveedores
	**/
	getProveedores = (search) => {

		axios.get('/proveedores', {
			params: {
				search: search
			}
		}).then(response => {

			this.setState({ proveedores: response.data.data.itemsList })

		}).catch(error => {
			console.log("error", error);

		})
	}

	/**
   * @memberof FormVariantes
   *
   * @method onFinish
   * @description  Se ejecuta al dar finalizar al formulario
   *
   **/
	onFinish = values => {

		values.proveedor_id = values.proveedor_id ? values.proveedor_id.value : undefined


		// const formData = new FormData()
		// formData.appendMultiple({
		// 	...values,
		// })

		// if (values.imagenes) {
		// 	for (const imagen of values?.imagenes) {
		// 		console.log("imagen", imagen);
		// 		if (imagen.originFileObj)
		// 			formData.append("imagenes", imagen.originFileObj, imagen.name)
		// 		else
		// 			formData.append("imagenes", imagen)
		// 	}
		// }

		values.imagenes = this.state.fileList

		console.log('values.imagenes', values.imagenes);

		this.setState({ loading: true })
		if (this.props.variante_id !== undefined) {
			this.updateCategoria(values)
		} else {
			this.addVariante(values);
		}

	}


	/**
	* @memberof FormVariantes
	*
	* @method getData
	* @description  trae la informacion de una categoria
	*
	**/
	getData = (values) => {
		this.setState({ loading: true })
		axios.get(`/variantes/get`, {
			params: {
				id: this.props.variante_id
			}
		}).then(async res => {
			let variante = res.data.data.variante;
			let imagenes = variante.imagenes;
			console.log("variante", variante);
			this.formModalVariante.current.setFieldsValue({
				nombre: variante.nombre,
				sku_variante: variante.sku_variante,
				size: (variante.size_id != undefined) ? variante.size_id._id : '',
				precio_venta: variante.precio_venta,
				descripcion: variante.descripcion,
				proveedor_id: {
					value: variante.proveedor_id?._id,
					key: variante.proveedor_id?._id,
					label: variante.proveedor_id?.nombre
				},
				activo: variante.activo,
				imagenes: (Array.isArray(imagenes)  && imagenes.length > 0)? imagenes.map((imagen, index) => ({
					uid: index,
					name: imagen,
					status: 'done',
					response: {
						filename: imagen,
					},
					url: `${axios.defaults.baseURL}/upload/${imagen}`,
				})): []
			});
		}).catch(res => {
			console.log("error", res);
			message.error('Error al traer la informacion')
		}).finally(() => this.setState({ loading: false }))
	}


	/**
	* @memberof FormVariantes
	*
	* @method updateCategoria
	* @description  Actualiza la informacion de una categoria
	*
	**/
	updateCategoria = (values) => {
		this.setState({ loading: true })
		axios.put('/variantes/update', {
			id: this.props.variante_id,
			...values
		})
			.then((res) => {
				message.success('Variante Actualziado')
				this.props.onCancel()
			}).catch((error) => {
				message.error('Error al actualizar')
				console.log(error)
			}).finally(() => this.setState({ loading: false }))
	}

	/**
	* @memberof FormVariantes
	*
	* @method addVariante
	* @description  Añade una categoria
	**/
	addVariante = (values) => {
		axios.post('/variantes/add', {
			...values,
			producto_id: this.props.producto_id,
		})
			.then((res) => {
				message.success('Variante creada');
				this.props.onCancel()
			})
			.catch((error) => {
				console.log(error)
				message.error('Error al crear la Variante')
			});
	}

    /**
    * @memberof FormProductos
    *
    * @method addProducto
    * @description  Añade una categoria
    **/
    normFile = (event) => {
        console.log('upload event', event)

        let update_fileList = false;
        let delete_file = false;
            
        //Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
             
        if (event.file.status == "done") {
            update_fileList = true;
        }
            
        if (event.file.status == "removed") {
            update_fileList = true;
            delete_file = true;
        }
            
        //if (delete_file)
        //    this.removeFile((event.file.response.filename != undefined) ? event.file.response.filename : event.file.name);

    
        if (update_fileList){
            let arrayList = [];

            for (let x = 0; x < event.fileList.length; x++){
                try{
                    let filename = event.fileList[x].response.filename
                    arrayList.push(event.fileList[x].response.filename)
                }catch (e) {
                    console.log(e)
                }
            }


            console.log('ARRY', arrayList)

            this.setState({
                fileList: arrayList
            });
        }
        
        return event && event.fileList;
       
    }




	render() {

		const { loading } = this.state

		return (
			<Spin spinning={loading}>
				<Title level={3} className="text-center"> {this.props.variante ? 'Editar' : 'Crear'} Variante</Title>
				<Form
					layout="vertical"
					ref={this.formModalVariante}
					onFinish={this.onFinish}
				>

					<Row justify="center" gutter={[16, 16]}>
						<Col xs={22} lg={22}>
							<Form.Item
								name="nombre"
								label="Nombre"
								rules={[{
									required: true,
									message: 'Por favor ingresa nombre de producto'
								}]}
							>
								<Input placeholder="Nombre del producto" />
							</Form.Item>
						</Col>
						<Col xs={22} lg={22}>
							<Form.Item
								name="descripcion"
								label="Descripción"
							>
								<Input.TextArea />
							</Form.Item>
						</Col>

						<Col xs={22} lg={11}>
							<Form.Item
								name="sku_variante"
								label="Sku"
							>
								<Input placeholder="ABCDS0123" />
							</Form.Item>
						</Col>



						<Col xs={22} lg={11}>
							<Form.Item
								name="precio_venta"
								label="Precio de Venta"
								rules={[{
									required: true,
									message: 'Por favor ingresa precio de venta de producto'
								}]}>
								<InputNumber
									className="width-100"
									placeholder="$0.00"
									formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
									parser={value => value.replace(/\$\s?|(,*)/g, '')}
								/>
							</Form.Item>
						</Col>
						{this.props.variante_id ? null : <Col xs={22} lg={22}>
							<Form.Item
								name="proveedor_id"
								label="Proveedor"
								rules={[{
									required: true,
									message: 'Por favor selecciona categoría'
								}]}
							>
								<Select
									className="width-100"
									showSearch
									labelInValue
									filterOption={false}
									onSearch={this.getProveedores}
									placeholder="Seleccionar opción"
								>
									{this.state.proveedores.map(function (proveedor, index) {
										return <Option value={proveedor._id}>{proveedor.nombre}</Option>
									})}
								</Select>
							</Form.Item>
						</Col>}
						{this.props.variante_id ? null : <Col xs={22} lg={11}>
							<Form.Item
								name="costo"
								label="Costo Proveedor"
								rules={[{
									required: true,
									message: 'Por favor ingresa costo de producto'
								}]}>
								<InputNumber
									className="width-100"
									placeholder="$0.00"
									formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
									parser={value => value.replace(/\$\s?|(,*)/g, '')}
								/>
							</Form.Item>
						</Col>}
						{this.props.variante_id ? null : <Col xs={22} lg={11}>
							<Form.Item
								name="existencias"
								label="Existencias"
								rules={[{
									required: true,
									message: 'Ingrese las existencias del producto'
								}]}>
								<InputNumber
									className="width-100"

								/>
							</Form.Item>
						</Col>}
						<Col xs={22} lg={11}>


						</Col>
					</Row>
					<Row gutter={[16, 16]} style={{ marginTop: '18px' }}>

						<Col xs={24} lg={{ span: 11, push: 1 }}>
							<Form.Item
								name="activo"
								label="Producto activo"
								valuePropName="checked"
							>
								<Switch defaultChecked style={{ float: 'left' }} />
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item
								name="imagenes"
								valuePropName="fileList"
								getValueFromEvent={this.normFile}
								noStyle
							>
								<Dragger
									name="files"
									listType="picture-card"
									action={axios.defaults.baseURL + '/upload/add'}
									multiple={true}
									style={{
										maxHeight: '120px', marginBottom: 10
									}}
								>
									<p className="ant-upload-drag-icon" style={{ marginBottom: '5px' }} >
										<FileImageOutlined style={{ fontSize: '30px' }} />
									</p>
									<p className="ant-upload-text">Agrega imagenes del producto</p>
									<p className="ant-upload-hint"> Haga clic o arrastre el archivo a esta área para cargar</p>
								</Dragger>
							</Form.Item>
						</Col>
					</Row>




					<Row justify="center" align="middle" >
						<Col span={24} className="flex-column">
							<Form.Item>
								<Button htmlType="submit" type="primary">
									Guardar
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Spin>

		)
	}
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {

	let { visible, onCancel } = props

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			title={null}
			footer={null}
			maskClosable={true}
			destroyOnClose={true}
			zIndex={1000}
		>
			<FormVariantes {...props} />
		</Modal>
	)
}
