//Dependencias
import React, {Component} from 'react';
import ReactEcharts from "echarts-for-react";
import echarts from 'echarts/lib/echarts'
import { Layout, Menu, Icon,Card,Avatar, Row, Col } from 'antd';

const moment = require('moment')



class VentasSemana extends Component {

	constructor(props){
		super(props);
		this.state={
			dateList:[],
			valueList:[]
		}
	}
	
	componentDidMount(){
		

	}

	random = () => {

		let dias = []

		for (const [key, value] of Object.entries(this.props.datos)) {
		 	dias.push(moment(key).format('dddd'))
		}
	
		return {
			title: {
					left: 'center',
					text: ' ',
					
			},
			
			xAxis: {
					type: 'category',
					data: dias
			},
			yAxis: {
					type: 'value',
			},
			series: [{
					type: 'line',
					left:0,
					data: Object.values(this.props.datos)
			}]
		}
	};
 
	render() {
		if(this.props.datos.length === 0)
			return 'nada'
		return (
			<ReactEcharts option={this.random()} className="chart-semanal" style={{ height: 450 , width: '100%', margin: 'auto'}} />
		);
	}
}
 
	
/*Pie*/
var  data_pie = [{name:"returing", value:18}, {name:"New", value:82} ];
 class Productos_Categorias extends Component
{
	option = () => ({
		textAlign: 'center',
		tooltip: {
			trigger: 'item',
			formatter: '{b} : {c} ({d}%)'
	},

	legend: {
			left: 'center',
			top: 'bottom',
			textStyle: { color:'#BBB' },
			data: data_pie,
			margin:'5px 0px 0px 0px',

	},

	toolbox: {
			show: true,
			feature: {
				saveAsImage: {
					show: true, title:'Descargar' ,
					width:'30px',
					height:'30px',
					iconStyle:{
						borderColor:'#CCC',
						},
				}
			}
	},
	 
	series: 
	[
			{
			color:['#4733ff','#e2e5ee'],
			name: 'Name',
			radius: '55%',
			type: 'pie',
			margin:0,
			center: ['50%', '50%'],
			//roseType: 'bar',
			clockwise: true,
			selectedMode: 'single',
			selectedOffset: 10,
			
			labelLine: {
				show: true,
				lineStyle:{
					type:'dashed',
					type:'doted',
					//fontSize:'15px',
					shadowOffsetX:0,
					shadowOffsetY:0,
					width:3
				}
				},
				
				label: {
					show: true,
					position: 'outside',
					//alignTo: 'edge',
					alignTo: 'edge',
					margin:5,
					textStyle: { color:'#CCC' },
					formatter: '{c}\n({d}%)',
					fontSize:'13px'
				},
				

				 itemStyle:{
					 shadowColor: 'rgba(0, 0, 0, 0.5)',
					 borderColor:{},
					 //borderColor:['#313133ac'],
				},

				data: data_pie,
			} 
		]
});

render() {
		return (
				<ReactEcharts option={this.option()} style={{ height: 150,  width:'100%' }} />
		);
	}
}
 


/*Analitics*/
class Productos_Activos extends Component {

	constructor(props){
		super(props);
		this.state={montado:false}

	}
	
	data_analitics = [

		{name:"Activos", value: this.props.productos.find(el => el.activo==true)?.count }, 
		{name:"Inactivos", value: this.props.productos.find(el => el.activo==false)?.count} ,

		
];

	componentDidMount(){
		console.log('props activos',this.props)
		this.setState({montado:true});
		console.log(this.data_analitics)
	}

			option = () => ({
				height:25,
			 borderRadius:5,
				tooltip: {
						trigger: 'axis',
						axisPointer: {  
								type: 'shadow'
						}
				},
				
				toolbox: {
						show: true,
						feature: {
						saveAsImage: {
								show: true, title:'Descargar' ,
								width:'30px',
								height:'30px',
								iconStyle:{
								borderColor:'#CCC',
								},
						}
						}
				},
				
				legend: {
						orient: 'vertical',
						data: this.data_analitics,
						textStyle: { color:'#AAA' },
						left:10,
						top:45
				},

				grid: {
						left: '3%',
						right: '3%',
						bottom: '3%',
						top: '75%',
						containLabel: false
				},
				xAxis: {
						type: 'value',
						show: false

				},
				yAxis: {
						type: 'category',
						data: [''],
						show: false

				},

				series: [
						{
								name: 'Activos',
								type: 'bar',
								stack: 'x',
								color:'#4733ff',
								label: {
										show: true,
										position: 'insideRight'

								},
								data: [this.data_analitics[0]]
								
						},
						{
								name: 'Inactivos',
								type: 'bar',
								stack: 'x',
								color:'#e45757',
								label: {
										show: true,
										position: 'insideRight',
										
								},
								data: [this.data_analitics[1]]
						},
				]
		});

		renderEcharts(){
			if(this.state.montado)
			return<ReactEcharts option={this.option()} style={{ height: 150}} /> 
		}


		render() {
				return (
					<>
						{this.renderEcharts()}
					</>
				);
			}
}


 
export {VentasSemana , Productos_Categorias, Productos_Activos }
