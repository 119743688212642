import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, PageHeader, Tag, Button, Space, Typography, Card, Popconfirm, List, Image, Avatar } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import { IconBus, IconEye, IconEdit, IconDelete } from "../../Widgets/Iconos";
import { Link } from "react-router-dom";
//modales
const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;
const { Title, Text } = Typography

const axios = require("axios").default;
const moment = require('moment');

/**
 *
 *
 * @class Compras
 * @extends {Component}
 */
export default class Compras extends Component {
    constructor(props) {
        super(props);
        this.state = {

            data: [],
            page: 1,
            limit: 10,
            
        }
    }

    /**
    * @memberof Compras
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getData()
    }

    /**
    * @memberof Compras
    *
    * @method getData
    * @description  Carga la informacion los inventarios
    **/
    getData = (page = this.state.page, limit = this.state.limit) => {
        axios.get('/inventario',{
            params:{
                page: page,
                limit,
            }
        }).then(response => {
            console.log("response", response.data.data);
            this.setState({
                invetario: response.data.data.itemsList,
                total: response.data.data.paginator.itemCount,
                page: response.data.data.paginator.currentPage,
                limit: response.data.data.paginator.perPage,
            })

        }).catch(error => {
            console.log("error", error);

        })
    }





    render() {

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title={
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                <span className="ant-page-header-heading-title">
                                    Inventario
                                </span>
                            </Col>
                        </Row>
                    }
                />
                <Content className="admin-content content-bg pd-1">

                    <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Usuarios" }}
                            dataSource={this.state.invetario}
                            pagination={{
                                current: this.state.page,
                                pageSize: this.state.limit,
                                total: this.state.total,
                                showSizeChanger: true,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                className:"flex-left",
                                onChange: this.getData
                                
                            }}
                            header={<Row className="width-100 pr-1 pl-1" >
                                <Col span={2}  className="center">
                                    <Text strong></Text>
                                </Col>
                                <Col span={4}  className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col span={4}  className="center">
                                    <Text strong>Sku</Text>
                                </Col>
                                <Col span={4}  className="center">
                                    <Text strong>Almacen</Text>
                                </Col>
                                <Col span={4}  className="center">
                                    <Text strong>Existencias</Text>
                                </Col>
                                <Col span={4}  className="center">
                                    <Text strong>Unidad</Text>
                                </Col>
                                <Col span={2}  className="center">
                                    <Text strong>Acciones</Text>
                                </Col>

                            </Row>}
                            
                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={2}  className="center">
                                                { (Array.isArray(item.producto_id?.imagenes) && item?.producto_id?.imagenes.length  > 0) ? 
                                                    <Avatar style={{ marginRight: 10 }} shape="square" src={axios.defaults.baseURL + "/upload/" + item?.producto_id?.imagenes[0] } size={60} /> : 
                                                    <Avatar style={{ marginRight: 10 }} shape="square" src={'/images/img-video.png' } size={60} />
                                                }
                                            </Col>
                                            <Col span={4}  className="flex-left-column">
                                                <Text strong>{item.producto_id?.nombre}</Text>
                                                <Text>{item.variante_id?.nombre}</Text>
                                            </Col>
                                            <Col span={4}  className="center">
                                                <Text strong>{item.producto_id?.sku_manantial}</Text>
                                            </Col>
                                            <Col span={4}  className="center">
                                                <Text strong>{ item.almacen_id ? item.almacen_id.nombre : 'Sin Almacén'}</Text>
                                            </Col>
                                            <Col span={4}  className="center">
                                                <Text strong>{item.existencias}</Text>
                                            </Col>
                                            <Col span={4}  className="center">
                                                <Text strong>{item.producto_id?.unidad}</Text>
                                            </Col>
                                            <Col span={2}  className="center">
                                               <Link to={`/admin/inventario/${item.variante_id?._id}`}>
                                                   <Button
                                                        title="Ver Movimientos"
                                                        type="primary" 
                                                        icon={<IconEye/>} 
                                                    />
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                </Content>

            </>
        )
    }
}

