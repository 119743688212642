

import React, { Component } from "react";
import { Col, Form, message, Modal, Table, Tag, Row, Divider, Statistic, Spin, Typography, Button, Select } from 'antd';
import '../../../Styles/Global/modales.css';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
const axios = require('axios').default;
const { Title, Text, Paragraph } = Typography;
const moment = require('moment')

const { Option } = Select
const colors = ["#00000", "#87d068", "#FA9100"];


/**
 *
 *
 * @class ModalVenta
 * @extends {React.Component}
 * @description Formulario Detalle de Venta
 */
class ModalVenta extends Component {


    formVenta = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            venta: {
                direccion: {},
                detalles: [],
                status: 1,
                status_envio: 1,
            },
            productos: [],
        }
    }


    /**
     * @methodOf ModalVenta
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component,
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if(this.props.venta_id){
            this.getVenta()
        }
    }



    /**
     *
     *
     * @memberof ModalVenta
     * @description Trae la venta 
     * @id ObjectId de la venta
     */
    getVenta = () => {
        this.setState({loading: true})
        axios.get('/ordenes/detalle', {
            params: {
                orden_id:this.props.venta_id
            }
        })
            .then(res => {
                console.log('res', res.data)
                this.setState({ 
                    venta: res.data.orden,
                    productos: res.data.orden_detalles
                })
                this.formVenta.current.setFieldsValue({
                    status: res.data.orden.status != undefined ? res.data.orden.status : 1,
                    status_envio: res.data.orden.status_envio != undefined ? res.data.orden.status_envio : 1,

                })
            }).catch(error => {
                message.error('Error al traer detalle de venta');
                console.log('Ha ocurrido un error al traer la venta', error)
            }).finally(()=>this.setState({loading: false}))
    }



    /**
     * @memberof ModalVenta
     * @description Actualiza la informacaion de la venta
     * @id ObjectId de la venta
     */
    updateData = () => {
        this.setState({loading: true})
        let values = this.formVenta.current.getFieldsValue()
        axios.post('/ordenes/update', {
           
            orden_id:this.props.venta_id,
            ...values
            
        }).then(res => {
            message.success('Venta Actualizada')
            this.props.onCancel()
        }).catch(error => {
            console.log("error", error);
            message.error('Error Actualizar');
        }).finally(()=>this.setState({loading: false}))
    }


    /**
        *
        *
        * @memberof Ventas
        * @description Renderiza el Tag correspondiente al status de la venta
        * @param status  1 = En proceso, 2= En transito , 3= Entregado
        */
    renderTag = (status) => {

        let text = "";
        switch (status) {
            case 1:
                text = "En Proceso";
                break;
            case 2:
                text = "En Transito";
                break;
            case 3:
                text = "Entregado";
                break;

            default:
                break;
        }
        return <Tag color={colors[status]}>{text}</Tag>
    }



    render() {
        const { venta } = this.state;
        return (

            <>
                <Spin spinning={false}>
                    <Title level={3} className="text-center">Detalle  de Venta {venta.folio}</Title>
                    <Row justify="center" className="mt-1 mb-1">
                        <Col span={22}>
                            <Text strong className="text-orange">Fecha de venta: </Text>
                            <Text> {moment(venta.createdAt).format('DD-MM-YYYY HH:mm')}</Text>
                        </Col>
                    </Row>
                    <Form 
                        layout="vertical"
                        ref={this.formVenta}
                    >
                        <Row justify="center" className="" gutter={[8, 18]}>
                            <Col xs={11}>
                                <Paragraph >Nombre Cliente </Paragraph>
                                { venta.usuario_id ? <> <CustomAvatar 
                                    name={venta.usuario_id?.nombre + ' ' + venta.usuario_id?.apellido} 
                                    image={venta.usuario_id?.avatar}  
                                    color={venta.usuario_id?.tipo_usuario_id?.color}
                                />
                                <Text type="secondary">{venta.usuario_id?.nombre + ' ' + venta.usuario_id?.apellido} </Text>
                                </> : null}
                            </Col>

                            <Col xs={11}>
                                <Paragraph >Correo </Paragraph>
                                <Text type="secondary" >{venta.usuario_id?.email} </Text>
                            </Col>
                            <Col xs={22} className="flex-left-column">
                                <Paragraph >Dirección </Paragraph>
                                <Text type="secondary">{venta.direccion?.calle}, {venta.direccion?.numero}</Text>
                                <Text type="secondary">{venta.direccion?.ciudad}, {venta.direccion?.estado}, C.P. {venta.direccion.codigo_postal}</Text>
                            </Col>
                            {venta.direccion?.notas ? <Col xs={24}>
                                <Paragraph >Notas </Paragraph>
                                <Text type="secondary" >{venta.direccion?.notas} </Text>
                            </Col> : null}
                            {venta.direccion?.especificaciones ?
                                <Col xs={24}>
                                    <Paragraph >Especificaciones de envío </Paragraph>
                                    <Text type="secondary" >{venta.direccion?.especificaciones} </Text>
                                </Col> : null
                            }
                            <Col span={11} className="mb-1">
                                <Form.Item
                                    label="Estatus Venta"
                                    name="status"
                                >
                                    <Select 
                                        className="select-estatus" 
                                        style={{maxWidth: '200px'}}
                                    >
                                        <Option value={1}>En Proceso</Option>
                                        <Option value={2}>Completado</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11} className="mb-1">
                                <Form.Item
                                    label="Estatus Envío"
                                    name="status_envio"
                                >
                                    <Select 
                                        style={{maxWidth: '200px'}}
                                        className="select-estatus" 
                                    >
                                        <Option value={1}>En Proceso</Option>
                                        <Option value={2}>En transito</Option>
                                        <Option value={3}>Entregado</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            
                        </Row>
                        <Row className="table-titles mt-1 " gutter={[16,16]}>
                            <Col className="center" span={8}>
                                <Text>Producto</Text>
                            </Col>
                            <Col className="center" span={5}>
                                <Text>Cantidad</Text>
                            </Col>
                            <Col className="center" span={5}>
                                <Text>Precio</Text>
                            </Col>
                            <Col className="center" span={5}>
                                <Text>Total</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={22}>
                                <div className="list-productos-sm mt-1">
                                    {
                                        this.state.productos.map((producto,index) =>{
                                            return <Row gutter={[16,16]} style={{marginTop: '12px'}}>
                                                    <Col span={8} className="flex-left-column">
                                                        <Text ellipsis strong style={{fontSize: '12px'}}>{producto?.variante_id?.nombre}</Text>
                                                        <Text ellipsis style={{fontSize: '12px'}}>{producto?.producto_id?.nombre}</Text>
                                                    </Col>
                                                    <Col span={5}  className="center">
                                                        <Text>{producto.cantidad}</Text>
                                                    </Col>
                                                    <Col span={5} className="center">
                                                        <Text>$ {producto.precio?.toMoney()} mxn</Text>
                                                    </Col>
                                                    <Col span={5} className="flex-right">
                                                        <Text>$ {(producto?.total_venta + producto.iva).toMoney()} mxn</Text>
                                                    </Col>
                                                </Row>
                                        })
                                    }
                                </div>
                            </Col>
                        </Row>
                        <div className="carrito-card mt-1">
                            <Row justify="center">
                                <Col span={7}>
                                    <Text className="titulo">Total</Text>
                                </Col>
                                <Col span={7} className="flex-right">
                                    <Text className="dato">$ {venta?.total_venta?.toMoney()} mxn</Text>
                                </Col>
                            </Row>
                            <Row justify="center">
                                <Col span={14} className="mt-1">
                                    <Button 
                                        className="generar" 
                                        htmlType="submit" 
                                        type="primary" 
                                        loading={this.state.loading}
                                        onClick={()=>this.updateData()}
                                    >
                                        Actualizar
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Spin>
            </>

        )
    }
}


/**
 *@function ModalDetalle
 *@description 
 */
export default function (props) {

    let { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            title={null}
            footer={null}
            maskClosable={true}
            destroyOnClose={true}
            closable={true}
            onCancel={onCancel}
            zIndex={1000}
            onOk={onCancel}
            width={800}
        >
            <ModalVenta {...props} />
        </Modal>
    )
}