import React from 'react'
import { Route, Switch } from "react-router-dom";
import Cotizaciones from '../../components/Admin/Cotizaciones/Cotizaciones';
import NuevaCotizacion from '../../components/Admin/Cotizaciones/NuevaCotizacion';

// componentes

/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function CotizacionesRouter( props) {
    return (
        <Switch>
            <Route exact path="/admin/cotizaciones" render={() => <Cotizaciones {...props} />} />
            <Route exact path="/admin/cotizaciones/nueva" render={() => <NuevaCotizacion {...props} />} />
        </Switch>
    )
}

export default CotizacionesRouter;
