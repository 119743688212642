import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";

import { Layout, Row, Col, Typography, Card, Button, Carousel, } from 'antd';

import AwesomeSlider from 'react-awesome-slider';

import { CreditCard, Outlined, HeartOutlined } from '@ant-design/icons'
import { IconBus, IconStar, IconTiming, IconConversation, IconHotDeals } from "../Widgets/Iconos";

import withAutoplay from 'react-awesome-slider/dist/autoplay';

import 'react-awesome-slider/dist/styles.css';

import '../../Styles/Modules/Public/landing.css';

import Slider from "react-slick";

import WeLoveOurClients from "./WeLoveOurClients";
import HotDeals from "./HotSale";

import UpdatePassword from "../Auth/UpdatePassword";



const AutoplaySlider = withAutoplay(AwesomeSlider);

const { Title, Paragraph, Text, } = Typography

/**
 *
 *
 * @export
 * @class Landing
 * @extends {Component}
 * @description Pagina principal del sistema
 */
export default class Landing extends Component {

    static defaultProps = {
        recovery: false
    }

    constructor(props) {
        super(props)
        this.state = {
            showRecovery: this.props.recovery
        }
    };

    componentDidMount() {
        this.props.setCategoria(null)
        this.props.setHot(false)
    }



    render() {


        return (
            <Layout className="landing">
                <AutoplaySlider
                    play={true}
                    // cancelOnInteraction={false} // should stop playing on user interaction
                    interval={4000}

                    bullets={false}
                    style={{ height: "calc(100vh - 150px)" }}>

                    <div
                        data-src="/images/bg-1.png"
                        onTransitionEnd={(e, x, g) => {
                        }}
                        className="menmen"
                    >
                        <div className="landing-slider-slide">
                            <Title level={2} className="landing-slider-slug"><b>Tu</b> Casa de <b>Materiales </b><p>de Confianza</p></Title>
                            <Link to={'/store'} className="landing-slider-button-link">
                                Explorar
                            </Link>
                        </div>
                    </div>
                </AutoplaySlider>



                <Row className="landing-section row-langing-images" >
                    <Col xs={24} md={12} xl={8} >
                        <Card bordered={false} className="card-landing" >
                            <Row className="row-img" style={{ backgroundImage: 'url(/images/landing/01.png)' }}>
                                <Col span={24} >
                                    <Title className="ttl-card-landing">Albañilería</Title>
                                    <Link to={'/store'} className="">
                                        <Button className="btn-card-landing">Ver Productos</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} md={12} xl={8} >
                        <Card bordered={false} className="card-landing " >

                            <Row className="row-img" style={{ backgroundImage: "url('/images/landing/02.png')" }}>
                                <Col span={24} >
                                    <Title className="ttl-card-landing">Acero</Title>
                                    <Link to={'/store'} className="">
                                        <Button className="btn-card-landing">Ver Productos</Button>
                                    </Link>
                                </Col>
                            </Row>


                        </Card>
                    </Col>
                    <Col xs={24} md={12} xl={8} >
                        <Card bordered={false} className="card-landing " >
                            <Row className="row-img" style={{ backgroundImage: "url('/images/landing/03.png')" }}>
                                <Col span={24}  >
                                    <Title className="ttl-card-landing">Plomería</Title>
                                    <Link to={'/store'} className="">
                                        <Button className="btn-card-landing">Ver Productos</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <WeLoveOurClients />
                <HotDeals />

                <UpdatePassword
                    visible={this.state.showRecovery}
                    {...this.props}
                />
                <div id="fb-customer-chat" class="fb-customerchat"></div>
            </Layout>
        )
    }

}