import React, { Component, useContext, useRef, useState } from 'react';

import { default as axios } from 'axios';

import {
    Form,
    Input,
    Select,
    Button,
    Table,
    Typography,
    Checkbox
} from 'antd';

import {
    MailOutlined,
    PhoneOutlined,
    MinusCircleOutlined, SafetyCertificateOutlined,
} from '@ant-design/icons';

import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';


import PaymentForm from './PaymentForm';


import './Stripe.css';
import { Stripe } from "./index";

const { Option } = Select;
const { Text } = Typography;

const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#c4f0ff',
            color: 'black',
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': { color: '#e5edf5' },
            '::placeholder': { color: '#bfc0d2' },
        },
        invalid: {
            iconColor: '#bfc0d2',
            color: 'black',
        },
    },
    
};



const stripePromise = loadStripe('pk_test_51JvQ9qEVNWAOILZe6ivJAYlOXnwoz7Km1ii22lQkZFGqua3DKO1CIunw1mCJ7INxLMcvLDy8LtJS6lc5GrgVvZIR00PVcQ6Fc8', {locale:'es-419'});



const CheckoutForm = React.forwardRef((props, ref) => {



    const { setLoading } = props
    const [error, setError] = useState(null);

    const [checkBox, setChecbox] = useState(false);
    const [message, setmessage] = useState('');


    const stripe = useStripe();
    const elements = useElements();
    const user = props.user;
    let textInput = null;

    const paymentHandledSubmit = props.paymentHandledSubmit;
    const handleChange = (event) => {
        if (event.error) {
            setError(event.error.message);
        } else {
            setError(null);
        }
    };


    console.log('setLoading', setLoading)

    // Handle form submission.
    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log('EXECUTE');

        try {
            setLoading(true)
        } catch (error) {
            console.log('EXECUTE', error);
        }

        console.log('CARRD DELEMENET');

        const cardElement = elements.getElement(CardElement);
        let { token, error } = await stripe.createToken(cardElement, { name: user.nombre, currency: 'mxn' });
        try {
            if (checkBox && (textInput.state.value == null || textInput.state.value == "")) {
                setmessage("Ingresa tu codigo promocional.")
                return;
            }

            // console.log
            paymentHandledSubmit({
                token: token,
                error: error,
                promo: textInput?.state?.value
            });
        } catch (e) {
            console.log(e);
            setmessage("Tienes que agregar tu codigo promocional.")

            
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div style={{ marginBottom: '2em' }}>
                Ingresa tu informacion para proceder con el pago
            </div>
            <fieldset className="FormGroup">
                <div className="FormRow">
                    <CardElement

                        options={CARD_OPTIONS}
                        id="card-element"
                        onChange={handleChange}
                    />
                </div>
            </fieldset>
            <fieldset>
                <div className="card-errors" role="alert">{error}</div>
            </fieldset>
            <div style={{ margin: "0 15px 20px" }}>
                <Checkbox
                    onChange={() => {
                        setChecbox(!checkBox);
                    }}
                    checked={checkBox}

                >¡Tengo un código promocional!</Checkbox>
            </div>
            <div style={{ margin: "0 15px 20px", display: (checkBox ? "inline" : "none") }}>
                <Input
                    ref={(input) => textInput = input}
                    onChange={e => setmessage('')}
                    className="form-item-container-input" placeholder="Código de Cupón" />
                <p style={{ padding: "0 15px 20px", display: (checkBox ? "inline" : "none"), color: "red" }}>{message}</p>
            </div>
            <input type="submit" style={{ display: 'none' }} ref={ref} />
        </form>
    );
}
)
class StripeAdapter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            buyList: [],
            total: 0.0,
            subtotal: 0.0
        }
    }


    async componentDidMount() {
        let listaCompras = Object.assign([], this.props.buyList);
        if (this.props.selectedShippingOptions !== undefined) {
            let selectedShippingOption = this.props.selectedShippingOptions;
            listaCompras.push({
                name: selectedShippingOption.name,
                description: (selectedShippingOption.description == undefined) ? "Servicio de Envio" : selectedShippingOption.description,
                cost: selectedShippingOption.cost,
                quantity: 1
            });
            this.setState({
                buyList: listaCompras
            })
        }
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     console.log('update')
    // }

    static getDerivedStateFromProps(props, state) {
        console.log('actualizado')
        let listaCompras = Object.assign([], props.buyList);
        console.log('lista', listaCompras);
        console.log('props.shippingOptions', props.shippingOptions)
        if (props.selectedShippingOptions !== undefined) {
            console.log('s')
            let selectedShippingOption = props.selectedShippingOptions;
            listaCompras.push({
                name: selectedShippingOption.name,
                description: (selectedShippingOption.description == undefined) ? "Servicio de Envio" : selectedShippingOption.description,
                cost: selectedShippingOption.cost,
                quantity: 1
            });
            return {
                buyList: listaCompras
            };
        }
        return null;
    }

    getTotal = (subtotal) => {
        let total = 0.0;
        if (Array.isArray(this.props.additionalCharges))
            this.props.additionalCharges.map(item => {
                let additionalCharge = item;
                let charge = 0;

                if (additionalCharge.type == 'percentage')
                    charge = subtotal * (additionalCharge.quantity / 100);
                else
                    charge = additionalCharge.quantity;

                if (additionalCharge.operation == "+")
                    total = total + charge;
                else
                    total = total - charge;
            });
        return parseFloat(total) + parseFloat(subtotal);




        // const items = this.state.buyList;;
        // let subtotal = parseFloat('0');
        // items.map(item => {
        //     console.log('item',item)
        //     subtotal = subtotal + (parseFloat(item.quantity) * parseFloat(item.cost))
        // });
        // return subtotal;
    };

    getSubTotal = () => {
        const items = this.state.buyList;;
        let subtotal = parseFloat('0');
        items.map(item => {
            console.log('item', item)
            subtotal = subtotal + (parseFloat(item.quantity) * parseFloat(item.cost))
        });
        return subtotal;
    };

    render() {


        const Languaje = this.props.languaje;
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };
        //Declararmos el subtotal
        // let subtotal = parseFloat('0');
        //Declaramos el total
        // let total = parseFloat('0');
        //Compiamos la lista de compras, a m plicarla directamente falla

        //Obtenemos la seleccion elegida

        const listaCompras = this.state.buyList;
        // const { total, subtotal } = this.state;
        let subtotal = this.getSubTotal();
        let total = this.getTotal(subtotal);

        // if (this.props.shippingOptions !== undefined) {
        //     let selectedShippingOption = (Array.isArray(this.props.shippingOptions))? this.props.shippingOptions[this.props.selectedShippingOptions]:this.props.shippingOptions;
        //     //Agregamos el metodo de envio seleccionado,
        //     listaCompras.push({
        //         name: selectedShippingOption.name,
        //         description: (selectedShippingOption.description == undefined) ? "Servicio de Envio" : selectedShippingOption.description,
        //         cost: selectedShippingOption.cost,
        //         quantity: 1
        //     });
        // }else{
        //
        //

        // }

        let additionalCharges = (this.props.additionalCharges === undefined) ? [] : this.props.additionalCharges;

        console.log('this.props.additionalCharges.', this.props.additionalCharges)

        return (
            <div>
                {
                    (this.props.total == undefined) ?
                        <Form.Item>
                            <Table dataSource={listaCompras}
                                pagination={false}
                                columns={[
                                    {
                                        title: 'Nombre',
                                        dataIndex: 'name',
                                        render: (text, row) => (row.link == undefined) ? text :
                                            <a href={row.link} target='_blank'>{text}</a>,
                                    },
                                    {
                                        title: 'Cantidad',
                                        dataIndex: 'quantity',
                                        key: 'quantity',
                                    },
                                    {
                                        title: 'Costo',
                                        dataIndex: 'cost',
                                        render: text => {
                                            let number = parseFloat(text).toFixed(2);
                                            return '$' + ((number > 0) ? number : "0.00") + 'MNX'
                                        },
                                    },
                                    {
                                        title: 'Totales',
                                        dataIndex: 'total',
                                        render: (text, row) => {
                                            let subtotal_products = parseFloat(parseFloat(row.quantity) * parseFloat((row.cost !== undefined) ? parseFloat(row.cost).toFixed(2) : 0)).toFixed(2);
                                            return '$' + subtotal_products + 'MXN';
                                        },

                                    },
                                ]}
                                summary={pageData => {
                                    return (
                                        <>
                                            <tr>
                                                <th colSpan={3}>Subtotal</th>
                                                <td>
                                                    <Text type="primary">${parseFloat(subtotal).toFixed(2)} MXN</Text>
                                                </td>
                                            </tr>
                                            {
                                                additionalCharges.map((T, number) => {
                                                    let additionalCharge = T;
                                                    let charge = 0;

                                                    if (additionalCharge.type == 'percentage')
                                                        charge = subtotal * (additionalCharge.quantity / 100);
                                                    else
                                                        charge = additionalCharge.quantity;

                                                    if (additionalCharge.operation == "+")
                                                        total = total + charge;
                                                    else
                                                        total = total - charge;

                                                    return (
                                                        <tr key={'additionalCharge-' + number}>
                                                            <th colSpan={2}>{additionalCharge.description}</th>
                                                            <td>
                                                                <Text>{additionalCharge.operation}${parseFloat(additionalCharge.quantity).toFixed(2) + ((additionalCharge.type == 'percentage') ? '%' : '')}</Text>
                                                            </td>
                                                            <td>
                                                                <Text>{additionalCharge.operation}${parseFloat(charge).toFixed(2)}</Text>
                                                            </td>
                                                        </tr>);
                                                })}
                                            <tr>
                                                <th colSpan={3} align={"right"}>Total</th>
                                                <td>
                                                    <Text type="primary">${parseFloat(total).toFixed(2)}</Text>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                }}
                            />
                        </Form.Item>
                        : null
                }
                <Elements stripe={stripePromise} options={{ locale: "es"  }} >
                    <CheckoutForm
                        user={this.props.user}
                        paymentHandledSubmit={this.props.paymentHandledSubmit}
                    />
                </Elements>
            </div>
        );
    }


}


export { CheckoutForm }


export default (StripeAdapter);
