import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, message, Upload, Checkbox, Spin, Radio, Space, Switch, Divider, DatePicker } from 'antd';
import { PlusOutlined,  DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import { CotizacionDetalle } from './ModalCotizaciones'


const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');


class ModalVariante extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            orden: {
                subtotal_venta: 0,
                iva_venta: 0,
                total_venta: 0
            },
            productos: [],
            tipo_vista: 'compras'

        }
    }

    refModalVariante = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if(this.props.orden_id){
            this.getOrden()
        }

    }

    /**
    * @memberof Cotizaciones
    *
    * @method getOrden
    * @description Obtiene la orden y sus productos
    **/
    getOrden = () => {
        this.setState({loading: true})
        axios.get('/ordenes/detalle',{
            params:{
                orden_id: this.props.orden_id
            }
        }).then(response => {
            console.log("response", response.data);
            this.setState({
                orden: response.data.orden,
                productos: response.data.orden_detalles
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la información')
        }).finally(()=>this.setState({loading: false}))
    }


    /**
    * @memberof Cotizaciones
    *
    * @method updateProducto
    * @description Actualiza la información de una orden detalle, y vuelve a obtener todos los detalles con los calculos
    **/
    updateProducto = (values, index) => {
        
        let orden_detalle = this.state.productos[index]

        let { costo, cantidad, estatus } = values


        orden_detalle.estatus = estatus

        let productos = this.state.productos
        let orden = this.state.orden

        productos[index] = orden_detalle


        this.setState({productos: []},() => {
            this.setState({productos: productos, orden: orden},()=>{
                this.updateData()
            })
        }) 
    
    }

    /**
    * @memberof Cotizaciones
    *
    * @method updateOrden
    * @description Actualiza el total dependidneo del iva
    **/
    updateOrden = (values) => {

        let { iva_agregado } = values

        let orden = this.state.orden


        if(iva_agregado){
            orden.iva_venta = ((orden.subtotal_venta * 100) * (0.16 * 100)) / 10000
            orden.total_venta =  ((orden.total_venta * 100) + ( orden.iva_venta * 100)) / 100
        }else{
            orden.iva_venta = 0
            orden.total_venta = orden.subtotal_venta
        }

        orden.iva_agregado_venta = iva_agregado

        this.setState({orden: orden}) 
    
    }

    /**
    * @memberof Cotizaciones
    *
    * @method updateOrden
    * @description Actualiza la informacion en la bd
    **/    
    updateData = () => {
        this.setState({loading: true})
        axios.post('/ordenes/update/all',{
            orden: this.state.orden,
            orden_detalles: this.state.productos
        }).then(response => {
            message.success('Orden Actualizada')
            //this.props.onCancel()
        }).catch(error =>{
            console.log("error", error);
            if(error?.response?.status === 403){
                message.error(error?.response?.data?.message)
            }else{
                message.error('Error al actualizar')
            }
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof FormOrdenes
     * 
     * @method getPDF
     * @description Generamos el PDF de la contizacion
     */
    getPDF = (orden_id) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/ordenes/pdf');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('orden_id', orden_id)
        return urlPDF.href
    }



    render() {

        const { orden, productos, tipo_vista } = this.state

        if(this.state.tipo_vista === 'ventas')
            return <CotizacionDetalle 
                {...this.props}
                onChangeVista={()=>this.setState({tipo_vista: 'compras'})}
                change={true}
            />

        return (
            <>
                <div className="center">
                    <Title level={3} className="text-orange">Orden de Compra {orden.folio} </Title>
                </div>
                <Row className="mt-1 mb-2">
                    <Col span={10} className="flex-left">
                        <Text strong className="text-orange">{moment(orden.createdAt).format('DD-MM-YYYY')}</Text>
                    </Col>
                    <Col span={14} className="">
                        <Space>
                            <Button 
                                className={`btn-materiales ${tipo_vista === 'ventas' ? 'active' : ''}`} 
                                onClick={() => { this.setState({tipo_vista: 'ventas'}) }}
                            >   
                                Cotizaciones
                            </Button>
                            
                            <Button 
                                className={`btn-materiales ${tipo_vista === 'compras'? 'active' : ''}`} 
                                onClick={() => { this.setState({tipo_vista: 'compras'}) }}
                            >
                                Orden de Compra
                            </Button>
                        </Space>
                    </Col>
                </Row>
                
                <Spin spinning={this.state.loading}>
                    <Row className="table-titles mt-1" gutter={[16,16]}>
                        <Col className="center" span={8}>
                            <Text>Producto</Text>
                        </Col>
                        <Col className="center" span={5}>
                            <Text>Cantidad</Text>
                        </Col>
                        <Col className="center" span={5}>
                            <Text>Total Precio</Text>
                        </Col>
                        <Col className="center" span={6}>
                            <Text>Estatus</Text>
                        </Col>
                        
                        
                    </Row>
                    <div className="list-productos mt-1">
                        {
                            productos.map((producto,index) =>{
                                return <Form
                                    onValuesChange={(values, all) => this.updateProducto(all, index)}
                                    initialValues={{
                                    	...producto,
                                    }}
                                >
                                    <Form.Item name="total" hidden/>
                                    <Form.Item name="costo" hidden/>
                                    <Form.Item name="_id" hidden/>
                                    <Row gutter={[16,16]} style={{marginTop: '12px'}}>
                                        <Col span={8} className="flex-left-column">
                                            <Text ellipsis strong style={{fontSize: '12px'}}>{producto?.variante_id?.nombre}</Text>
                                            <Text ellipsis style={{fontSize: '12px'}}>{producto?.producto_id?.nombre}</Text>
                                        </Col>
                                        <Col span={5} className="center">
                                            {/*<Form.Item name="cantidad">
                                                <InputNumber min={1} precision={0} size="small" className="width-100" disabled={true}/>
                                            </Form.Item>*/}
                                            <Text>{producto.cantidad}</Text>
                                        </Col>
                                        <Col span={5} className="center">
                                            <Text strong>$ {producto?.total_venta?.toMoney()} mxn</Text>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                            	name="estatus"
                                            >
	                                            <Select
	                                            	className="select-estatus"
	                                            >
	                                            	<Option value={0}>No Entregado</Option>
	                                            	<Option value={1}>En Camino</Option>
	                                            	<Option value={2}>Entregado</Option>
	                                            </Select>
	                                        </Form.Item>
                                        </Col>
                                        
                                    </Row>
                                </Form>
                            })
                        }
                    </div>
                    <Divider/>
                    <div className="carrito-card mt-1">
                        <Row justify="center">
                            <Col span={7}>
                                <Text className="titulo">Subtotal</Text>
                            </Col>
                            <Col span={7} className="flex-right">
                                <Text className="dato">$ {orden?.subtotal_venta?.toMoney()} mxn</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={7}>
                                <Text className="titulo">IVA <Switch 
                                    size="small" 
                                    checked={orden.iva_agregado_venta} 
                                    disabled={true}
                                    //onChange={(check) => this.updateOrden({iva_agregado: check})}
                                    />
                                </Text>
                            </Col>
                            <Col span={7} className="flex-right">
                                <Text className="dato">$ {orden?.iva_venta?.toMoney()} mxn</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={7}>
                                <Text className="titulo">Total</Text>
                            </Col>
                            <Col span={7} className="flex-right">
                                <Text className="dato">$ {orden?.total_venta?.toMoney()} mxn</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={14} className="mt-1">
                                <a href={this.getPDF(orden._id)} download target="_blank">
                                    <Button 
                                        className="generar" 
                                        htmlType="submit" 
                                        type="primary" 
                                        loading={this.state.loading}
                                        //onClick={()=>this.updateData()}
                                    >
                                        Descargar Orden de Compra
                                    </Button>
                                </a>
                            </Col>
                        </Row>
                    </div>

                </Spin>
            </>
        )
    }
}



export default function (props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        destroyOnClose={true}
        zIndex={1000}
        width={700}
    >
        <ModalVariante {...props} />
    </Modal>

}