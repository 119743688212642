import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, PageHeader, Tag, Button, Space, Typography, Card, Popconfirm, List, Avatar } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import { IconBus, IconEye, IconEdit, IconDelete } from "../../Widgets/Iconos";
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import '../../../Styles/Global/admin.scss';

//modales
import ModalOrdenDetalle from './Modales/ModalOrdenDetalle'


const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;
const { Title, Text } = Typography

const axios = require("axios").default;
const moment = require('moment');



const colors = ["#00000", "#87d068", "#FA9100"];
/**
 *
 *
 * @class Cotizaciones
 * @extends {Component}
 */
export default class Cotizaciones extends Component {
    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            cotizaciones: [],

            page: 1,
            limit: 10,
            total: 0,
        }
    }

    /**
    * @memberof Cotizaciones
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    *
    *
    **/
    componentDidMount() {
        /* this.props.setTitle('Ventas') */
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenes()
    }


     /**
    * @memberof Cotizaciones
    *
    * @method getOrdenes
    * @description  Carga los datos de la vista
    **/
    getOrdenes = (page = this.state.page) => {
        this.setState({loading: true})
        axios.get('/ordenes',{
            params: {
                usuario_id: this.props.usuario_id,
                cotizacion: true, //cotizaciones
            }
        }).then(response => {
            this.setState({
                cotizaciones: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
            })

        }).catch(error => {
            console.log("error", error);

        }).finally(()=>this.setState({loading: false}))
    }
    



    

    
    render() {

        return (
            <>
                <Content className="admin-content content-bg pd-1">

                    <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Cotizaciones realizadas" }}
                            dataSource={this.state.cotizaciones}
                            pagination={{
                                current: this.state.page,
                                pageSize: 10,
                                total: this.state.total,
                                showSizeChanger: false,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                className:"flex-left",
                                onChange: this.getOrdenes
                                
                            }}

                            header={<Row className="width-100 pr-1 pl-1" >
                                <Col span={2}  className="center">
                                    <Text strong>#</Text>
                                </Col>
                                <Col span={3}  className="center">
                                    <Text strong>Fecha</Text>
                                </Col>
                                <Col span={3}  className="center">
                                    <Text strong>Tipo</Text>
                                </Col>
                                <Col span={4}  className="center">
                                    <Text strong>Productos</Text>
                                </Col>
                                <Col span={4}  className="center">
                                    <Text strong>Monto Pagado</Text>
                                </Col>
                                <Col span={4}  className="center">
                                    <Text strong>Total</Text>
                                </Col>
                                <Col span={4}  className="center">
                                    <Text strong>Acciones</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                        <Col span={2}  className="center">
                                            <Text strong>{item.folio}</Text>
                                        </Col>
                                        <Col span={3}  className="center">
                                            <Text strong>{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                                        </Col>
                                        <Col span={3}  className="center">
                                            <Tag className="tipo-tag" strong>Cotizacion</Tag>
                                        </Col>
                                        <Col span={4}  className="center">
                                            <Text strong>{item.productos.length}</Text>
                                        </Col>
                                        <Col span={4}  className="center">
                                            <Text strong>$ {item.monto_pagado.toMoney()} mxn</Text>
                                        </Col>
                                        <Col span={4}  className="center">
                                            <Text strong>$ {item.total_venta.toMoney()} mxn</Text>
                                        </Col>
                                            <Col span={4} className="center">
                                                <Space>
                                                   
                                                    <Button 
                                                        type="primary" 
                                                        icon={<IconEye/>} 
                                                        onClick={()=>this.setState({modal_visible: true, tipo: item.tipo, orden_id:item._id})}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta cotizacion?"
                                                        okText="Si"
                                                        cancelText="No"
                                                        onConfirm={()=>{
                                                            axios.post('/ordenes/delete',{
                                                                orden_id: item._id
                                                            }).then(()=>this.getDatos()).catch(()=>message.error('Error al eliminar'))
                                                        }}
                                                    >
                                                        <Button type="normal" danger  title="Eliminar" icon={<IconDelete/>}/>
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                </Content>

                <ModalOrdenDetalle
                    visible={this.state.modal_visible}
                    onCancel={()=>{
                        this.setState({modal_visible: false, orden_id: undefined})
                        this.getOrdenes()
                    }}
                    orden_id={this.state.orden_id}
                />

            </>
        )
    }
}

