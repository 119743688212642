import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, Select, PageHeader, Tag, Button, Switch, Space, Typography, Card, Popconfirm, List, Avatar, Tabs, Image, Form, Divider } from 'antd';
import { Redirect } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { FilePdfFilled } from '@ant-design/icons';

//componentes
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import {ProductosOrden, CarritoOrden} from "./OrdenDeCompra"
import {ProductosCotizacion, CarritoCotizacion} from "./Cotizacion"
import { IconBus, IconEye, IconEdit, IconDelete } from "../../Widgets/Iconos";

//modales
import ModalVariante from './ModalVariante'
import ModalUsuarios from '../Usuarios/ModalUsuario'

//css
import '../../../Styles/Global/admin.scss';

const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;
const { Title, Text } = Typography
const { TabPane } = Tabs;
const {Option} = Select

const axios = require("axios").default;
const moment = require('moment');



const colors = ["#00000", "#87d068", "#FA9100"];
/**
 *
 *
 * @class Cotizaciones
 * @extends {Component}
 */
export default class Cotizaciones extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seleccion: 1,
            sending: false,
            orden: {
                subtotal_venta: 0,
                iva_venta: 0,
                total_venta: 0,
                subtotal_costo: 0,
                iva_costo: 0,
                total_costo: 0
            },
            direcciones: [],
            modal_visible: false,
            clientes: [],
            usuario_id: undefined,
            redirect: false,
            productos: [],
            tipo: 1
        }
    }

    cotizacionRef = React.createRef()
    productosRef = React.createRef()
    refDireccion = React.createRef()
    refUsuario = React.createRef()
    
    componentDidMount() {
        /* this.props.setTitle('Ventas') */
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getUsuarios()
        this.newOrden()
        
    }

    /**
    * @memberof Cotizaciones
    *
    * @method newOrden
    * @description Crea una nueva orden, se la manda al padre para actualizar la vista
    **/
    newOrden = () => {
        axios.post('/ordenes/nueva',{
            tipo: 1
        }).then(response => {
            this.setState({
                orden_id: response.data.data._id,
                productos: []
            })
            this.setState({orden: response.data.data})

        }).catch(error => {
            console.log("error", error);

        })
    }

    /**
    * @memberof Cotizaciones
    *
    * @method getOrden
    * @description Obtiene la orden y sus productos
    **/
    getOrden = () => {
        axios.get('/ordenes/detalle',{
            params:{
                orden_id: this.state.orden_id
            }
        }).then(response => {
            this.setState({
                productos: []
            },()=>{
                this.setState({
                    productos: response.data.orden_detalles,
                    orden: response.data.orden,
                })
            })

        }).catch(error => {
            console.log("error", error);

        })
    }
    
    /**
    * @memberof Cotizaciones
    *
    * @method addProducto
    * @description Crea una nueva orden, se la manda al padre para actualizar la vista
    **/
    addProducto = ({variante_id, proveedor_id}) => {
        axios.post('/ordenes/producto/add',{
            orden_id: this.state.orden_id,
            variante_id,
            proveedor_id,
        }).then(response => {

            this.getOrden()
        }).catch(error => {
            console.log("error", error);

        })
    }

    /**
    * @memberof Cotizaciones
    *
    * @method updateProducto
    * @description Actualiza la información de una orden detalle, y vuelve a obtener todos los detalles con los calculos
    **/
    updateProducto = (values, index) => {
        axios.post('/ordenes/producto/update',{
            ...values,
        }).then(response => {
            let productos = this.state.productos
            productos[index] = { 
                ...productos[index], 
                ...values, 
                margen_utilidad: response.data.orden_detalle.margen_utilidad
            }

            this.setState({productos})

            this.setState({orden: response.data.orden})
        }).catch(error => {
            console.log("error", error);

            if(error?.response?.status === 403){
                message.error(error?.response?.data?.message)
            }else{
                message.error('Error al actualizar')
            }
        })
    }

    
    /**
    * @memberof Cotizaciones
    *
    * @method   onChangeOrden
    * @description actualiza la orden para quitar o añadir el iva, y asignar un cliente, en caso de ser confirmada la orden se redirige a la vista de 
    * lista de ordenes
    **/
    onChangeOrden = (values) => {

        const { orden } = this.state

        if(values.iva_agregado !== undefined){
            if(orden.tipo === 1) values.iva_agregado_venta = values.iva_agregado
            if(orden.tipo === 2) values.iva_agregado_costo = values.iva_agregado
        }

        if(values.usuario_id && orden.usuario_id != values.usuario_id && this.refDireccion.current){
                console.log("this.refDireccion.current", this.refDireccion.current);
            this.refDireccion.current.setFieldsValue({
                direccion_id: null
            })
        }

        axios.post('/ordenes/update',{
            ...values,
            orden_id: this.state.orden._id,
        }).then(response => {
            if(response.data.orden.estatus === 1){
                this.setState({orden: {
                    subtotal: 0,
                    iva: 0,
                    total: 0
                },
                redirect: true})
                //this.cotizacionRef.current.getOrden()
                //this.productosRef.current.getProductos()



            }else{
                
                let direcciones = []

                let index = this.state.clientes.findIndex(c => c._id.toString() == response.data?.orden.usuario_id?.toString())

                if(index != -1){
                    direcciones = this.state.clientes[index].direcciones
                }else {
                    direcciones = []
                }

                this.setState({
                    orden: response.data.orden,
                    tipo: response.data.orden.tipo,
                    direcciones: direcciones,
                    productos: response.data.orden_detalles,
                })
            }

        }).catch(error => {
            console.log("error", error);
            if(error?.response?.status == 403)
                message.error(error.response.data.message)
            else
                message.error('Error al generar la orden')
        })
    }

    /**
    * @memberof Cotizaciones
    *
    * @method   getUsuarios
    * @description actualiza la orden para quitar o añadir el iva
    **/
    getUsuarios = (search) => {        

        axios.get('/usuarios',{
            params:{
                tipo: 2,
                search
            }
        }).then(response => {
            this.setState({clientes: response.data.data.itemsList})

        }).catch(error => {
            console.log("error", error);

        })
    }

    /**
    * @memberof Cotizaciones
    *
    * @method   addClienteNew
    * @description Añade el cliente recien creado en el select y lo selecciona auntomaticamente
    **/
    addClienteNew = (cliente) => {        

        let clientes = this.state.clientes

        clientes.push(cliente)

        this.setState({clientes})

        this.refUsuario.current.setFieldsValue({
            usuario_id: cliente._id
        })

        this.onChangeOrden({usuario_id: cliente._id})
    }


    render() {

        const { orden, redirect, tipo } = this.state

        if(redirect){
            return <Redirect to="/admin/cotizaciones"/>
        }

        return (
            <Layout>
                <PageHeader
                    className="site-page-header"
                    title={
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                <span className="ant-page-header-heading-title">
                                    Cotizaciones
                                </span>
                            </Col>
                        </Row>
                    }
                />
                <Content className="admin-content content-bg pd-1">
                   <Row gutter={30}>
                       <Col span={15}>
                            <ProductosCotizacion
                                ref={this.productosRef}
                                openModal={(variante)=>this.setState({
                                    variante_id: variante._id, 
                                    modal_visible: true,
                                    variante,
                                })}
                            />
                       </Col>
                       <Col span={9}>
                            <Card className="carrito-card">
                                <Row align="middle">
                                    <Col span={24}>
                                        <Text className="folio">FOLIO {orden.folio}</Text>
                                    </Col>
                                    <Col span={3} className="center pt-1">
                                        <Button
                                            type="primary"
                                            title="Añadir Cliente"
                                            icon={<PlusOutlined style={{color: 'white'}}/>}
                                            onClick={()=>this.setState({modal_cliente: true})}
                                        />
                                            
                                    </Col>
                                    <Col className="flex-right pt-1" span={21}>
                                        <Form
                                            ref={this.refUsuario}
                                            style={{width: '90%'}} 
                                        >
                                            <Form.Item
                                                name="usuario_id"
                                            >
                                                <Select 
                                                    className="clientes" 
                                                    showSearch
                                                    allowClear
                                                    filterOption={false}
                                                    onSearch={this.getUsuarios}
                                                    onSelect={(usuario_id)=>{
                                                        this.onChangeOrden({usuario_id})
                                                    }}
                                                    placeholder="Seleccionar un usuario" 
                                                >
                                                    {this.state.clientes.map(cliente => <Option value={cliente._id}>
                                                        <CustomAvatar 
                                                            name={cliente.nombre + ' ' + cliente.apellido} 
                                                            image={cliente.avatar}  
                                                            color={cliente?.tipo_usuario_id?.color ? cliente?.tipo_usuario_id?.color : '#9664FF'}
                                                            size="small"
                                                        />
                                                        <Text strong>{cliente.nombre + ' ' + cliente?.apellido} </Text>
                                                    </Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                    <Col className="flex-right pt-1" span={24} style={{minHeight: '50px'}}>
                                        <Form
                                            ref={this.refDireccion}
                                            style={{width: '80%'}} 
                                        >
                                            <Form.Item
                                                name="direccion_id"
                                            >
                                                {this.state.orden.usuario_id ? <Select 
                                                    className="clientes" 
                                                    onSelect={(direccion_id)=>{
                                                        this.onChangeOrden({direccion_id})
                                                    }}
                                                    placeholder="Seleccionar una dirección" 
                                                >
                                                    {this.state.direcciones.map(direccion => <Option value={direccion._id}>
                                                        {direccion.nombre} <br/><small>{direccion.calle}</small>
                                                    </Option>)}
                                                </Select> : null }
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row className="">

                                    <Col span={24} className="mt-2 mb-2">
                                        <Space>
                                            <Button 
                                                className={`btn-materiales ${tipo === 1 ? 'active' : ''}`} 
                                                onClick={() => { this.onChangeOrden({tipo: 1}) }}
                                            >   
                                                Cotizaciones
                                            </Button>
                                            
                                            <Button 
                                                className={`btn-materiales ${tipo === 2 ? 'active' : ''}`} 
                                                onClick={() => { this.onChangeOrden({tipo: 2}) }}
                                            >
                                                Orden de Compra
                                            </Button>
                                        </Space>
                                    </Col>
                                    <Col span={24}>
                                        <CarritoCotizacion
                                            ref={this.cotizacionRef}
                                            setOrden={(orden)=>this.setState({ orden })}
                                            productos={this.state.productos}
                                            updateProducto={(values, index)=>this.updateProducto(values,index)}
                                            getOrden={()=>this.getOrden()}
                                            tipo={this.state.tipo}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Text className="titulo-sm">Utilidad</Text>
                                    </Col>
                                    <Col span={12} className="flex-right">
                                        <Text className="dato-sm">{orden.utilidad ? orden.utilidad.toUtilidad() : '0%'}</Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Text className="titulo">Subtotal</Text>
                                    </Col>
                                    <Col span={12} className="flex-right">
                                        <Text className="dato">$ {orden.tipo === 1 ? orden.subtotal_venta.toMoney() : orden.subtotal_costo.toMoney()} mxn</Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Text className="titulo">IVA <Switch 
                                            checked={orden.tipo === 1 ? orden.iva_agregado_venta : orden.iva_agregado_costo}
                                            size="small" 
                                            onChange={(check) => this.onChangeOrden({iva_agregado: check})}/></Text>
                                    </Col>
                                    <Col span={12} className="flex-right">
                                        <Text className="dato">$ {orden.tipo === 1 ? orden.iva_venta.toMoney() : orden.iva_costo.toMoney()} mxn</Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Text className="titulo">Total</Text>
                                    </Col>
                                    <Col span={12} className="flex-right">
                                        <Text className="dato">$ {orden.tipo === 1 ? orden.total_venta.toMoney() : orden.total_costo.toMoney()} mxn</Text>
                                    </Col>
                                </Row>
                                <Row className="center">
                                    <Button
                                        disabled={this.state.productos.length === 0}
                                        className="mt-2 generar"
                                        onClick={() => this.onChangeOrden({estatus: 1})}
                                    >{orden.tipo == 1 ? 'Generar Cotización' : 'Generar Orden de Compra'}</Button>
                                </Row>
                            </Card>
                       </Col>
                   </Row>
                </Content>
                <ModalVariante
                    visible={this.state.modal_visible}
                    onCancel={()=>{
                        this.setState({modal_visible: false, variante_id: undefined, variante: undefined})
                    }}
                    variante_id={this.state.variante_id}
                    variante={this.state.variante}
                    addProducto={(values)=>this.addProducto(values)}
                />
                <ModalUsuarios
                    visible={this.state.modal_cliente}
                    onCancel={(flag,cliente) => {
                        console.log("cliente", cliente);
                        this.setState({ modal_cliente: false})
                        if(flag === true)
                            this.addClienteNew(cliente)
                    }}
                    small={true}
                />
            </Layout>
        )
    }
}

