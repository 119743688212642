import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, message, Upload, Checkbox, Spin, Radio, Space, Switch, Divider } from 'antd';
import { PlusOutlined,  DeleteOutlined, LoadingOutlined } from '@ant-design/icons';


const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');


class CotizacionDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            orden: {
                subtotal_venta: 0,
                iva_venta: 0,
                total_venta: 0
            },
            productos: [],

        }
    }

    refModalVariante = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if(this.props.orden_id){
            this.getOrden()
        }

    }

    /**
    * @memberof Cotizaciones
    *
    * @method getOrden
    * @description Obtiene la orden y sus productos
    **/
    getOrden = () => {
        this.setState({loading: true})
        axios.get('/ordenes/detalle',{
            params:{
                orden_id: this.props.orden_id
            }
        }).then(response => {
            console.log("response", response.data);
            this.setState({
                orden: response.data.orden,
                productos: response.data.orden_detalles
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la información')
        }).finally(()=>this.setState({loading: false}))
    }


    /**
    * @memberof Cotizaciones
    *
    * @method updateProducto
    * @description Actualiza la información de una orden detalle, y vuelve a obtener todos los detalles con los calculos
    **/
    updateProducto = (values, index) => {
        
        let orden_detalle = this.state.productos[index]

        let { precio, cantidad } = values

        orden_detalle.precio = precio
        orden_detalle.cantidad = cantidad
        orden_detalle.total_venta = (( precio * 100 ) * cantidad ) / 100

        let productos = this.state.productos
        let orden = this.state.orden

        productos[index] = orden_detalle

        let sub_total_venta = 0

        for(const detalle of productos){
            sub_total_venta = ((sub_total_venta * 100) + (detalle.total_venta * 100))/100
        }

        orden.subtotal_venta = sub_total_venta
        orden.total_venta = sub_total_venta

        if(orden.iva_agregado_venta){
            orden.iva_venta = ((sub_total_venta * 100) * (0.16 * 100)) / 10000
            orden.total_venta =  ((orden.total_venta * 100) + ( orden.iva_venta * 100)) / 100
        }else
            orden.iva_venta = 0

        orden.subtotal_venta = orden.subtotal_venta
        orden.total_venta = orden.total_venta
        orden.iva_venta = orden.iva_venta

        this.setState({productos: []},() => {
            this.setState({productos: productos, orden: orden})
        }) 
    
    }

    /**
    * @memberof Cotizaciones
    *
    * @method updateOrden
    * @description Actualiza el total dependidneo del iva
    **/
    updateOrden = (values) => {

        let { iva_agregado } = values

        let orden = this.state.orden


        if(iva_agregado){
            orden.iva_venta = ((orden.subtotal_venta * 100) * (0.16 * 100)) / 10000
            orden.total_venta =  ((orden.total_venta * 100) + ( orden.iva_venta * 100)) / 100
        }else{
            orden.iva_venta = 0
            orden.total_venta = orden.subtotal_venta
        }

        orden.iva_agregado_venta = iva_agregado

        this.setState({orden: orden}) 
    
    }

    /**
    * @memberof Cotizaciones
    *
    * @method updateData
    * @description Actualiza la informacion en la bd
    **/    
    updateData = () => {
        this.setState({loading: true})
        axios.post('/ordenes/update/all',{
            orden: this.state.orden,
            orden_detalles: this.state.productos
        }).then(response => {
            message.success('Orden Actualizada')
            this.props.onCancel()
        }).catch(error =>{
            console.log("error", error);
            if(error?.response?.status === 403){
                message.error(error?.response?.data?.message)
            }else{
                message.error('Error al actualizar')
            }
        }).finally(()=>this.setState({loading: false}))
    }


    /**
     * @memberof Cotizaciones
     * 
     * @method getPDF
     * @description Generamos el PDF de la cotizacion
     */
    getPDF = (orden_id) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/ordenes/pdf');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('orden_id', orden_id)
        urlPDF.searchParams.append('cotizacion', true)
        return urlPDF.href
    }


    render() {

        const { orden, productos } = this.state

        return (
            <>
                <div className="center">
                    <Title level={3} className="text-orange">Cotizacion {orden.folio} </Title>
                </div>
                <Row className="mt-1 mb-2">
                    <Col span={10} className="flex-left">
                        <Text strong className="text-orange">{moment(orden.createdAt).format('DD-MM-YYYY')}</Text>
                    </Col>
                    {this.props.change ? <Col span={14} className="">
                        <Space>
                            <Button 
                                className={`btn-materiales active`} 
                                onClick={() => { this.props.onChangeVista({tipo_vista: 'ventas'}) }}
                            >   
                                Cotizaciones
                            </Button>
                            
                            <Button 
                                className={`btn-materiales`} 
                                onClick={() => { this.props.onChangeVista({tipo_vista: 'compras'}) }}
                            >
                                Orden de Compra
                            </Button>
                        </Space>
                    </Col> : null}
                </Row>
                
                <Spin spinning={this.state.loading}>
                    <Row className="table-titles mt-1" gutter={[16,16]}>
                        <Col className="center" span={8}>
                            <Text>Producto</Text>
                        </Col>
                        <Col className="center" span={5}>
                            <Text>Cantidad</Text>
                        </Col>
                        <Col className="center" span={5}>
                            <Text>Precio {orden.iva_agregado_venta ? 'Sin Iva' : 'Sin Iva'}</Text>
                        </Col>
                        <Col className="center" span={5}>
                            <Text>Total</Text>
                        </Col>
                        
                    </Row>
                    <div className="list-productos mt-1">
                        {
                            productos.map((producto,index) =>{
                                return <Form
                                    onValuesChange={(values, all) => this.updateProducto(all, index)}
                                    initialValues={{...producto}}
                                >
                                    <Form.Item name="total" hidden/>
                                    <Form.Item name="_id" hidden/>
                                    <Row gutter={[16,16]} style={{marginTop: '12px'}}>
                                        <Col span={8} className="flex-left-column">
                                            <Text ellipsis strong style={{fontSize: '12px'}}>{producto?.variante_id?.nombre}</Text>
                                            <Text ellipsis style={{fontSize: '12px'}}>{producto?.producto_id?.nombre}</Text>
                                        </Col>
                                        <Col span={5}  className="center">
                                            {/*<Form.Item name="cantidad">
                                                <InputNumber min={1} precision={0} className="width-100"/>
                                            </Form.Item>*/}
                                            <Text>{producto.cantidad}</Text>
                                        </Col>
                                        <Col span={5} className="center">
                                            {/*<Form.Item name="precio">
                                                <InputNumber min={1} precision={0} className="width-100"/>
                                            </Form.Item>*/}
                                            <Text>{producto.precio_unitario?.toMoney()}</Text>
                                        </Col>
                                        <Col span={5} className="flex-right">
                                            <Text strong>$ {producto?.total_venta?.toMoney()} mxn</Text>
                                        </Col>
                                    </Row>
                                </Form>
                            })
                        }
                    </div>
                    <Divider/>
                    <div className="carrito-card mt-1">
                        <Row justify="center">
                            <Col span={7}>
                                <Text className="titulo">Subtotal</Text>
                            </Col>
                            <Col span={7} className="flex-right">
                                <Text className="dato">$ {orden?.subtotal_venta?.toMoney()} mxn</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={7}>
                                <Text className="titulo">IVA <Switch 
                                    size="small" 
                                    disabled={true}
                                    checked={orden.iva_agregado_venta} 
                                    //onChange={(check) => this.updateOrden({iva_agregado: check})}
                                /></Text>
                            </Col>
                            <Col span={7} className="flex-right">
                                <Text className="dato">$ {orden?.iva_venta?.toMoney()} mxn</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={7}>
                                <Text className="titulo">Total</Text>
                            </Col>
                            <Col span={7} className="flex-right">
                                <Text className="dato">$ {orden?.total_venta?.toMoney()} mxn</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={14} className="mt-1">
                                <a href={this.getPDF(orden._id)} download target="_blank">
                                    <Button 
                                        className="generar" 
                                        htmlType="submit" 
                                        type="primary" 
                                        loading={this.state.loading}
                                        onClick={()=>this.updateData()}
                                    >
                                        Descargar Cotización
                                    </Button>
                                </a>
                            </Col>
                        </Row>
                    </div>

                </Spin>
            </>
        )
    }
}



function ModalCotizaciones (props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        destroyOnClose={true}
        zIndex={1000}
        width={700}
    >
        <CotizacionDetalle {...props} />
    </Modal>

}


export {
    ModalCotizaciones as default,
    CotizacionDetalle
}