import React, { Component } from "react";
import { Table, Layout, Input, Row, Col, Modal, message, PageHeader, Tag, Button, Space, Typography, Card, Popconfirm, List, Image, Avatar } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import { IconBus, IconEye, IconEdit, IconDelete, IconArrowBack } from "../../Widgets/Iconos";
import { Link } from "react-router-dom";
//modales
const { Header, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;
const { Title, Text } = Typography

const axios = require("axios").default;
const moment = require('moment');

/**
 *
 *
 * @class Compras
 * @extends {Component}
 */
export default class Compras extends Component {

    volver = () => {
        this.props.history.goBack();
    }

    constructor(props) {
        super(props);
        this.state = {

            movimientos: [],
            producto: {},

            
        }
    }

    /**
    * @memberof Compras
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getData()
        this.getProducto()
    }


    /**
    * @memberof Compras
    *
    * @method getProducto
    * @description  Carga la informacion del producto
    **/
    getProducto = () => {
        axios.get('/productos/get',{
            params:{
                variante_id: this.props.match.params.variante_id
            }
        }).then(response => {
            console.log("response", response);
            this.setState({
                producto: response.data.data.producto
            })
        }).catch(error => {
            message.error('Error al obtener la información')
        })
    }

    /**
    * @memberof Compras
    *
    * @method getData
    * @description  Carga la informacion los inventarios
    **/
    getData = () => {
        axios.get('/movimientos',{
            params:{
                variante_id: this.props.match.params.variante_id
            }
        }).then(response => {
            console.log("response", response.data.data.itemsList);
            this.setState({
                movimientos: response.data.data.itemsList,
                total: response.data.data.paginator.itemCount,
                page: response.data.data.paginator.currentPage,
            })

        }).catch(error => {
            console.log("error", error);

        })
    }





    render() {

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title={
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                <Button ghost onClick={this.volver} style={{ border: "none" }}>
                                    <IconArrowBack />
                                </Button>
                                <span className="ant-page-header-heading-title">
                                    Movimientos de {this.state.producto.nombre}
                                </span>
                            </Col>
                        </Row>
                    }
                />
                <Content className="admin-content content-bg pd-1">

                    <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Usuarios" }}
                            dataSource={this.state.movimientos}
                            pagination={{
                                current: this.state.page,
                                pageSize: 10,
                                total: this.state.total,
                                showSizeChanger: false,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                className:"flex-left",
                                onChange: this.getUsuarios
                                
                            }}
                            header={<Row className="width-100 pr-1 pl-1" >
                                <Col span={4}  className="center">
                                    <Text strong>Fecha</Text>
                                </Col>
                                <Col span={10}  className="">
                                    <Text strong>Tipo</Text>
                                </Col>
                                <Col span={5}  className="center">
                                    <Text strong>Cantidad</Text>
                                </Col>
                                

                            </Row>}
                            

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={4}  className="center">
                                                <Text strong>{moment(item.createdAt).format('DD-MM-YYYY HH:mm')}</Text>
                                            </Col>
                                            <Col span={10}  className="flex-left-column">
                                                {item.tipo == 1 ? <Text strong style={{color: '#0dca33'}}>Entrada</Text> : <Text style={{color: '#df2a2a'}}>Salida</Text>}
                                            </Col>
                                            <Col span={5}  className="center">
                                                <Text strong>{item.cantidad}</Text>
                                            </Col>
                                            
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                </Content>

            </>
        )
    }
}

