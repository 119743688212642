import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Modal, Spin, Divider, Image, Alert, Checkbox, Typography } from 'antd';
import { Redirect, Link } from "react-router-dom";
import { User, SetUser } from '../../Hooks/Logged';
import '../../Styles/Global/auth.css';

import { IconBus, IconBuyBag, IconUserCircle, IconHotDeals, IconCloseModal } from "../Widgets/Iconos";
const axios = require("axios").default;


const { Text, Title } = Typography;

/**
 * @class Login
 * @extends {Component}
 */
class Login extends Component {

    redirectLink = "/admin/dashboard";
    recoveryPassword = React.createRef();

    /**
     *Creates an instance of Login.
     * @param {*} props
     * @memberof Login
     */
    constructor(props) {
        super(props);
        this.state = {
            log: false,
            loading: false,
            error: {
                success: null,
                message: null
            },
            statusLoading: 0,
            isRecovery: false,
            isRecoveryLoading: false
        }
    }




    /**
     * @memberof Login
     *
     * @method handleSubmit
     * @description  Envia los datos del formulario al Servidor
     *
     * @param values (array)
     * Contiene los campos del formulario para registrar al usuario
     *
     * @returns response (array)
     **/
    handleSubmit = (values) => {
        this.setState({ loading: true })
        axios.post('/login', {
            email: values.email,
            password: values.password
        })
            .then(({ data, headers }) => {

                console.log('props', this.props)
                const { setUser } = this.props;

                axios.defaults.headers.post["Authorization"] = headers.authorization;

                sessionStorage.setItem('token', headers.authorization);
                axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

                setUser(data.user);

                if (typeof this.props.onSuccess == "function")
                    return this.props.onSuccess()
                else 
                    this.props.onCancel()

            })
            .catch(({ response }) => {
                console.log('error', response)
                this.setState({ error: response?.data })
            })
            .finally(f => {
                this.setState({ loading: false });
            })
    };


    /**
     * @memberof Login
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
     **/
    renderRedirect = () => {
        if (this.state.log)
            return <Redirect to={this.redirectLink} />
    };


    /**
     * @memberof Login
     *
     * @method redirectTo
     * @description  Redirecciona a cierto link.
     *
     **/
    redirectTo = (to) => {
        this.redirectLink = to;
        this.setState({ log: true });
    };

    /**
    * @memberof Login
    * @method   handleRecoveryPassword
    * @description Metodo de recuperación de contraseña
    *
    **/
    handleRecoveryPassword = async value => {
        await this.setState({
            isRecoveryLoading: true
        })
        axios.put("/password/recovery", { email: value.email })
            .then(res => {
                this.setState({
                    isRecovery: false
                })
                Modal.success({
                    title: 'Recupera tu contraseña.',
                    content: 'El mail ha sido enviado! Ve a tu bandeja de entrada.'
                })
                this.recoveryPassword.current.resetFields();
            })
            .catch(res => {
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no pudo ser enviado. Verifica que sea el mismo con el que te registraste. '
                });
            })
            .finally(() => {
                this.setState({
                    isRecoveryLoading: false
                })
            })
    }



    render() {
        return (
            <>
                {this.renderRedirect()}

                <section className="cnt-login">
                    <Row className="w-100" >
                        <Col className="col-form"
                            xs={{ span: 24, order: 1 }}
                            sm={{ span: 24, order: 1 }}
                            md={{ span: 24, order: 1 }}
                            lg={{ span: 12, order: 1 }}
                            xl={{ span: 12, order: 1 }}
                            xxl={{ span: 12, order: 1 }}
                        >
                            <Spin spinning={this.state.loading}>

                                <Row className="w-100" justify="center" >
                                    <Col xs={{ span: 24, offset: 2, pull: 1 }}
                                        sm={{ span: 24, offset: 0, pull: 0 }}
                                        md={{ span: 20, offset: 0 }}
                                        lg={{ span: 20, offset: 1, pull: 1 }}
                                        xl={{ span: 20, offset: 0, pull: 0 }}
                                        xxl={{ span: 20, offset: 0, pull: 0 }} >
                                        <Row justify="center" className="mb-3">
                                            <Image src={'/images/logo.svg'} wrapperClassName="center" preview={false} width={185} />
                                        </Row>
                                        <h3 className="h3-title">Login</h3>
                                        <Form onFinish={this.handleSubmit} layout={"vertical"} requiredMark={false} >
                                            <Form.Item name="email" rules={[{ required: true, message: 'Por favor, ingresa tu correo electronico' }]}>
                                                <Input placeholder="Correo Electronico" size="large" className="input-login" />
                                            </Form.Item>
                                            <Form.Item name="password" rules={[{ required: true, message: 'Por favor, ingresa tu contraseña' }]}>
                                                <Input.Password type="password" placeholder="Contraseña" size="large" className="input-login" />
                                            </Form.Item>
                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item name="rememberme" >
                                                        <Checkbox className="checkbox-bdf " > Recuerdame </Checkbox>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Link to={'#'} onClick={() => this.setState({ isRecovery: true })} className="mb-1"> Olvide mi contraseña </Link>
                                                </Col>
                                            </Row>

                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" block size="large" className="button-login" >Accesar</Button>
                                            </Form.Item>
                                            <Form.Item>
                                                <p> No tienes una cuenta? <Link to={"#"} onClick={this.props.onClickRegistrarmeButton}>Registrar</Link></p>
                                            </Form.Item>

                                        </Form>

                                        {this.state.error?.success == false ?
                                            <Alert
                                                message="¡Ha ocurrido un error!"
                                                description={this.state.error.message}
                                                type="error"
                                                closable
                                                className="pd-1"

                                                onClose={() => this.setState({ error: { success: null, description: null } })}
                                            />
                                            : null}
                                    </Col>
                                </Row>
                                <Row className="w-100">
                                    <Col className="txt-pie-modal alg-l"> Copyright © 2022 <Text> Manantial Materiales </Text> Todos los derechos reservados. </Col>
                                    <Col className="txt-pie-modal alg-r"> <Text>Terminos y Condiciones</Text> </Col>
                                </Row>
                            </Spin>
                        </Col>
                        <Col
                            xs={{ span: 24, order: 1 }}
                            sm={{ span: 24, order: 1 }}
                            md={{ span: 24, order: 1 }}
                            lg={{ span: 12, order: 2 }}
                            xl={{ span: 12, order: 2 }}
                            xxl={{ span: 12, order: 2 }}

                            style={{ background: "url('/images/bg-login.jpg')" }} className="col-img" >

                        </Col>
                    </Row >

                    <Modal

                        title="Olvide mi contraseña"
                        visible={this.state.isRecovery}
                        onOk={() => this.recoveryPassword.current.submit()}
                        okButtonProps={{
                            loading: this.state.isRecoveryLoading
                        }}
                        onCancel={() => this.setState({ isRecovery: false })}
                        maskClosable={!this.state.isRecoveryLoading}
                        okText="Enviar"
                        cancelText="Cancelar"
                    >
                        <Form onFinish={this.handleRecoveryPassword} layout={"vertical"} className="pd-2" ref={this.recoveryPassword}>
                            <Form.Item
                                name="email"
                                label="Introduce tu correo electronico."
                                rules={[{
                                    required: true,
                                    message: 'Introduce tu correo electronico, por favor.'
                                }]}>
                                <Input type="email" size="large" placeholder="Ingresa tu correo electronico" />
                            </Form.Item>
                        </Form>
                    </Modal>
                </section>

            </>
        )
    }
}


export default function (props) {

    const { visible, onCancel } = props
    let user = useContext(User)
    const setUser = useContext(SetUser)

    return <Modal
        visible={visible}
        onCancel={onCancel}
        width={1000}
        maskClosable={false}

        className="modal-radius"
        footer={null}

        closable={true}
        closeIcon={<div title="Cerrar"> <IconCloseModal /> </div>}

        destroyOnClose={true}
        mask={true}

        maskStyle={{
            backdropFilter: 'blur(0.5rem)'
        }}
    >
        <Login {...props} user={user} setUser={setUser} />
    </Modal>


}