import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, message, Upload, Checkbox, Spin, Radio, Space } from 'antd';
import { PlusOutlined,  DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import ColorPicker from '../../Widgets/ColorPicker';


const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;


class ModalVariante extends Component {


    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
            proveedores: [],
            proveedor_variantes: [],
        }
    }

    refModalVariante = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getProveedores()

        if(this.props.variante_id){
            this.getProveedoresVariantes()
        }

    }

    /**
     * @memberof ModalCuentas
     * @method getProveedores
     * @description Obtiene los proveedores
     * 
     */
    getProveedores = (search) => {

        axios.get('/proveedores',{
            params:{
                search: search,
                variante_id: this.props.variante_id
            }
        }).then(response => {
            this.setState({proveedores: response.data.data.itemsList})

        }).catch(error => {
            console.log("error", error);

        })
    }

    /**
     * @memberof ModalCuentas
     * @method getProveedoresVariantes
     * @description Obtiene los proveedores de la variante
     * 
     */
    getProveedoresVariantes = (values) => {
        axios.get('/variantes/proveedores',{
            params:{
                variante_id: this.props.variante_id
            }
        }).then(response => {
            this.setState({proveedor_variantes: response.data.data})

        }).catch(error => {
            console.log("error", error);

        })
    }

    /**
     * @memberof ModalCuentas
     * @method onFinish
     * @description Obtiene los proveedores de la variante
     * 
     */
    onFinish = (values) => {
        if(values.proveedor_id_new){
            this.addProveedorVariante(values)
        }else{
            this.props.addProducto({
                variante_id: this.props.variante_id,
                proveedor_id: values.proveedor_id,
            })
            this.props.onCancel()
        }
        
    }

    /**
     * @memberof ModalCuentas
     * @method addProveedorVariante
     * @description Obtiene los proveedores de la variante
     * 
     */
    addProveedorVariante = (values) => {
        axios.post('/variantes/proveedores/add',{
            ...values,
            variante_id: this.props.variante_id
        }).then(response => {
            message.success('Proveedor agregado con exito')
            this.props.addProducto({
                proveedor_id: response.data.data.proveedor_id,
                variante_id: response.data.data.variante_id,
            })
            this.props.onCancel()
        }).catch(error => {
            console.log("error", error);
            if(error.response.status === 403)
                message.error(error.response.data.message)
            else
                message.error('Error al agregar el proveedor')
        })
    }


    render() {

        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.refModalVariante}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>

                    <Row className="mt-2" justify="center" gutter={[20,20]} style={{minHeight: '200px'}}>
                        {this.state.proveedor_variantes.length > 0 ? <Col span={20}>
                            <Form.Item
                                label="Selecciona un proveedor"
                                name="proveedor_id"
                            >
                                <Radio.Group>
                                    <Space direction="vertical">
                                        {this.state.proveedor_variantes.map(p_v => {
                                            return <Radio value={p_v.proveedor_id._id}>
                                                <div className="flex-left-column">
                                                    <Text>{p_v.proveedor_id.nombre}</Text>
                                                    <small>Costo: $ {p_v.costo.toMoney()} mxn</small>
                                                </div>
                                            </Radio>
                                        })}
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Col> : null}
                        <Col span={20}>
                            <Form.Item
                                label="Agregar proveedor"
                                name="proveedor_id_new"
                            >
                                <Select 
                                    showSearch
                                    allowClear
                                    filterOption={false}
                                    onSearch={this.getProveedores}
                                    onSelect={(proveedor_id)=>{
                                        this.refModalVariante.current.setFieldsValue({
                                            proveedor_id: null
                                        })
                                        this.setState({proveedor_id})
                                    }}
                                    placeholder="Seleccionar opción" 
                                >
                                    {this.state.proveedores.map(function (proveedor, index) {
                                        return <Option value={proveedor._id}>{proveedor.nombre}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        {this.state.proveedor_id ? <Col span={20}>
                            <Form.Item
                                label="Costo de la variante"
                                name="costo"
                                rules={[{ required: true, message: 'Por favor ingresa el costo de la variante' }]}
                            >
                                <InputNumber min={0} className="width-100"/>
                            </Form.Item>
                        </Col> : null}
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24} className="mt-1">
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading || this.state.loadingImage}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="flex-column">
            <Title level={3}> Seleccionar Proveedor</Title>
            <Title level={4}> Precio Variante: $ {props?.variante?.precio_venta?.toMoney()} mxn</Title>
        </div>
        <ModalVariante {...props} />
    </Modal>

}