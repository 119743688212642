import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, List, Switch, Upload } from 'antd';

import {  FileImageOutlined } from '@ant-design/icons';

import PropTypes from "prop-types";
import Logged from '../../../Hooks/Logged';
import CustomAvatar from "../../Widgets/Avatar/Avatar";


const axios = require('axios').default;
const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;
const { Dragger } = Upload;

/**
 *
 *
 * @class FormProductos
 * @extends {React.Component}
 * @description Formulario de categorias
 */
class FormProductos extends Component {


    formModalProductos = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            categorias: [],
            loadingImage: false,
            form: {},
            
            id: null,
            image: null,
            negocios: [],
            fileList:[],


        }
    }


    /**
     * @methodOf FormProductos
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getCategorias();

        if(this.props.producto_id){
            this.getData()
        }
    }

    /**
    * @memberof CrearProducto
    *
    * @method getCategorias
    * @description  metodo que contiene la peticion de obtener todas las categorias para asignarlos al state.categorias
    *
    **/
    getCategorias = () => {
        axios.get('/categorias', {
            params:{
                list:true
            }
        }).then(res => {
            this.setState({
                categorias: res.data.data,
            })
        }).catch(res => {
            console.log("error", res);
            message.error('Error al cargar las categorias')
        });
    }

    /**
   * @memberof FormProductos
   *
   * @method onFinish
   * @description  Se ejecuta al dar finalizar al formulario
   *
   **/
    onFinish = values => {
        this.setState({loading: true})
        if(this.props.producto_id !== undefined){
            this.updateProducto(values)
        }else{
            this.addProducto(values);
        }
        
    }


    /**
    * @memberof FormProductos
    *
    * @method getData
    * @description  trae la informacion de una categoria
    *
    **/
    getData = (values) => {
        this.setState({loading: true})
        axios.get(`/productos/get`,{
            params: {
                id: this.props.producto_id
            }
        }).then(async res => {

            console.log(res.data.data.producto)

            let producto = res.data.data.producto

            let imagenes = res.data.data.producto.imagenes;
            this.setState({fileList: imagenes})
            
            let fileArray =  imagenes.length > 0? imagenes.map((imagen, index) => ({
                uid: index,
                name: imagen,
                status: 'done',
                response: {
                    filename: imagen,
                },
                url: `${axios.defaults.baseURL}/upload/${imagen}`,
            })): [];

            this.formModalProductos.current?.setFieldsValue({
                nombre:        producto.nombre,
                categoria_id:  producto.categoria_id?._id,
                descripcion:   producto.descripcion,
                unidad:        producto.unidad,
                sku_manantial: producto.sku_manantial,
                sku_fabricante:producto.sku_fabricante,
                fabricante:    producto.fabricante,
                activo:        producto.activo,
                hot:           producto.hot,
                imagenes:      fileArray,
            })

                
               
        }).catch(res => {
            console.log("error", res);
            message.error('Error al cargar la informacion')
        }).finally(()=>this.setState({loading: false}));
    }


    /**
    * @memberof FormProductos
    *
    * @method updateProducto
    * @description  Actualiza la informacion de una categoria
    *
    **/
    updateProducto = (values) => {
        axios.put('/productos/update', {
            id: this.props.producto_id,
            nombre:        values.nombre,
            categoria_id:  values.categoria_id,
            descripcion:   values.descripcion,
            unidad:        values.unidad,
            sku_manantial: values.sku_manantial,
            sku_fabricante:values.sku_fabricante,
            fabricante:    values.fabricante,
            activo:        values.activo,
            hot:           values.hot,
            imagenes:      this.state.fileList,
        }).then((res) => {
            message.success('Producto Actualizado');
            this.props.onCancel()
        }).catch((error) => {
            console.log(error)
            message.error('Error al actualizar')
        }).finally(()=>this.setState({loading: false}));   
    }

    /**
    * @memberof FormProductos
    *
    * @method addProducto
    * @description  Añade una categoria
    **/
    addProducto = (values) => {
        axios.post('/productos/add', {
            nombre:        values.nombre,
            categoria_id:  values.categoria_id,
            descripcion:   values.descripcion,
            unidad:        values.unidad,
            sku_manantial: values.sku_manantial,
            sku_fabricante:values.sku_fabricante,
            fabricante:    values.fabricante,
            activo:        values.activo,
            hot:           values.hot,
            imagenes:      this.state.fileList,

        }).then((res) => {
            message.success(res.data.message);
            this.props.onCancel()
        }).catch((error) => {
            Modal.warning({
                title: 'Error',
                content:
                    <List
                        size="small"
                        bordered
                         dataSource="No se ha podido crear el producto"
                    />
            });
        }).finally(()=>this.setState({loading: false}));
       
    }

    /**
    * @memberof FormProductos
    *
    * @method addProducto
    * @description  Añade una categoria
    **/
    normFile = (event) => {
        console.log('upload event', event)

        let update_fileList = false;
        let delete_file = false;
            
        //Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
             
        if (event.file.status == "done") {
            update_fileList = true;
        }
            
        if (event.file.status == "removed") {
            update_fileList = true;
            delete_file = true;
        }
            
        //if (delete_file)
        //    this.removeFile((event.file.response.filename != undefined) ? event.file.response.filename : event.file.name);

    
        if (update_fileList){
            let arrayList = [];

            for (let x = 0; x < event.fileList.length; x++){
                try{
                    let filename = event.fileList[x].response.filename
                    arrayList.push(event.fileList[x].response.filename)
                }catch (e) {
                    console.log(e)
                }
            }


            console.log('ARRY', arrayList)

            this.setState({
                fileList: arrayList
            });
        }
        
        return event && event.fileList;
       
    }






    render() {

        const { loading } = this.state

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">Productos</Title>
                <Form 
                    layout="vertical" 
                    ref={this.formModalProductos} 
                    onFinish={this.onFinish} 
                >

                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item 
                                name="nombre" 
                                label="Nombre" 
                                rules={[{
                                    required: true,
                                    message: 'Por favor ingresa nombre de producto'
                                }]}
                            >
                                <Input placeholder="Nombre del producto" />
                            </Form.Item>
                        </Col>
                                    
                        <Col xs={24} lg={{span: 11, push: 2}}>
                            <Form.Item 
                                name="categoria_id" 
                                label="Categoría" 
                                rules={[{
                                    required: true,
                                    message: 'Por favor selecciona categoría'
                                }]}>
                                    
                                    <Select 
                                        className="width-100"
                                        placeholder="Seleccionar opción"  
                                    >
                                        {this.state.categorias.map((categoria, index) => {
                                            return <Option value={categoria._id}>{categoria.nombre}</Option>
                                        })}
                                    </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row align="space-between">
                        <Col md={24} lg={24}>
                            <Form.Item 
                                name="descripcion" 
                                type='textarea' 
                                label="Descripción" 
                                rules={[{ required: true, message: 'Por favor ingresa descripción' }]}
                            >
                                <TextArea
                                    placeholder="Descripción del producto"
                                    style={{minHeight: '100px', width: '100%'}}
                                />
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={11}>
                            <Form.Item 
                                name="unidad" 
                                label="Unidad" 
                                rules={[{
                                    required: true,
                                    message: 'Por favor ingresa la unidad del producto'
                                }]}>
                                    <Input placeholder="Litros" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={11}>
                            <Form.Item 
                                name="fabricante" 
                                label="Fabricante" 
                                rules={[{
                                    required: true,
                                    message: 'Por favor ingresa el fabricante del producto'
                                }]}>
                                    <Input placeholder="CEMEX" />
                            </Form.Item>
                        </Col>
                    </Row>

                        <Row>
                            <Col xs={24} md={5}>
                                <Form.Item 
                                    name="activo" 
                                    label="Activo"
                                    valuePropName="checked"
                                >
                                    <Switch defaultChecked  style={{float: 'left'}}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={5}>
                                <Form.Item 
                                    name="hot" 
                                    label="Hot sale"
                                    valuePropName="checked"
                                >
                                    <Switch defaultChecked={false}  style={{float: 'left'}}/>
                                </Form.Item>
                            </Col>
                            
                        </Row>
                                
                        
                    
                    <Row>
                        <Col span={24}>
                            <Form.Item 
                                label="Imagenes"
                            >
                                <Form.Item
                                    name="imagenes"
                                    valuePropName="fileList"
                                    getValueFromEvent={this.normFile}
                                    noStyle
                                >
                                    <Dragger
                                        name="files"
                                        listType="picture-card"
                                        action={axios.defaults.baseURL + '/upload/add'}
                                        multiple={true}
                                    >
                                        <p className="ant-upload-drag-icon" style={{ marginBottom: '5px' }} >
                                            <FileImageOutlined style={{ fontSize: '30px' }} />
                                        </p>
                                        <p className="ant-upload-text">Agrega imagenes del producto</p>
                                        <p className="ant-upload-hint"> Haga clic o arrastre el archivo a esta área para cargar</p>
                                    </Dragger>
                                </Form.Item>
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row justify="center" align="middle" >
                        <Col span={24} className="mt-1 flex-column">
                            <Form.Item>
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {

    let { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormProductos {...props} />
        </Modal>
    )
}